import getGeo from '../glb/geo';
import getFilter from '../glb/filter';

export default function () {
    return {
        geo: getGeo(),
        isSearching: false,
        /* View Related Items */
        currentViewStatus: 'grid-view',
        isSplitView: true,
        /* End View Related Items */
        viewUrl: '',
        hasBoundary: false,
        pageSize: 50,
        pageCount: 0,
        totalCount: 0,
        displayTotalCount: 0,
        displayPageSize: 0,
        paginationMode: 0,
        firstPageIndex: 0,
        displayPageIndex: 0,
        listings: [],
        mapListings: [],
        mapListingsPath: '',
        attributes: [],
        visiableListings: [],
        mspSeoLinks: {},
        dataList: [],
        realEstateLinks: [],
        searchInput: '',
        searchType: '',
        priceSuggestions: [],
        resetListView: false,
        filter: getFilter(),
        firstZipcode: '',
        firstProperty: null,
        schoolData: {
            schoolCount: 0,
            list: [],
        },
        dpp: null,
        marketSummary: {},
        snapshotData: {},
        nearbyData: [],
        nearbyCities: [],
        nearbyZipCodes: [],
        nearbyNeighborhoods: [],
        nearbyCounties: [],
        colloquialAreaInfo: null,
        photoGraphicContent: null,
        filterType: null,
        topSchools: [],
        sort: 0,
        topNlistingsInCity: null,
        refinedInventoryCounts: null,
        shortcutFilters: null,
        localHighlightGeo: null,
        community: '',
        name: 'mapsearch',
        searchCount: 0,
        boundaryIndexId: '',
        boundaryGeoId: '',
        boundaryName: '',
        boundaryState: '',
        currentSearchMode: 0,
        searchMode: {
            default: 0,
            coords: 1,
            forsale: 2,
            forrent: 3,
        },
        isNewSearch: true,
        filterTrigger: '',
        searchTrigger: '',
        searchTriggerMode: {
            MapSearchByTextbox: 'text-box',
            MapSearchByFilter: 'search-filters',
            MapSearchPageLoad: 'search-page-load',
            MapSearchPagination: 'search-pagination',
            MapSearchByCoordinates: 'map-coordinates',
        },
        col: 2,
        isListView: false,
        loadMap: false,
        relatedLinks: {},
        poiList: [],
        marketTrendsSummary: [],
        nearbySchools: null,
        schoolSummary: null,
        mlsIds: [],
    };
}
