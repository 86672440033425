import geoMapper from './_geoMapper.js';
import motime from '../../utilities/motime.js';
import molanglib from '../../utilities/lang/index.js';
import format from '../../utilities/format.js';

let molang = null;

function initMolang(language) {
    const datastore = molanglib.getDataStore(language);
    return function (key, options) {
        return molanglib.getString(datastore, key, options);
    };
}

function getPropertyTypeDisplay(data) {
    if (data.isRentals) {
        if (data.propertyType === 'SINGLE_FAMILY_HOUSE') {
            return 'House';
        } else if (data.propertyType === 'CONDO' || data.propertyType === 'MULTI_FAMILY') {
            return 'Apartment';
        }
    }
    if (data.propertyType === 'SINGLE_FAMILY_HOUSE') {
        return 'House';
    } else if (data.propertyType === 'MULTI_FAMILY') {
        return 'Multi-family';
    } else if (data.propertyType === 'COMMERCIAL') {
        return 'Commercial';
    } else if (data.propertyType === 'LAND') {
        return 'Lot/Land';
    } else if (data.propertyType === 'MOBILE') {
        return data.propertyTypeDisplayName || 'Mobile';
    } else if (data.propertyType === 'CONDO') {
        return data.propertyTypeDisplayName || 'Condo/Townhouse ';
    }
    return 'Other';
}

function getPropertyStatusDisplay(data, labelDisplayName) {
    if (data.isPrOnly) {
        return molang('search.card.notForSale');
    } else if (data.houseRealStatus === 'SOLD') {
        return molang('search.card.sold');
    } else if (data.houseRealStatus === 'PENDING') {
        return data.activeUnderContract ? molang('search.card.activeUnderContract') : molang('search.card.pending');
    } else if (data.houseRealStatus === 'REMOVED') {
        return molang('search.card.dELISTED');
    } else {
        return labelDisplayName;
    }
}

function getPropertyTypeIcon(data) {
    if (data.isRentals) {
        if (data.propertyType === 'SINGLE_FAMILY_HOUSE') {
            return 'icon-property-single-family';
        } else if (data.propertyType === 'CONDO' || data.propertyType === 'MULTI_FAMILY') {
            return 'icon-property-condo';
        }
    }
    if (data.propertyType === 'SINGLE_FAMILY_HOUSE') {
        return 'icon-property-single-family';
    } else if (data.propertyType === 'MULTI_FAMILY') {
        return 'icon-property-multi-family';
    } else if (data.propertyType === 'CONDO') {
        return 'icon-property-condo';
    } else if (data.propertyType === 'LAND') {
        return 'icon-property-lot';
    } else if (data.propertyType === 'MOBILE') {
        return 'icon-property-mobile';
    } else if (data.propertyType === 'OTHER') {
        return 'icon-property-other';
    }
    return 'icon-property-single-family';
}

function getIsCommingSoonListing(listingStatus) {
    return !!(listingStatus === 'Coming Soon');
}
function getLastListPrice(data, price) {
    if (data.priceChange) {
        var orginPrice = price - data.priceChange;
        if (orginPrice > 0) {
            return orginPrice;
        }
    }
    return 0;
}
function getShowPriceChange(lastListPrice, isActive, isNew, isComingSoonListing) {
    return !isNew && !isComingSoonListing && lastListPrice && isActive;
}

function getIsPriceReduced(data) {
    return data.priceChange < 0;
}

function getIsNew(data, compliance) {
    const isNew = !data.isCommingSoonListing && (data.houseRealStatus === 'ACTIVE' || data.houseRealStatus === 'FOR_RENT') && data.daysOnMovoto >= 0 && data.daysOnMovoto <= 7;
    return isNew && compliance ? !compliance.rule.includes('hideNew') : isNew;
}
function getIsSold(data) {
    return data.houseRealStatus && data.houseRealStatus === 'SOLD';
}
function getIsActive(data) {
    return data.houseRealStatus === 'ACTIVE' || data.houseRealStatus === 'PENDING' || data.houseRealStatus === 'FOR_RENT';
}
function getIsCondo(data) {
    return data.propertyType === 'CONDO';
}

function getIsLand(data) {
    return data.propertyType === 'LAND';
}

function getShow3DTour(data) {
    return data.houseRealStatus === 'ACTIVE' && data.is3DTour;
}

function getArea(data, isLand) {
    return (isLand ? data.lotSize : data.sqftTotal) || 0;
}

function getAreaUnit(isLand) {
    return isLand ? 'Acre' : 'Sqft';
}

//check
function getPrice(data, isSold) {
    var price;
    if (data.isPrOnly) {
        data.isEstPrice = true;
        price = data.listPrice;
    } else if (isSold || data.houseRealStatus === 'OFF_MARKET' || data.houseRealStatus === 'REMOVED') {
        if (data.closePrice) {
            price = data.closePrice;
        } else if (data.listPrice) {
            price = data.listPrice;
        } else if (data.permitAvm) {
            data.isEstPrice = true;
        }
    } else if (data.listPrice || data.houseRealStatus === 'ACTIVE') {
        price = data.listPrice;
    } else if (data.permitAvm) {
        data.isEstPrice = true;
    }
    return price || 0;
}

function getPricePerArea(price, area) {
    if (!price || !area) {
        return 0;
    }
    return price / area;
}

function getAppSeoJsonCards(data) {
    if (!data) return;
    const address = {
        '@type': 'PostalAddress',
        streetAddress: data.geo.address || '',
        addressLocality: data.geo.city || '',
        postalCode: data.geo.zipcode || '',
        addressRegion: data.geo.state || '',
    };
    const seoObj = {
        '@context': 'http://schema.org',
        '@type': 'SingleFamilyResidence',
        name: data.formatAddress,
        image: data.tnImgPath,
        url: data.dppUrl,
    };

    data.appSeoJsonCard = JSON.stringify({ ...seoObj, address });

    if ((data.openHouses && data.openHouses.length) || data.is3DTour) {
        seoObj['@type'] = 'Event';
        seoObj.location = {
            '@type': 'Place',
            name: data.formatAddress,
            address,
        };
        (seoObj.performer = {
            '@type': data.listingAgent ? 'Person' : 'PerformingGroup',
            name: data.listingAgent || data.officeListName,
        }),
            (seoObj.eventAttendanceMode = 'https://schema.org/MixedEventAttendanceMode'),
            (seoObj.eventStatus = 'https://schema.org/EventScheduled');
        if (data.geo.lat && data.geo.lng) {
            seoObj.location.geo = {
                '@type': 'GeoCoordinates',
                latitude: data.geo.lat,
                longitude: data.geo.lng,
            };
        }
        if (data.price && data.isActive) {
            seoObj.offers = {
                '@type': 'Offer',
                url: data.dppUrl,
                availability: 'http://schema.org/InStock',
                price: data.price,
                priceCurrency: 'USD',
            };
        }

        if (data.openHouses && data.openHouses.length) {
            const [open] = data.openHouses;
            !open.startTime && (open.startTime = '00:00:00');
            !open.endTime && (open.endTime = '23:59:59');
            let starting = motime.toDateTime(open.date, open.startTime);
            let ending = motime.toDateTime(open.date, open.endTime);
            const time = [];
            if (!isNaN(starting)) {
                time.push(motime.formatDate(starting, { hour: 'numeric', minute: 'numeric', raw: true }).split(' ')[0]);
            }
            if (!isNaN(ending)) {
                time.push(motime.formatDate(ending, { hour: 'numeric', minute: 'numeric', raw: true }));
            }
            seoObj.name = `Open House: ${time.join(' - ')}`;
            seoObj.description = `Open house at ${data.formatAddress}`;
            seoObj.startDate = new Date(`${open.date} ${open.startTime}`);
            seoObj.endDate = new Date(`${open.date} ${open.endTime}`);
            seoObj.offers && (seoObj.offers.validFrom = open.startDate);
            data.appSeoJsonOpenHouse = seoObj;
        }
        if (data.is3DTour) {
            const today = new Date();
            seoObj.name = `3D/Virtual Tour - ${data.formatAddress}`;
            seoObj.description = `3D/Virtual Tour at ${data.formatAddress}`;
            seoObj.startDate = today;
            seoObj.endDate = today;
            seoObj.offers && (seoObj.offers.validFrom = today);
            seoObj.organizer = seoObj.performer;
            data.appSeoJsonVirtualTour = JSON.stringify(seoObj);
        }
    }
}

function getHouseCardTitleText(data) {
    if (!data) {
        return '';
    }
    let titleText = '';
    let rentLabel = data.isRentals ? 'Rent' : '';
    switch (data.propertyType) {
        case 'SINGLE_FAMILY_HOUSE':
            titleText = molang('search.card.house');
            break;
        case 'MULTI_FAMILY':
            titleText = molang(`search.card.multiFamily${rentLabel}`);
            break;
        case 'CONDO':
            titleText = molang(`search.card.condo${rentLabel}`);
            break;
        case 'LAND':
            titleText = molang('search.card.lotLand');
            break;
        case 'MOBILE':
            titleText = 'Mfd/Mobile Home';
            break;
        case 'OTHER':
        default:
            titleText = molang('search.card.other');
    }

    if (data.isCommingSoonListing && data.houseRealStatus === 'ACTIVE' && data.listDate != '—') {
        titleText += ` ${molang('search.card.comingSoon')} ${motime.formatDate(data.listDate, { day: 'numeric', month: 'numeric', year: '2-digit' })}`;
    } else if (data.houseRealStatus === 'ACTIVE') {
        titleText += ' ' + molang('search.card.forSale');
    } else if (data.houseRealStatus === 'PENDING') {
        titleText += ' ' + molang('search.card.underContract');
    } else if (data.houseRealStatus === 'SOLD') {
        titleText += data.soldDate ? ' Sold on ' + motime.formatDate(data.soldDate) : ' Recently Sold';
    } else if (data.houseRealStatus === 'FOR_RENT') {
        titleText += ' ' + molang('search.card.forRent');
    }

    return titleText;
}

function timeDisplay(time, time2) {
    if (!time) {
        return '';
    }

    const timeDiffInSeconds = motime.dateDiff(time, time2);
    let timeFrameString;
    if (timeDiffInSeconds < 3599) {
        const timeInMinutes = Math.floor(timeDiffInSeconds / 60);
        const soldOrListed = time2 ? 'zeroDay' : 'minute_ago';
        timeFrameString = molang(`search.card.${soldOrListed}`, { pluralCount: timeInMinutes });
    } else if (timeDiffInSeconds <= 172800) {
        const timeInHours = Math.floor(timeDiffInSeconds / (60 * 60));
        timeFrameString = molang('search.card.hour_ago', { pluralCount: timeInHours });
    } else {
        const timeInDay = Math.floor(timeDiffInSeconds / (60 * 60 * 24));
        timeFrameString = molang('search.card.day_ago', { pluralCount: timeInDay });
    }

    return timeFrameString;
}

function getListOrCreatedDate(formatted, data) {
    if (formatted.listDate > formatted.createdAt && formatted.listDate < Date.now()) {
        return data.listDate;
    }
    return data.createdAt;
}

function getMlsCompliance(mlsId, complianceData) {
    if (!mlsId || !complianceData || !complianceData.hasOwnProperty(mlsId)) {
        return null;
    }
    return complianceData[mlsId];
}

function populateMlsLogoSrc(cdnUrl, compliance, mlsId) {
    if (!compliance) {
        return '';
    }
    var rules = compliance['rule'];
    if (!rules || !rules.length) {
        return '';
    }
    if (rules.indexOf('showMLSLogo') >= 0 && mlsId) {
        return cdnUrl + 'images/desktop/logo/logo_' + mlsId + '.png';
    }
    return '';
}

function populateMlsDescription(compliance) {
    if (!compliance) {
        return '';
    }
    return compliance.name;
}

function getLabel(data, isNew, createTimeDisplay, soldTimeDisplay, houseRealStatusDisplay) {
    const labelNameList = [];
    if (data.listingByMovoto) {
        if (data.houseRealStatus === 'SOLD') {
            labelNameList.push(molang('dpp.propertyNearby.soldBy'));
            if (soldTimeDisplay) {
                labelNameList.push(soldTimeDisplay);
            }
            return {
                labelClass: 'default active',
                labelName: labelNameList.join(' '),
                labelNameList,
            };
        } else {
            if (isNew) {
                labelNameList.push(molang('dpp.propertyNearby.listedBy'));
                if (createTimeDisplay) {
                    labelNameList.push(createTimeDisplay);
                }
                return {
                    labelClass: 'springgreen',
                    labelName: labelNameList.join(' '),
                    labelNameList,
                };
            } else {
                labelNameList.push(molang('dpp.propertyNearby.listedBy'));
                return {
                    labelClass: 'brand active',
                    labelName: labelNameList.join(' '),
                    labelNameList,
                };
            }
        }
    } else if (isNew) {
        const newKeyword = molang('search.card.newAgo');
        labelNameList.push(newKeyword);
        if (createTimeDisplay) {
            labelNameList.push(createTimeDisplay);
        }
        return {
            labelClass: 'springgreen',
            labelName: labelNameList.join(' '),
            labelNameList,
        };
    } else if (data.houseRealStatus === 'ACTIVE') {
        labelNameList.push(createTimeDisplay);
        return {
            labelClass: '',
            labelName: labelNameList.join(' '),
            labelNameList,
        };
    } else if (data.houseRealStatus === 'PENDING') {
        labelNameList.push(houseRealStatusDisplay);
        return {
            labelClass: 'pending default active',
            labelName: labelNameList.join(' '),
            labelNameList,
        };
    } else if (data.houseRealStatus === 'SOLD') {
        labelNameList.push(houseRealStatusDisplay);
        if (soldTimeDisplay) {
            labelNameList.push(soldTimeDisplay);
        }
        return {
            labelClass: 'default active',
            labelName: labelNameList.join(' '),
            labelNameList,
        };
    } else if (data.isPrOnly || data.houseRealStatus === 'REMOVED') {
        labelNameList.push(houseRealStatusDisplay);
        return {
            labelClass: '',
            labelName: labelNameList.join(' '),
            labelNameList,
        };
    } else if (data.houseRealStatus === 'OFF_MARKET') {
        const labelValue = `${data.status} ${soldTimeDisplay || ''}`.trim();
        if (data.status) {
            labelNameList.push(data.status);
        }
        if (soldTimeDisplay) {
            labelNameList.push(soldTimeDisplay);
        }
        if (!labelNameList.length) {
            labelNameList.push(houseRealStatusDisplay);
        }
        return {
            labelClass: '',
            labelName: labelNameList.join(' '),
            labelNameList,
        };
    }
    labelNameList.push(houseRealStatusDisplay);
    return {
        labelClass: '',
        labelName: houseRealStatusDisplay,
        labelNameList,
    };
}

function populateMlsInfo(propertyData, compliance) {
    if (!compliance || !propertyData) {
        return '';
    }
    let text = '';
    var rules = compliance['rule'];
    if (!rules || !rules.length) {
        return text;
    }
    if (rules.indexOf('showAgent') >= 0 || rules.indexOf('showBroker') >= 0) {
        text = '';
        if (rules.indexOf('showAgent') >= 0 && propertyData.listingAgent) {
            text += ' ' + propertyData.listingAgent;
        }
        if (rules.indexOf('showBroker') >= 0) {
            var officeListName = propertyData.officeListName || compliance.name;
            if (officeListName) {
                text += `${text ? ',' : ''} ${officeListName}`;
            }
        }
        if (rules.indexOf('showBrokerPhone') >= 0) {
            var officeListPhone = propertyData.officeListPhone;
            if (officeListPhone) {
                text += `${text ? ',' : ''} Phone #${officeListPhone}`;
            }
        }
        if (rules.indexOf('showMLSNum') >= 0) {
            text += ' MLS#' + propertyData.mlsNumber;
        }
        if (text) {
            text = 'Listed by ' + text;
        }
    }
    return text;
}

function getOpenDate(propertyData) {
    return (
        propertyData.openHouses &&
        propertyData.openHouses.length &&
        motime.formatDate(motime.toDateTime(propertyData.openHouses[0].date, propertyData.openHouses[0].startTime), { year: undefined, month: 'numeric' })
    );
}
function getOpenDateV1(propertyData) {
    if (propertyData.openHouses && propertyData.openHouses.length) {
        return motime.toWeekTime(propertyData.openHouses[0].date, propertyData.openHouses[0].startTime, propertyData.openHouses[0].endTime);
    }
    return false;
}

export function populateHtmlDescription(rawProperty, geo) {
    const propertyTypeDisplay = getPropertyTypeDisplay(rawProperty);
    let status = 'For Sale';
    if (rawProperty.isRentals) {
        status = rawProperty.houseRealStatus === 'REMOVED' ? 'Public Record' : 'For Rent';
    } else if (rawProperty.showAsPR) {
        status = 'Public Record';
    } else if (rawProperty.isSold || rawProperty.houseRealStatus === 'OFF_MARKET') {
        status = 'Off-market';
    }
    const price = getPrice(rawProperty, rawProperty.isSold) || (rawProperty.nearbyEstPrice ? rawProperty.nearbyEstPrice.estPrice : 0);
    const isLand = getIsLand(rawProperty);
    let area = getArea(rawProperty, isLand);
    let displayArea = format.area(area, isLand);
    let areaUnit = getAreaUnit(isLand);
    let formattedDisplayArea = format.formatNumberByComma(displayArea) > 0 ? format.formatNumberByComma(displayArea) + ` ${areaUnit} land, ` : '';
    let pricePerArea = getPricePerArea(price, area);
    pricePerArea = isLand ? price / parseFloat(displayArea) : pricePerArea;
    let priceString = price ? format.formatNumberByComma(price) : price;
    const pricePerAreaString = pricePerArea && !isNaN(pricePerArea) ? format.formatNumberByComma(format.normalRound(pricePerArea)) : '';
    let displayPriceStr = (priceString ? `$${priceString}` : '') + (priceString && rawProperty.showAsPR ? ' estimated value' : '');
    displayPriceStr = (rawProperty.isSold || !!rawProperty.showAsPR) && rawProperty.hidePropertyPrice ? '$—' : displayPriceStr;
    priceString = (rawProperty.isSold || !!rawProperty.showAsPR) && rawProperty.hidePropertyPrice ? '—' : priceString;
    return rawProperty.propertyType === 'LAND'
        ? `${status}: ${displayPriceStr ? displayPriceStr : ''}${pricePerAreaString ? ` $${pricePerAreaString}/${areaUnit}` : ''} - ${geo.formatAddress}${
              geo.neighborhood ? ` in ${geo.neighborhood}.` : ''
          } ${formattedDisplayArea}${
              (rawProperty.avmInfo && rawProperty.avmInfo.valuation) || (rawProperty.nearbyEstPrice && rawProperty.nearbyEstPrice.estPrice)
                  ? `with an estimated value of $${
                        rawProperty.avmInfo && rawProperty.avmInfo.valuation
                            ? format.formatNumberByComma(rawProperty.avmInfo.valuation)
                            : rawProperty.nearbyEstPrice
                            ? format.formatNumberByComma(rawProperty.nearbyEstPrice.estPrice)
                            : ''
                    }`
                  : ''
          }`
        : `${status}: $${priceString}${pricePerAreaString ? ` $${pricePerAreaString}/Sqft` : ''}${rawProperty.hoafee ? `, $${rawProperty.hoafee}/month HOA` : ''} - ${geo.formatAddress}${
              geo.neighborhood ? ` in ${geo.neighborhood}` : ''
          } is a ${rawProperty.bed > 0 ? rawProperty.bed + ' bed, ' : ''}${rawProperty.bath > 0 ? rawProperty.bath + ' bath, ' : ''}${
              area > 0 ? format.formatNumberByComma(area) + ' ' + areaUnit + ', ' : ''
          }${rawProperty.lotSize > 0 ? format.formatNumberByComma(rawProperty.lotSize) + ' sqft lot, ' : ''}${propertyTypeDisplay}${rawProperty.yearBuilt ? ' built in ' + rawProperty.yearBuilt : ''}${
              (rawProperty.avmInfo && rawProperty.avmInfo.valuation) || (rawProperty.nearbyEstPrice && rawProperty.nearbyEstPrice.estPrice)
                  ? `, with an estimated value of $${
                        rawProperty.avmInfo && rawProperty.avmInfo.valuation
                            ? format.formatNumberByComma(rawProperty.avmInfo.valuation)
                            : rawProperty.nearbyEstPrice
                            ? format.formatNumberByComma(rawProperty.nearbyEstPrice.estPrice)
                            : ''
                    }`
                  : ''
          }`;
}

function getOpenDateText(openhouse) {
    if (!openhouse) {
        return '';
    }
    openhouse.isVirtual = openhouse.remarks && /virtual/i.test(openhouse.remarks);
    const virtualText = openhouse.isVirtual ? 'Virtual:' : '';
    const start = motime.toDateTime(openhouse.date, openhouse.startTime);
    const end = motime.toDateTime(openhouse.date, openhouse.endTime);
    const date = motime.formatDate(start, { weekday: 'short', month: 'numeric', day: 'numeric', raw: true });
    const time = [];
    if (!isNaN(start)) {
        time.push(motime.formatDate(start, { hour: 'numeric', minute: 'numeric', raw: true }));
    }
    if (!isNaN(end)) {
        time.push(motime.formatDate(end, { hour: 'numeric', minute: 'numeric', raw: true }));
    }
    return `${virtualText} ${date} ${time.join(' - ')}`;
}

function getCategorizedPhotos(propertyData, compliance, user) {
    const hasCatPhotos = propertyData && propertyData.categorizedPhotos && propertyData.categorizedPhotos.length;
    let catPhotos = hasCatPhotos ? propertyData.categorizedPhotos : [];
    if (compliance) {
        const status = propertyData.houseRealStatus || '-';
        const rules = compliance['rule'];
        const isLoggedIn = user && user.id;
        if (rules) {
            const ruleForInactiveNonSignedIn = rules.includes('onlyFirstPicture') && !propertyData.isActive && !isLoggedIn;
            const ruleForStatus = propertyData.showAsPR || rules.includes(`onlyFirstPicture${status}`);

            if (ruleForInactiveNonSignedIn || ruleForStatus) {
                propertyData.photoCount = 1;
                ruleForInactiveNonSignedIn && !ruleForStatus && (propertyData.hidePhotosByCompliance = true);
                if (catPhotos.length) {
                    catPhotos = catPhotos.slice(0, 1);
                    if (catPhotos[0].photos && catPhotos[0].photos.length) {
                        catPhotos[0].photos = catPhotos[0].photos.slice(0, 1);
                    }
                }
            }
        }
    }
    return catPhotos;
}

function isDateObj(date) {
    return Object.prototype.toString.call(date) === '[object Date]';
}

function getAddressId(data) {
    return data.brokerageDetails && data.brokerageDetails.opendoorDetails ? data.brokerageDetails.opendoorDetails.addressId : '';
}

function propertyIdToImg(data, cdnUrl) {
    if (!data.propertyId) {
        return data.tnImgPath;
    }
    let number = data.propertyId[0].charCodeAt(0) + data.propertyId[1].charCodeAt(0) + data.propertyId[2].charCodeAt(0);
    let seed = (number % 19) + 1;
    return `${cdnUrl}images/listing-card-empty-${seed}.webp`;
}

function getListingImgUrl(data, cdnUrl) {
    if (data.photoCount > 0) {
        return data.tnImgPath;
    }
    if (data.tnImgPath && data.tnImgPath.indexOf('streetview') > -1) {
        return data.tnImgPath;
    }
    return propertyIdToImg(data, cdnUrl);
}

function isDateWithinSpecificMonths(inputDate, month) {
    const dateToCheck = new Date(inputDate);
    if (isNaN(dateToCheck)) {
        return false;
    }
    const currentDate = new Date();
    const sixMonthsAgo = new Date(currentDate);
    sixMonthsAgo.setMonth(currentDate.getMonth() - month);
    return dateToCheck >= sixMonthsAgo && dateToCheck <= currentDate;
}

export default function (data, { language, appUrl, cdnUrl, desktopUrl, staticUrl, isUserBrowser, rentals, user, marketSummary, compliance: complianceData }) {
    if (!appUrl || !cdnUrl || !staticUrl) {
        return null;
    }
    molang = initMolang(language);
    const isPrOnly = !!data.isPrOnly;
    const hiddenMode = !!data.hiddenMode;
    var baseProperty = {
        propertyId: data.propertyId || null,
        listingId: data.id || null,
        path: data.path || null,
        dppUrl: data.dppurl || (desktopUrl || appUrl) + data.path,
        fullAddress: data.fullAddress,
        geo: geoMapper(data.geo, rentals),
        tnImgPath: getListingImgUrl(data, cdnUrl),
        photoCount: data.photoCount || 0,
        houseRealStatus: data.houseRealStatus || null,
        activeUnderContract: !!data.activeUnderContract,
        propertyType: data.propertyType || null,
        propertyTypeDisplayName: data.propertyTypeDisplayName || null,
        bath: data.bath,
        bed: data.bed,
        garage: data.garage >= 0 ? data.garage : null,
        daysOnMovoto: isPrOnly ? undefined : data.daysOnMovoto,
        lotSize: data.lotSize >= 0 ? data.lotSize : null,
        lotSizeUnit: data.lotSizeUnit || null,
        sqftTotal: data.sqftTotal >= 0 ? data.sqftTotal : null,
        buildingArea: data.buildingArea >= 0 ? data.buildingArea : null,
        listDate: data.listDate ? (isDateObj(data.listDate) ? data.listDate : new Date(data.listDate.replace(/-/g, '/'))) : null,
        soldDate: data.soldDate ? (isDateObj(data.soldDate) ? data.soldDate : new Date(data.soldDate.replace(/-/g, '/'))) : null,
        createdAt: data.createdAt ? (isDateObj(data.createdAt) ? data.createdAt : new Date(data.createdAt.replace(/-/g, '/') + '+00:00')) : null,
        updateAt: data.updateAt ? (isDateObj(data.updateAt) ? data.updateAt : new Date(data.updateAt.replace(/-/g, '/') + '+00:00')) : null,
        listPrice: data.listPrice >= 0 ? data.listPrice : null,
        isPrOnly: isPrOnly,
        hiddenMode: hiddenMode,
        showAsPR: !!data.showAsPR,
        permitAvm: !!data.permitAvm || hiddenMode,
        closePrice: data.closePrice >= 0 ? data.closePrice : null,
        priceChange: data.priceChange * 1 || 0,
        priceChangedDate: data.priceChangedDate ? new Date(data.priceChangedDate) : null,
        openHouses: data.openHouses || [],
        isFavorite: !!data.isFavorite,
        is3DTour: !!data.is3DTour,
        isHot: !!data.isHot,
        hotProbability: data.hotProbability,
        virtualLink: data.virtualLink || '',
        threeDTourLink: data.threeDTourLink || data.virtualLink || '',
        isVideoTour: !!data.isVideoTour,
        videoTourLink: data.videoTourLink || '',
        listingByMovoto: !!data.listingByMovoto,
        listingAgent: data.listingAgent || null,
        mlsDbNumber: data.mlsDbNumber || null,
        mlsId: data.mlsId || (data.mls ? data.mls.id : null),
        mlsNumber: data.mlsNumber || null,
        mls: data.mls || {},
        brokerageDetails: data.brokerageDetails || null,
        distance: data.distance,
        updatedStatus: data.updatedStatus || {},
        officeListName: data.officeListName || null,
        officeListPhone: data.officeListPhone || null,
        complianceListOffice: data.complianceListOffice || null,
        listingOfficeDescription: data.listingOfficeDescription || null,
        yearBuilt: data.yearBuilt >= 0 ? data.yearBuilt : null,
        hoafee: data.hoafee >= 0 ? data.hoafee : null,
        isCheapProperty: data.isCheapProperty,
        hideIsViewed: false,
        imageFormat: data.imageFormat || 'jpeg',
        imageDownloaderStatus: data.imageDownloaderStatus || 0,
        categorizedPhotos: data.categorizedPhotos,
        isNHS: !!data.isNHS,
        nhsBuilderId: data.nhsBuilderId || null,
        nhsBuilderName: data.nhsBuilderName || null,
        nhsCommunityId: data.nhsCommunityId || null,
        nhsCommunityName: data.nhsCommunityName || null,
        nhsListingId: data.nhsListingId || null,
        nhsListingType: data.nhsListingType || null,
        nhsMarketId: data.nhsMarketId || null,
        noteData: data.noteData || '',

        //should we change here to data.houseRealStatus === 'FOR_RENT'? and remove the isRentalActive
        isRentals: data.houseRealStatus === 'FOR_RENT',

        rentalPotential: data.houseRealStatus === 'FOR_RENT' ? false : data.rentalPotential || false,
        rentalListingType: data.rentalListingType || '',
        rentalListingId: data.rentalListingId || '',
        rentalBuildingId: data.rentalBuildingId || '',
        rentalFloorPlanId: data.rentalFloorPlanId || '',
        rentalLink: data.rentalLink || '',
        totalPhotosCount: data.photoCount || 0,
        marketTrendTable: data.marketTrendTable || {},
        videoURL: data.videoURL || '',
    };

    if (baseProperty.threeDTourLink.toLowerCase().split('?')[0] === baseProperty.videoTourLink.toLowerCase().split('?')[0]) {
        baseProperty.videoTourLink = '';
    }

    const compliance = getMlsCompliance(baseProperty.mlsId, complianceData);

    baseProperty.isCommingSoonListing = getIsCommingSoonListing(data.status);
    baseProperty.createTimeDisplay = timeDisplay(getListOrCreatedDate(baseProperty, data));
    if (baseProperty.soldDate && baseProperty.listDate) {
        baseProperty.soldTimeDisplay = timeDisplay(baseProperty.soldDate, baseProperty.listDate);
    }
    baseProperty.isSold = getIsSold(baseProperty);
    baseProperty.showSoldDate = isDateWithinSpecificMonths(baseProperty.soldDate, 6);
    baseProperty.isActive = getIsActive(baseProperty);
    baseProperty.isNew = getIsNew(baseProperty, compliance);
    baseProperty.isCondo = getIsCondo(baseProperty);
    baseProperty.isLand = getIsLand(baseProperty);
    baseProperty.hidePropertyPrice = !!(compliance && compliance.rule && compliance.rule.includes('hidePropertyPrice'));
    baseProperty.propertyTypeIcon = getPropertyTypeIcon(baseProperty);
    baseProperty.propertyTypeDisplay = getPropertyTypeDisplay(baseProperty);
    baseProperty.houseRealStatusDisplay = getPropertyStatusDisplay(baseProperty, data.labelDisplayName);
    baseProperty.price = getPrice(baseProperty, baseProperty.isSold);
    baseProperty.lastListPrice = getLastListPrice(baseProperty, baseProperty.price);
    baseProperty.showPriceChange = getShowPriceChange(baseProperty.lastListPrice, baseProperty.houseRealStatus === 'ACTIVE', baseProperty.isNew, baseProperty.isCommingSoonListing);
    baseProperty.isPriceReduced = getIsPriceReduced(baseProperty);
    baseProperty.show3DTour = getShow3DTour(baseProperty);
    baseProperty.area = getArea(baseProperty, baseProperty.isLand);
    baseProperty.areaUnit = getAreaUnit(baseProperty.isLand);
    baseProperty.pricePerArea = getPricePerArea(baseProperty.price, baseProperty.area);
    baseProperty.openDate = getOpenDate(baseProperty);
    baseProperty.openDateV1 = getOpenDateV1(baseProperty);
    baseProperty.categorizedPhotos = getCategorizedPhotos(baseProperty, compliance, user);
    baseProperty.houseCardTitleText = getHouseCardTitleText(baseProperty);
    baseProperty.addressId = getAddressId(baseProperty);
    if (isUserBrowser) {
        if (compliance) {
            baseProperty.mlsLogoSrc = populateMlsLogoSrc(cdnUrl, compliance, baseProperty.mlsId);
            baseProperty.mlsDescription = populateMlsDescription(compliance);
            baseProperty.mlsName = compliance.name || '';
            baseProperty.formatMlsInfo = populateMlsInfo(baseProperty, compliance);
            baseProperty.hideDaysOnMarket = compliance.rule.includes('hideDaysOnMarket');
            baseProperty.showProvidedBy = compliance.rule.includes('showProvidedBy');
            baseProperty.hideAvmAndRange = compliance.rule.includes('hideAvmAndRange') && !baseProperty.permitAvm;
            baseProperty.hideNew = compliance.rule.includes('hideNew');
            baseProperty.hidePriceReduced = compliance.rule.includes('hidePriceReduced');
            baseProperty.hidePublicRemark = compliance.rule.includes(`hidePublicRemarks${baseProperty.houseRealStatus}`);
            baseProperty.showRentalListings = compliance.rule.includes('showRentalListings');
            baseProperty.showBuyingOffice = compliance.rule.includes('showBuyingOffice');
            baseProperty.hideKeyInsights = compliance.rule.includes('hideKeyInsights');
            baseProperty.hideMarketStatistics = compliance.rule.includes('hideMarketStatistics');
        }
    } else {
        if (baseProperty.openHouses && baseProperty.openHouses.length) {
            baseProperty.openHouses.forEach(function (open) {
                open.openDateText = getOpenDateText(open);
            });
        }
    }
    getAppSeoJsonCards(baseProperty);
    var timeCreate = baseProperty.hideDaysOnMarket ? '' : baseProperty.createTimeDisplay;
    var timeSold = baseProperty.hideDaysOnMarket && baseProperty.soldDate ? '' : motime.formatDate(baseProperty.soldDate, { month: 'numeric' });
    var label = getLabel(data, baseProperty.isNew, timeCreate, timeSold, baseProperty.houseRealStatusDisplay);
    baseProperty.labelClass = label.labelClass;
    baseProperty.labelName = label.labelName;
    baseProperty.labelNameList = label.labelNameList;
    return baseProperty;
}
