import mapper from '../mapper/index.js';
import getFilter from '../glb/filter.js';
import { getSearchObjByPath, getSearchPropertyStatusBySearchObj } from '../../../../widget/spec/searchHelper';
const isSSR = typeof window === 'undefined';

var adsPerPage = -1;
function getPropertyList(arr, state) {
    if (arr && arr.length) {
        return arr.map(function (e) {
            return mapper.property(e, state);
        });
    }
    return [];
}
function updateFilterData(state, obj) {
    obj.propertyCriteriaForeclosed = 0;
    obj.searchPropertyStatus = getSearchPropertyStatusBySearchObj(obj);
    if (obj.minHoa > 0 && obj.maxHoa === 0) {
        obj.maxHoa = -1;
    } else if (obj.maxHoa > 0 && obj.minHoa === 0) {
        obj.minHoa = -1;
    }
    Object.assign(state.filter, obj);
}
function updateVisiableListings(state, data) {
    state.visiableListings = data;
}
function updateCurrentPageListings(state, data) {
    state.currentpageListings = data;
}

function updateViewStatus(state, value) {
    if (value !== state.currentViewStatus) {
        !isSSR && $.setCookie('LASTSEARCHVIEW', value);
        !isSSR && $.updateContext(['page']);
        state.previousViewStatus = state.currentViewStatus;
    }

    if (value == 'map-view') {
        state.loadMap = true;
    }

    state.currentViewStatus = value;
}

function updateSearchResult(state, data, glb) {
    state.mlsIds = data.mlsIds || [];
    if (adsPerPage === -1) {
        adsPerPage = glb && glb.mlsIds && glb.mlsIds.indexOf(102) >= 0 ? 0 : 4;
    }
    let listings = getPropertyList(data.listings, glb);
    const totalCount = data.totalCount;
    const pageIndex = state.filter.pageIndex || 1;
    if (!listings || !listings.length) {
        updateCurrentPageListings(state, []);
        state.firstZipcode = '';
        state.firstProperty = null;
        if (!state.paginationMode) {
            state.firstPageIndex = 0;
            state.pageCount = 0;
            state.totalCount = 0;
            state.listings = [];
        }
    } else {
        if (state.filter && !state.filter.sort) {
            let avgPrice;
            const sortByRecencyAndPrice = glb.splits['movoto-msp-sort-recency-price-CW-1678'] === 'on';

            if ((!avgPrice && glb.splits['movoto-CW-1214'] === 'on') || sortByRecencyAndPrice) {
                if (listings?.length > 0 && state?.marketSummary?.averageListingPrice) {
                    avgPrice = state.marketSummary.averageListingPrice;
                }
            }

            if (avgPrice > 0) {
                if (sortByRecencyAndPrice) {
                    // All listings <=24 hours old are at the top of the page, prioritized in descending order by how far away they are from the median listing price in that market
                    const now = glb.date ? +new Date(glb.date) : Date.now();
                    const newListings = getListingsSortedByAveragePrice(
                        listings.filter((listing) => {
                            const hoursOld = Math.abs(now - listing.createdAt) / (60 * 60 * 1000);
                            return hoursOld <= 24;
                        }),
                        avgPrice
                    );
                    // All listings >24 hours old are shown below the first set of listings, in descending order of recency
                    const oldListings = listings
                        .filter((listing) => {
                            const hoursOld = Math.abs(now - listing.createdAt) / (60 * 60 * 1000);
                            return hoursOld > 24;
                        })
                        .sort((listing) => listing.createdAt);
                    listings = [...newListings, ...oldListings];
                } else {
                    listings = getListingsSortedByAveragePrice(listings, avgPrice);
                }
            }
        }
        if (listings[0]) {
            state.firstZipcode = listings[0].geo && listings[0].geo.zipcode;
            state.firstProperty = listings[0];
        }
        updateCurrentPageListings(
            state,
            listings.map(function (e) {
                return e.propertyId;
            })
        );
        if (!state.paginationMode) {
            state.firstPageIndex = pageIndex;
            state.pageCount = Math.ceil(totalCount / state.pageSize);
            state.totalCount = totalCount;
            state.displayTotalCount = totalCount + state.pageCount * adsPerPage;
            state.displayPageSize = state.pageSize + adsPerPage;
            state.listings = new Array(state.pageCount);
            state.listings.splice(pageIndex - 1, 1, listings);
        } else {
            state.firstPageIndex = Math.min(state.firstPageIndex, pageIndex);
            state.listings.splice(pageIndex - 1, 1, listings);
        }
    }
    state.attributes = data.attributes;
    state.searchType = data.searchType;
    state.community = data.communityName;
    updateDisplayIndex(state, pageIndex);
}

function getListingsSortedByAveragePrice(listings, avgPrice) {
    // sort by closest to average price
    return listings.sort((a, b) => {
        if (a.price === b.price) return 0;
        if (!a.price) return 1;
        if (!b.price) return -1;
        return Math.abs(a.price - avgPrice) - Math.abs(b.price - avgPrice);
    });
}

function updateMapListings(state, { data, glb, path }) {
    if (data.listings && data.listings.length) {
        data.listings.forEach((item) => {
            item.light = true;
        });
    }
    state.mapListingsPath = path;
    state.mapListings = data.listings;
}

function resetMapListings(state) {
    state.mapListingsPath = '';
    state.mapListings = [];
}

function updateNearyInfo(state, data) {
    state.nearbyCities = data.topNearbyCityList || [];
    state.nearbyZipCodes = data.topZipCodeList || [];
    state.nearbyNeighborhoods = data.topNeighborhoodList || [];
    state.nearbyCounties = data.topNearbyCountyList || [];
}

function updateSchoolData(state, school) {
    state.schoolData = Object.assign({}, state.schoolData, {
        schoolCount: school.totalCount || 0,
        list: school.list || [],
    });
}

function updateMapSearchGeo(state, { data, glb }) {
    state.viewUrl = data.viewUrl || glb.requestUrlRaw.replace(/^\//, '');
    const { geo, filter } = getSearchObjByPath(state.viewUrl);
    let defaultFilter = getFilter();
    //handle case when trigger by history.back()
    //state.filter is the old value, filter is the new value
    //check if anything key in state.filter is not default value, and the key is not mentioned in filter,
    //then update filter with a default value as reset command, (if keep undefined, will cause UI no update)
    Object.keys(defaultFilter).forEach((key) => {
        if (filter[key] === undefined && state.filter[key] !== defaultFilter[key]) {
            filter[key] = defaultFilter[key];
        }
    });

    const hasBoundary = filter.boundary;
    const coords = filter.coordinates;
    const firstListingGeo = data && data.listings && data.listings[0] && data.listings[0].geo;

    let mode;
    if (!coords) {
        !isSSR && $.setStorage('drawModeEnabled', false);
        !isSSR && $.setStorage('drawCoordinates', null);
    }
    if (geo.geoType === 'GPS') {
        mode = filter.rentals ? state.searchMode.forrent : state.searchMode.forsale;
    } else if (hasBoundary) {
        mode = state.searchMode.default;
    } else if (coords) {
        mode = state.searchMode.coords;
    } else {
        mode = state.searchMode.default;
    }

    let geoInfo;
    if (mode === state.searchMode.forsale || mode === state.searchMode.forrent) {
        geoInfo = mapper.geo(data.geoInfo, filter.rentals);
        geoInfo = Object.assign(geoInfo, {
            geoType: 'GPS',
            lat: (data.geoInfo && data.geoInfo.lat) || (firstListingGeo && firstListingGeo.lat) || (geo && geo.lat),
            lng: (data.geoInfo && data.geoInfo.lng) || (firstListingGeo && firstListingGeo.lng) || (geo && geo.lng),
        });
    } else if (data && data.geoInfo) {
        geoInfo = mapper.geo(data.geoInfo, filter.rentals);
    } else if (firstListingGeo && firstListingGeo.city && firstListingGeo.state) {
        geoInfo = mapper.geo(
            {
                city: firstListingGeo.city,
                lat: firstListingGeo.lat,
                lng: firstListingGeo.lng,
                state: firstListingGeo.state,
            },
            filter.rentals
        );
    } else {
        geoInfo = mapper.geo(geo, filter.rentals);
    }
    state.hasBoundary = hasBoundary;
    updateSearchMode(state, mode);
    updateFilterData(state, filter);
    updateGeoInfo(state, geoInfo, filter.rentals);
}

function updateMapSearchState(state, { data, glb, molang }) {
    // this piece of code is to filter out the listings out of drawn bounds
    const drawCoordinates = !isSSR && $.getStorage('drawCoordinates');
    if (drawCoordinates && state.filter.coordinates && data.listings) {
        data.listings = data.listings.filter((item) => {
            var listingLAT = item.geo.lat;
            var listingLNG = item.geo.lng;
            var northEastLAT = parseFloat(drawCoordinates.northeast.lat);
            var southWestLAT = parseFloat(drawCoordinates.southwest.lat);
            var northEastLNG = parseFloat(drawCoordinates.northeast.lng);
            var southWestLNG = parseFloat(drawCoordinates.southwest.lng);
            if (listingLAT > southWestLAT && listingLAT < northEastLAT && listingLNG > southWestLNG && listingLNG < northEastLNG) {
                return item;
            }
        });
    }
    state.mspSeoLinks = data.mspSeoLinks || {};
    state.dataList = data.dataList || [];
    state.realEstateLinks = data.realEstateLinks || [];
    state.marketSummary = data.marketSummary || {};
    state.snapshotData = data.snapshotData || {};
    state.marketTrendsSummary = data.marketTrendsSummary || [];
    state.nearbySchools = data.nearbySchools || null;
    state.schoolSummary = data.schoolSummaryData || null;
    updateSearchResult(state, data, glb);
    updateNearyInfo(state, data);
    updateFilterType(state, data);
    updateBoundary(state, data);
    updateSchoolData(state, data.schoolData || {});
    state.searchCount++;
    state.viewUrl = data.viewUrl;
    state.topNlistingsInCity = data.topNlistingsInCity;
    state.refinedInventoryCounts = data.refinedInventoryCounts;
    state.shortcutFilters = data.shortcutFilters;
    state.localHighlightGeo = data.localHighlightGeo;
    state.relatedLinks = data.relatedLinks || {};
    state.attrSSRLinks = data.attrSSRLinks || {};
    state.isUnfilteredUrl = data.isUnfilteredUrl || false;
    updateSearchInput(state, data, glb, molang);
    if (data.lightDpp) {
        state.lightDpp = mapper.property(data.lightDpp, glb);
        if (data.lightDpp.nearbyHomes) {
            state.lightDpp.nearbyHomes = getPropertyList(data.lightDpp.nearbyHomes, glb);
        }
    }
}

function updateBoundary(state, data) {
    if (!state.geo || !state.geo.hasGeoInfo) {
        state.boundaryIndexId = '';
    } else if (state.filter.schoolId) {
        var school = null;
        if (data.searchSchoolInfo) {
            school = data.searchSchoolInfo;
        } else if (data.schoolData && data.schoolData.length) {
            school = data.schoolData.find(function (e) {
                return e.uid === state.filter.schoolId;
            });
        }
        if (school && school.boundaryId) {
            state.boundaryIndexId = school.boundaryId;
        } else {
            state.boundaryIndexId = '';
        }
    } else if (state.currentSearchMode === state.searchMode.forsale || state.currentSearchMode === state.searchMode.forrent) {
        state.boundaryIndexId = '';
    } else if (state.currentSearchMode === state.searchMode.default) {
        state.boundaryIndexId = data.boundaryIndexId || '';
    } else if (data.boundaryIndexId) {
        state.boundaryIndexId = data.boundaryIndexId;
    }
    if (data.boundaryGeoId) {
        state.boundaryGeoId = data.boundaryGeoId;
    }
    if (data.boundaryName) {
        state.boundaryName = data.boundaryName;
    }
    if (data.boundaryState) {
        state.boundaryState = data.boundaryState;
    }
}

function updateSearchInput(state, data, glb, molang) {
    if (data.searchSchoolInfo && data.searchSchoolInfo.name) {
        state.searchInput = data.searchSchoolInfo.name;
    } else if (data.geoInfo) {
        if (state.currentSearchMode === state.searchMode.forsale || state.currentSearchMode === state.searchMode.forrent) {
            //please keep only en version for here, it in our logic
            state.searchInput = 'Current Location';
        } else if (data.geoInfo.searchTerm) {
            state.searchInput = data.geoInfo.searchTerm;
        }
    } else if (data.searchText) {
        state.searchInput = data.searchText;
    } else if (data.hasFilter) {
        state.searchInput = 'Current Location';
    }
}

function updateFilterType(state, data) {
    state.filterType = data.filterType;
}

function updateDisplayIndex(state, value) {
    state.displayPageIndex = value;
}
function updateSearchMode(state, value) {
    state.currentSearchMode = value;
}
function updateSearchTrigger(state, value) {
    state.searchTrigger = value;
}
function updateIsNewSearch(state, value) {
    state.isNewSearch = value;
}

function updateIsSearching(state, value) {
    state.isSearching = value;
}

function updateGeoInfo(state, geoInfo, rentals) {
    if (geoInfo && geoInfo.hasGeoInfo === undefined) {
        state.geo = Object.assign(state.geo, mapper.geo(geoInfo, rentals));
    } else {
        state.geo = Object.assign(state.geo, geoInfo);
    }
    if (state.geo && state.geo.state) {
        !isSSR && $.setStorage('mapsearch.geo', state.geo);
    }
}

function updateGeoLocationState(state, position) {
    if (position) {
        state.geo.lat = position.lat;
        state.geo.lng = position.lng;
    }
}

function updateNearbyData(state, data) {
    state.nearbyData = data || [];
}

function updateFilterTrigger(state, data) {
    state.filterTrigger = data || '';
}

function updatePOIByBoundaryIndexId(state, data) {
    state.poiList = data;
}

function updatePOIByCoordinates(state, data) {
    state.poiList = data;
}

export default {
    updateNearbyData,
    updateDisplayIndex,
    updateVisiableListings,
    updateCurrentPageListings,
    updateFilterData,
    updateViewStatus,
    updateMapSearchGeo,
    updateMapSearchState,
    updateSearchResult,
    updateSearchMode,
    updateSearchTrigger,
    updateFilterTrigger,
    updateIsNewSearch,
    updateIsSearching,
    updateSearchInput,
    updateGeoLocationState,
    updateSchoolData,
    updatePOIByBoundaryIndexId,
    updatePOIByCoordinates,
    updateMapListings,
    resetMapListings,
};
