function fetchGuideCategories({commit, getters, dispatch, rootState}, searchUrl){
    const url = `${rootState.glb.appUrl}api/v/guide/categories/`;
    const data = {
        path: searchUrl || '',
    }
    return dispatch('glb/sendRequest', {url: url, data: data, method: 'GET', requestName: 'guide'}, {root: true})
    .then(res=>{
        if(res && res.data){
            commit('updateCategoryList', {data: res.data, glb: rootState.glb});
            return res.data;
        }
        return Promise.reject(res);
    });
}

export default {
    fetchGuideCategories
};
