export default {
    seeLessHighlights: 'Show Less Features',
    seeAll: 'Show All',
    highlights: 'Features',
    getQuotes: 'Get Quotes',
    getHomeUpdates: 'Get Home Value Updates',
    homeSaveProfile: 'Home Saved to Profile',
    estimate: 'Estimate',
    range: 'Range',
    average: 'Average',
    notProvidedMLS: 'Not provided by MLS',
    bestValueHomes: 'Best Value Homes',
    newDppBestValueHomes: 'Best value homes',
    recentlyViewed: 'Recently Viewed',
    newIn: 'New in $zip',
    priceReducedIn: 'Price Reduced in $zip',
    newDppPriceReducedIn: 'Price reduced in $zip',
    recentlySoldIn: 'Recently Sold in $zip',
    openIn: 'Open houses in $zip',
    calculateWithLender: 'Calculate your monthly payment with a lender',
    creditMonitoring: 'View your latest credit score now',
    creditMonitoring1: 'View Your Latest Credit Score Now',
    faqProperty: 'Questions for This Property',
    movotoCalculated: 'Calculated By Movoto',
    movotoPowered: 'Powered By Movoto',
    propertyNav: {
        back: 'Back',
        search: 'Search',
        next: 'Next',
    },
    propertyButton: {
        photos: 'Photos',
        photo: 'Photo',
        map: 'Map',
        save: 'Save',
        saved: 'Saved',
        share: 'Share',
        call: 'Call',
        edit: 'Edit',
    },
    mvtEmail: {
        contactAgent: 'Contact Agent',
        recipientsEmail: 'Recipients Email',
        shareHome: 'Share This Home',
        checkoutHome: 'Hi, Check out this home I found on Movoto.',
        friendsEmail: "Friend's Email",
        name: 'Your Name',
        email: 'Your Email',
        sendmail: 'Share',
        sendCopy: 'Send me a Copy',
        thanksForSharing: 'THANKS FOR SHARING',
        seeThisHome: 'Go See This Home',
        thankYou: 'THANK YOU!',
        agentContact: 'One of our Agent Relations Associates will contact you shortly.',
        saveHome: 'Save Home',
        likeThisHome: 'Like this home? Get emailed when updates are made to the listing.',
        disclaimer: "Once you've entered your email address, we'll start sending you monthly valuation reports from our personal home finance tool, Movoto Homeowner.",
        movotoTerms1:
            "By submitting your information, you agree we can share your information with our network of real estate & mortgage professionals (<a class='text-dotted' target='blank' href='https://www.movoto.com/about/terms-of-use/#affiliates'>affiliates</a>) (or through their agents) and consent to receive marketing emails related to your inquiry from Movoto or affiliates to the email address you provided. You further agree that such affiliates may share information with Movoto and each other. You also agree to our <a class='text-dotted' target='blank' href='https://www.movoto.com/about/terms-of-use/'>Terms & Conditions</a>  and  <a class='text-dotted' target='blank' href='https://www.movoto.com/about/privacy-policy/'>Privacy Policy</a>. Consent not required for purchase.",
        movotoTerms2: 'Terms of Use and Privacy Policy',
        sharemyprofile: 'Share My Profile',
        signupTerms:
            "By joining you agree to Movoto.com's<br/><a class='link' target='blank' href='https://www.movoto.com/about/terms-of-use/'>Terms of Use</a>  and  <a class='link' target='blank' href='https://www.movoto.com/about/privacy-policy/'>Privacy Policy</a>",
    },
    dppTitle: {
        drop: 'Drop',
        increase: 'Increase',
        bd: 'Bd',
        ba: 'Ba',
        mortgage: 'Mortgage',
        apply: 'Apply',
        refinance: 'Refinance',
        rent: 'For Rent',
        sale: 'For Sale',
        coming: 'Coming Soon',
        new: 'New',
        contract: 'Active Under Contract',
        pending: 'Pending',
        sold: 'Sold',
        offMarket: 'Off Market',
        delisted: 'Delisted',
        publicRecord: 'Public Record - Not For Sale / Not For Rent',
        bed: 'bed',
        bath: 'bath',
    },
    propertyTag: {
        virtualTour: 'Virtual Tour',
        lakefront: 'Lakefront',
        waterfront: 'Waterfront',
        '3dtour': '3D Tour',
        videoTour: 'Video Tour',
    },
    propertyDetails: {
        propertyDetailsHeader: 'Property Details',
        landValueWithUnit: 'Land Value ($/Acre)',
        homeValueWithUnit: 'Home Value ($/Sqft)',
        landValue: 'Land Value',
        homeValue: 'Home Value',
        landHomeEstTip:
            "How did we calculate the home's value? Movoto calculates your home's value through our version of an Automated Valuation Model (AVM). Typically AVMs take into account a home's characteristics (beds, baths, square footage) and look at public record sales data of similar homes in your neighborhood. The movoto estimate is not a formal appraisal. For a more accurate valuation, we can connect you with one of our top local Real Estate agents, who are experts in your area. You can also improve this estimate by removing homes that are not similar or switch to Sold homes in the table below.",
        hoaFees: 'HOA Fees',
        hoa: 'HOA',
        propertyType: 'Property Type',
        propertyStatus: 'Property Status',
        daysOnMarket: 'Days on Market',
        listedToday: 'Listed Today',
        timeOnMovoto: 'Time on Movoto',
        lotSize: 'Lot Size',
        lot: 'Lot',
        yearBuilt: 'Built',
        garageSpaces: 'Garage Spaces',
        garageSpace: 'garage space',
        cooling: 'Cooling/AC',
        heating: 'Heating',
        levels: 'Levels/Style',
        neighborhood: 'Neighborhood',
        county: 'County',
        transitStation: 'Transit Station',
        distanceToCoast: 'Distance to Coast',
        distanceToLake: 'Distance to Lake',
        greatSchool: 'GreatSchools Rating',
        rating: 'Rating',
        ratingTip: 'This score is based on the average rating of the school’s that are assigned to this home.',
        propertyOrientation: 'Property Orientation',
        mortgagePayment: 'Mortgage Payment',
        getPreapproved: 'Get Preapproved',
        getPrequalified: 'Get Prequalified',
        getBetterMortgage: 'Get Preapproved',
        refinanceNow: 'Refinance Now',
        meetAgent: 'Meet a Realtor to find homes',
    },
    propertyFeature: {
        extLink: 'External Link',
        lessPropertyDetails: 'Show Less Property Details',
        allPropertyDetails: 'Show All Property Details',
        seeLess: 'See Less',
        ctaTitle: 'Interested In More Details?',
        ctaSub: 'Our experienced agents can provide even more details about the property, including key features, recent renovations, location information and past sales history.',
    },
    propertyOpenHouse: {
        openHouse: 'Open house',
        scheduleTour: 'Request a virtual or in-person tour',
        scheduleTourBuyerAgent: 'Request tour with a top Real Estate agent',
        requestTour: 'Request tour',
    },
    propertySchedule: {
        tourThisPropertyWithOJOAgent: 'Tour This Home with $firstName',
        tourSimilarNearBy: 'Tour Similar Homes Nearby',
        tourThisProperty: 'Go Tour This Property',
        tourThisHome: 'Tour this home with a top agent',
        scheduleConsultation: 'Schedule consultation',
        inPerson: 'In-Person',
        liveVideo: 'Live Video',
        morning: 'Morning',
        afternoon: 'Afternoon',
        evening: 'Evening',
        requestTour: 'Request a Tour',
        connectLocalAgent: 'Connect with a local agent who can give you a personalized tour. Cancel anytime.',
        notMls: 'Not provided by MLS',
        seeThisHome: 'When would you like to see this home?',
    },
    propertyMortgage: {
        paymentCalculator: 'Payment calculator',
        prequalifyMortgage: 'Pre-qualify',
        prequalifyHere: 'Pre-qualify here',
        refinance: 'Refinance',
        findLender: 'Find a Lender',
        principalInterest: 'Principal & Interest',
        propertyTaxes: 'Property Taxes',
        monthlyHoa: 'Monthly HOA fee',
        mortgageInsurance: 'Mortgage Insurance',
        insurance: 'Insurance',
        downPaymentTitle: 'Down Payment & Home Price',
        downPayment: 'Down payment',
        downPaymentCash: 'Down payment from cash',
        downPaymentEquity: 'Down payment from home equity',
        percent: 'Percent',
        homePrice: 'Home price',
        interestRateTitle: 'Interest Rate, Loan Type & Credit',
        interestRate: 'Interest rate',
        checkCreditNow: 'Homebuyers: Check your credit now',
        checkCredit: 'Check your credit before you buy',
        creditChallenges: 'Credit Challenges? Improve Your Credit Score In 3 Steps',
        creditIssues: 'Credit issues? Get a free credit consultation today',
        taxesTitle: 'Taxes, Insurance & HOA',
        taxEstimate: 'Yearly property tax estimate',
        homeInsurance: 'Yearly home insurance',
        mortgageRates: 'Mortgage Rates',
        additionalSerices: 'Additional Services',
        rentalServices: 'Rental Services',
        poweredBy: 'Powered by',
        advertiserDisclosure: 'Advertiser Disclosure',
        advertiserDisclosureTip:
            'The listings that appear on this page are from companies from which this website and Bankrate receives compensation, which may impact how, where and in what order products appear. This table does not include all companies or all available products. Bankrate does not endorse or recommend any companies.',
        details: 'Details',
        detailsTip:
            '30 year mortgage offers are based on a 20% down payment and 360 equal monthly payments. Monthly payment does not include taxes or insurance. Actual monthly payment will be higher.',
        privacyPolicy: 'Privacy Policy',
        noMatch: 'No Rates Match Your Criteria',
        connectLocalLender: 'Get connected to a local lender.',
        findLowRate: 'Find a Low Rate',
        lowerMortgagePayment: 'Lower your mortgage payment',
        cableDiscount: 'Cable/Internet/Phone Discounts',
        veteransEligibility: 'Veterans: Get Preapproved for a $0 Down VA Loan',
        veteransCanAfford: 'Veterans: Getting Preapproved is Step #1',
        brownBagMarketingData: 'Data provided by Brown Bag Marketing, Inc. Payments do not include amounts for taxes and insurance premiums.',
        click: 'Click',
        here: 'here',
        forMoreInfo: 'for more information on rates and product details.',
        compareTodaysMortgageRates: 'Compare today’s mortgage rates',
        ctaTitle: 'Reduce Your Payment',
        ctaSub: 'Our experienced agents can guide you in connecting with lenders, understanding local incentives, and what comprises of your property taxes and/or HOA fees.',
        homeownershipcostcalc: 'Home Ownership Cost Calculator',
    },
    desc: {
        description: 'Description',
        activeDppDescription: 'About $streetAddress',
        listedBy: 'Listed by',
    },
    propertyEstimate: {
        landValueComps: 'Land value & comps',
        homeValueComps: 'Home value & comps',
        above: 'above',
        below: 'below',
        soldPrice: 'sold price',
        listPrice: 'list price',
        estHomeSubText1: 'This home value estimate is for a home built on this land and based on the following nearby homes which average',
        estHomeSubText2: 'in value and',
        estHomeSubText3: 'in size.',
        estSubText1: 'based on the following homes which average',
        estSubText2: 'This calculation is using the public record building area of',
        estPriceTip: 'To improve this estimate, remove homes that are not similar or switch to Sold homes in the table below.',
        estDisclaimer: 'Our one year forecast is a prediction of what the home value estimate will be one year from now. We don’t actually know what will happen in the year.',
        land: 'Land',
        home: 'Home',
        sale: 'For Sale',
        sold: 'Recently Sold',
        viewed: 'My Last Viewed',
        bed: 'Bed',
        bath: 'Bath',
        houseSize: 'House size',
        hoaFee: '$HOA/Mo',
        lotSize: 'Lot size',
        yearBuilt: 'Built',
        distance: 'Distance',
        status: 'On Movoto',
        address: 'Address',
        estNote: 'Movoto‘s Price/$unit estimate is not a professional appraisal, it is a starting point to help you price this home.',
        ctaTitle: 'Need An Accurate Valuation?',
        ctaSub: 'Our experienced agents can provide a comprehensive comparative market analysis to accurately value this home.',
    },
    saleProceeds: {
        saleProceedsCalc: 'Sale Proceeds Calculator',
    },
    propertyHistory: {
        history: 'Property history',
        activeDppHistory: 'Property History for $streetAddress',
        date: 'Date',
        status: 'Status & Source',
        price: 'Price',
        change: 'Change',
        source: 'Source',
        ctaTitle: 'Property History Questions?',
        ctaSub: 'Our experienced agents can provide you with more property history, including sales history, tax records, recent renovations, and permit history.',
    },
    propertyClimate: {
        climateRisk: 'Average Climate Check Risk',
        climateChange: 'How will climate change affect you?',
        climateCheckDesc: "ClimateCheck™ analyzes a property's risk using the latest modeling and data from climate scientists, universities, and federal agencies.",
        showMore: 'Show More',
        learnMore: 'Learn More',
        ctaTitle: 'Learn About The Climate',
        ctaSub: 'Our experienced agents can provide you more detailed information about the storm, flood, fire, and other climate related risks in this area.',
    },
    propertySchool: {
        assignedSchool: 'Assigned Schools',
        schoolDesc: '$schoolLength Schools with ratings between $schoolRating',
        schoolsInCity: 'All Schools in $city, $state',
        greatSchoolsData: 'Data Provided by GreatSchools.org',
        greatSchoolTip:
            'School information provided by GreatSchools.org. Attendance boundaries are supplied by Maponics and are subject to change without notice. Please verify with the appropriate school district to confirm legal eligibility and attendance rules.',
        homesInSchoolZone: "Show homes in this school's zone",
        removeSchoolZone: "Remove this school's zone",
        rating: 'Rating',
        name: 'Name',
        type: 'Type',
        grade: 'Grade',
        reviews: 'Reviews',
        distance: 'Distance (mi)',
        ctaTitle: 'Questions About Schools?',
        ctaSub: 'Our experienced agents can provide you with more information about schools, enrollment requirements, and educational programs in this area.',
    },
    propertyMarket: {
        statistics: 'Market statistics',
        listPrice: 'List Price in',
        year: 'Year',
        years: 'Years',
        price: 'Price',
        inventory: 'Inventory',
        days: 'Days',
        noChart: 'No chart available',
        comparisonProperties: 'Comparison to 30 closest properties',
        statisticsByMovoto: 'Statistics calculated by Movoto',
        medianPrice: 'Median List Price in',
        yoy: 'Year over Year',
        ctaTitle: 'Learn More About The Market',
        ctaSub: 'Our experienced agents can provide you market insights including homes recently under contract, sales in the area, price trends, and other market dynamics.',
    },
    propertyLocalScore: {
        neighborhoodScore: 'Neighborhood Scores',
        foodDesc: 'The overall rating score is divided into 4 categories: Price, Quality, Quantity, and Diversity. All together they sum to the maximum score of 100.',
        kidDesc: 'The overall rating score is divided into 2 categories: Parks and Points of Interest. All together they sum to the maximum score of 100.',
        dogDesc: 'The overall rating score is divided into 3 categories: Trails, Parks, and Points of Interest. All together they sum to the maximum score of 100.',
        walkrateLowDesc: 'Car-Dependent - Nearly all locations and activities require a car',
        walkrateAvgDesc: 'Car-Dependent - Most locations and activities require a car.',
        walkrateGoodDesc: 'Definitely Walkable - Most locations and activities are walkable.',
        walkrateBestDesc: 'Walker’s Dream - Almost all locations and activities are walkable.',
        ctaTitle: 'Learn About The Neighborhood',
        ctaSub: 'Our experienced agents can help you learn more about the neighborhood, attractions, parks, schools, and more.',
    },
    propertyLocalhighlight: {
        insights: 'Property Insights',
        seeMore: 'See More',
        ctaTitle: 'More Property Insights',
        ctaSub: 'Our experienced agents can provide you even information about the property and the area such as information about the weather, nearby amenities, transportation, and more.',
    },
    propertyCommute: {
        commute: 'Commute Distance & Time',
        addLocation: 'Add Commute Location',
        commuteAddress: 'Enter commute address',
        city: 'City',
        distance: 'Distance (mi)',
        commuteByCar: 'Commute by Car (min)',
        allCommuteTimes: 'All Commute Times',
        commuteDesc: '$distance Mile, $carCommute Minute Commute to $cityName',
        myCommuteTime: 'My commute',
        enterDistance: 'Enter a destination to see travel time and distance.',
    },
    hotleadForm: {
        genericOjoAgentQuestion: '',
        askOjoAgent: 'Ask $firstName a Question',
        askQuestion: 'Ask Us a Question',
        listingAgent: 'Connect with a Local Listing Agent',
        requestInfo: 'Request Information',
        askBuilder: 'Ask Builder a Question',
        questionAbout: 'I would like to ask a question about',
        scheduleViewing: 'Request a Viewing',
        scheduleViewingAgent: 'Request viewing with a top local Real Estate agent',
        tourNearby: 'Go Tour Nearby Properties',
        send: 'Send',
        go: 'Go',
        submit: 'Submit',
        tourPlace: 'Tour This Place',
        requestTour: 'Request a Tour',
        interestIn: 'I am interested in scheduling a viewing of',
        interestInSimilar: 'I am interested in scheduling a viewing of similar homes to',
        seeHomes: 'Go See Similar Homes',
        tourHome: 'Tour a Similar Home',
        scheduleVirtual: 'Schedule a virtual tour',
        virtualTour: 'I am interested in scheduling a virtual tour of',
        virtualTourSimilar: 'I am interested in scheduling a virtual tour of similar homes to',
        attendOpenHouse: 'Attend the Open House',
        interestedInOpen: 'I am interested in attending the open house at',
        interestedIn: 'I am interested in',
        contactNow: 'Contact Now',
        connectToManager: 'Connect me to the property manager to discuss',
        connectAgent: 'Connect me to an agent to discuss',
        msgBuyerAgent: "Message a buyer's agent",
        connectBuyerAgent: "Connect with a buyer's agent",
        msgOjoAgent: 'Message a top local Real Estate agent',
        veterancheckbox: 'I or my spouse served in the military',
        connectMovotoHomeLoans: "Connect with a <span style='font-weight: bold; color: #0E6959'>Movoto Home Loans</span> expert to explore financing opportunities.",
        connectMovotoHomeLoans_on1: 'I want pre-approval information from Movoto Home Loans',
        connectMovotoHomeLoans_on2: 'I’m interested in pre-approval',
    },
    propertyNearby: {
        nearby: 'Nearby Homes',
        newDppNearby: 'Nearby homes',
        distance: 'Distance',
        similar: 'Similar',
        listDate: 'List Date',
        priceHigh: 'Price High',
        priceLow: 'Price Low',
        sqftBig: 'Sqft Big',
        sqftSmall: 'Sqft Small',
        viewOnMap: 'View on map',
        soldBy: 'Sold By Movoto',
        soldByOn: 'Sold By Movoto On',
        listedBy: 'Listed by Movoto',
    },
    faq: {
        faqHeader: 'FAQ',
    },
    propertyPR: {
        pr: 'Public Record',
        dataProvided: 'Data Provided by County Record',
    },
    seo: {
        homesForSale: 'Homes for Sale',
        homesForRent: 'Homes for Rent',
        realEstateAndHomes: 'Real Estate & Homes for Sale',
        realEstateAndHomesRent: 'Real Estate & Homes for Rent',
        view: 'View',
        homesForSaleIn: 'homes for sale in',
        homesForSaleRentIn: 'homes for rent in',
        filteredBy: 'filtered by',
        openHouse: 'Open Houses',
        singleFamily: 'House',
        condo: 'Condo/Townhouse',
        new: 'New Listings',
        priceReduce: 'Recent Price Reduced',
        singleFamilyTitle: '$city Single Family Real Estate & Homes for Sale',
        condoTitle: '$city Condo Real Estate & Homes for Sale',
        openHouseTitle: '$city Open Houses Real Estate & Homes for Sale',
        newTitle: '$city New Listings Real Estate & Homes for Sale',
        priceReduceTitle: '$city Price Reduced Real Estate & Homes for Sale',
    },
    propertyAgents: {
        mvtAgents: 'Agents in',
        noResults: 'No Results',
        askQuestion: 'Ask a Question',
    },
    propertyMap: {
        location: 'Location',
        streetView: 'Street View',
        satellite: 'Satellite',
    },
    propertyPoi: {
        commute: 'Commute',
        addCommute: 'Add Commute',
        poi: 'Points of Interest',
        poiCard: 'Find Special Places Nearby',
        nearbyCafe: 'Groceries, Cafés & Restaurants near $address',
        showMore: 'Show More',
        viewMap: 'View Map',
        dataProvided: 'Data Provided by Precisely',
        ctaSub: 'Our experienced agents can provide you more information about the neighborhood and nearby attractions.',
    },
    mvtNearby: {
        noResults: 'No Results',
    },
    sellbanner: {
        meetWithAgent: 'Meet With a top local agent',
        getConnected: 'Get Connected',
        connectNow: 'Connect Now',
        isYourHome: 'Is This Your Home?',
        homeValue: "Discover your home's value",
        valuationDesc: 'Receive a free, accurate valuation of your home, so you can make the right financial decisions.',
        getValuation: 'Get Valuation',
        manageInvestment: 'Manage your investment',
        trackHomeValueDesc: 'Track your home’s value, find ways to save, and build long-term wealth until you’re ready to make a move.',
        digsByOjo: 'Try Movoto Homeowner',
        selling: 'Selling before you buy?',
        findHomesWorth: 'Find out what your home’s worth and maximize your sale with a local expert.',
        getAValuation: 'Get a Valuation',
        rightTimeSell: 'Identify the right time to sell',
        getStarted: 'Get Started With Digs',
    },
    propertyArticle: {
        getToKnow: 'Get To Know',
        articleDesc: '$number Articles About Nearby Areas',
        showMore: 'Show More',
    },
    hotBanner: {
        hotDesc: 'This home is <b>HOT</b>. There is a 80% likelihood this home will go under contract in 15 days.',
        goTomorrow: 'Go Tomorrow',
        goToday: 'Go Today',
    },
    hotButton: {
        manageAssignedAgent: 'Message Your Agent',
        messageAssignedAgent: 'Send Message',
        sendReq: 'Send Request',
        chkAvlbility: 'Check Availability',
        msgAgent: 'Message Agent',
        askAgent: 'Ask an agent',
        message: 'Message',
        fakeChatBanner1: 'An experienced real estate agent wants to speak to you about this property right now!',
        fakeChatBanner2: 'Want to talk to a local real estate expert in $city?',
        maybeLater: 'Maybe later',
        requestTour: 'Request Tour',
        requestaTour: 'Request a Tour',
        requestInfo: 'Request Info',
        scheduleTour: 'Schedule Tour',
        contactAgent: 'Contact Agent',
        owner: 'I Own This Home',
        ownerDashboard: 'Owner Dashboard',
        requestThisTime: 'Request This Time',
        confirm: 'Confirm',
        confirmInfo: 'Confirm Your Info',
        sell: 'Sell',
        buy: 'Buy',
        both: 'Both',
        other: 'Other',
        claimHome: 'Claim Home',
        iAmOwner: 'I’m the Owner',
        whoIsOwner: "Who's the owner?",
        requestHomeDetails: 'Request Home Details',
        subcopyaskagent: "We'll connect you to a local real estate agent who can answer your question on this or any other property.",
    },
    propertyChat: {
        sendMessage: 'Send a Message',
        interested: 'Hi there! I see that you’re interested in',
        assistYou: 'How can I assist you?',
        contactManager: 'Contact Property Manager',
        contactAgent: 'Contact Agent',
        videoChat: 'Video Chat',
        scheduleTour: 'Schedule a Tour',
        sellHome: 'Sell My Home',
        buyHome: 'Buy a Similar Home',
        askUs: 'Or ask us anything...',
        provideContactInfo: 'Please provide your contact info so I know how to reach you.',
        InterestedInCPM: 'I am interested in contacting the property manager for',
        InterestedInVT: 'I am interested in a video tour of',
        InterestedInMoreInfo: 'I am interested in more information about',
        InterestedInST: 'I am interested in scheduling a tour of',
        getInTouch: 'Get In Touch',
    },
    dppVeterans: {
        veteransTitle: 'Veterans & Military Benefits',
        veteransSub: 'Veterans take advantage of your unique benefits',
        veteransTitleNew: 'Veterans & military benefits',
    },
};
