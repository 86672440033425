export default {
    seeLessHighlights: 'Mostrar menos características',
    seeAll: 'Ver todos',
    highlights: 'Características',
    getQuotes: 'Obtener citas',
    getHomeUpdates: 'Obtenga actualizaciones sobre el valor de la vivienda',
    homeSaveProfile: 'Inicio guardado en el perfil',
    estimate: 'Estimar',
    range: 'Rango',
    average: 'Promedio',
    notProvidedMLS: 'No proporcionado por la MLS',
    bestValueHomes: 'Casas de mejor valor',
    newDppBestValueHomes: 'Casas de mejor valor',
    recentlyViewed: 'visto recientemente',
    newIn: 'Nueva en $zip',
    priceReducedIn: 'Precio reducido en $zip',
    newDppPriceReducedIn: 'Precio reducido en $zip',
    recentlySoldIn: 'Recently Sold in $zip',
    openIn: 'Casas abiertas en $zip',
    calculateWithLender: 'Calcule su pago mensual con un prestamista',
    creditMonitoring: 'Vea su último puntaje de crédito ahora',
    creditMonitoring1: 'Vea su último puntaje de crédito ahora',
    faqProperty: 'Preguntas para esta propiedad',
    movotoCalculated: 'Calculada por movoto',
    movotoPowered: 'Energizado por Movoto',
    propertyNav: {
        back: 'atrás',
        search: 'Buscar',
        next: 'Próximo',
    },
    propertyButton: {
        photos: 'Fotos',
        photo: 'Foto',
        map: 'Mapa',
        save: 'Ahorrar',
        saved: 'Salvado',
        share: 'Cuota',
        call: 'Llama',
        edit: 'editar',
    },
    mvtEmail: {
        contactAgent: 'Contactar Agente',
        recipientsEmail: 'Correo electrónico de los destinatarios',
        shareHome: 'Comparte Esta Casa',
        checkoutHome: 'Hola, Echa un vistazo a esta casa que encontré en Movoto.',
        friendsEmail: 'Email de amigos',
        name: 'Tu nombre',
        email: 'Tu Email',
        sendmail: 'Enviar',
        sendCopy: 'Enviame una copia',
        thanksForSharing: 'GRACIAS POR COMPARTIR',
        seeThisHome: 'Ve a ver esta casa',
        thankYou: '¡GRACIAS!',
        agentContact: 'Uno de nuestros asociados de relaciones con agentes se comunicará con usted a la brevedad.',
        saveHome: 'Guardar casa',
        likeThisHome: '¿Te gusta esta casa? Reciba un correo electrónico cuando se realicen actualizaciones a la lista.',
        disclaimer:
            'Una vez que haya ingresado su dirección de correo electrónico, comenzaremos a enviarle informes de valoración mensuales desde nuestra herramienta personal de financiamiento de viviendas.',
        movotoTerms1:
            "Al enviar su información, acepta que Movoto puede compartir su información con su red de profesionales inmobiliarios e hipotecarios (<a class='text-dotted' target='blank' href='https://www.movoto.com/about/terms-of-use/#affiliates'>afiliados</a>) (o a través de sus agentes) y dar su consentimiento para recibir correos electrónicos de marketing relacionados con su consulta de Movoto o afiliados a la dirección de correo electrónico que proporcionó. Además, acepta que dichos afiliados pueden compartir información con Movoto y entre ellos. También acepta nuestros <a class='text-dotted' target='blank' href='https://www.movoto.com/about/terms-of-use/'>Términos y condiciones</a> y <a class='text-dotted' target='blank' href='https://www.movoto.com/about/privacy-policy/'>Política de privacidad</a>. No se requiere consentimiento para la compra.",
        movotoTerms2: 'Términos de uso y política de privacidad',
        sharemyprofile: 'Share My Profile',
        signupTerms:
            "Al unirte, aceptas los <a class='link' target='blank' href='https://www.movoto.com/about/terms-of-use/'>Términos de uso</a> y la <a class='link' target='blank' href='https://www.movoto.com/about/privacy-policy/'>Política de privacidad</a> de Movoto.com.",
    },
    dppTitle: {
        drop: 'Soltar',
        increase: 'Incrementar',
        bd: 'cama',
        ba: 'baño',
        mortgage: 'Hipoteca',
        apply: 'Solicitar',
        refinance: 'Refinanciar',
        rent: 'En Renta',
        sale: 'En Venta',
        coming: 'Próximamente, en breve, pronto',
        new: 'Nuevo',
        contract: 'Activo Bajo Contrato',
        pending: 'Pendiente',
        sold: 'Vendido',
        offMarket: 'Fuera Del Mercado',
        delisted: 'Eliminado',
        publicRecord: 'Registro Público - No Para Venta/Alquiler',
        bed: 'cama',
        bath: 'baño',
    },
    propertyTag: {
        virtualTour: 'Tour virtual',
        lakefront: 'Frente al lago',
        waterfront: 'Frente al mar',
        '3dtour': 'Tour en 3D',
        videoTour: 'Tour en video',
    },
    propertyDetails: {
        propertyDetailsHeader: 'Detalles de la propiedad',
        landValueWithUnit: 'Precio del terreno ($/Acre)',
        homeValueWithUnit: 'valor de la casa ($/Sqft)',
        landValue: 'Precio del terreno',
        homeValue: 'valor de la casa',
        landHomeEstTip: 'Para mejorar esta estimación, elimine las viviendas que no sean similares o cambie a viviendas vendidas en la sección Valor de la vivienda',
        hoaFees: 'Tarifas de HOA',
        hoa: 'HOA',
        propertyType: 'tipo de propiedad',
        propertyStatus: 'Estado de la propiedad',
        daysOnMarket: 'Días en el mercado',
        listedToday: 'Listado hoy',
        timeOnMovoto: 'Tiempo en Movoto',
        lotSize: 'Tamaño del lote',
        lot: 'Lote',
        yearBuilt: 'Construida',
        garageSpaces: 'Espacios de garaje',
        garageSpace: 'plaza de garaje',
        cooling: 'Enfriamiento/AC',
        heating: 'Calefacción',
        levels: 'Niveles/Estilo',
        neighborhood: 'Vecindario',
        county: 'Condado',
        transitStation: 'Estación de tránsito',
        distanceToCoast: 'Distancia a la costa',
        distanceToLake: 'Distancia al lago',
        greatSchool: 'GreatSchools Clasificación',
        rating: 'Clasificación',
        ratingTip: 'Este puntaje se basa en la calificación promedio de la escuela asignada a este hogar.',
        propertyOrientation: 'Orientación de la propiedad',
        mortgagePayment: 'Pago hipotecario',
        getPreapproved: 'Obtenga una aprobación previa',
        getPrequalified: 'Obtenga la precalificación',
        getBetterMortgage: 'Obtenga una aprobación previa',
        refinanceNow: 'Refinanciar ahora',
        meetAgent: 'Conoce a un Realtor para encontrar casas',
    },
    propertyFeature: {
        extLink: 'Enlace externo',
        lessPropertyDetails: 'Mostrar menos detalles de la propiedad',
        allPropertyDetails: 'Mostrar todos los detalles de la propiedad',
        seeLess: 'Ver menos',
        ctaTitle: 'Interesado en más detalles?',
        ctaSub: 'Nuestros agentes experimentados pueden proporcionar aún más detalles sobre la propiedad, incluidas las características clave, las renovaciones recientes, la información de ubicación y el historial de ventas anteriores.',
    },
    propertyOpenHouse: {
        openHouse: 'Casa abierta',
        scheduleTour: 'Solicite un recorrido en persona o virtualmente',
        scheduleTourBuyerAgent: 'Solicitar tour con un agente de la red OJO',
        requestTour: 'Solicite un recorrido',
    },
    propertySchedule: {
        tourThisPropertyWithOJOAgent: 'Recorre esta casa con $firstName',
        tourSimilarNearBy: 'Visita casas similares cercanas',
        tourThisProperty: 'Ir a recorrer esta propiedad',
        scheduleConsultation: 'Programar consulta',
        tourThisHome: 'Recorre esta casa con una agente destacada',
        inPerson: 'En persona',
        liveVideo: 'Video en vivo',
        morning: 'Mañana',
        afternoon: 'Tarde',
        evening: 'Noche',
        requestTour: 'Solicitar Una Visita',
        connectLocalAgent: 'Conéctese con un agente local que puede brindarle un recorrido personalizado. Cancela en cualquier momento.',
        notMls: 'No proporcionado por la MLS',
        seeThisHome: '¿Cuando te gustaría ver esta casa?',
    },
    propertyMortgage: {
        paymentCalculator: 'Calculadora de pagos',
        prequalifyMortgage: 'Precalifique',
        prequalifyHere: 'Pre-califica aquí',
        refinance: 'Refinanciar',
        findLender: 'Encuentre un prestamista',
        principalInterest: 'Principal e interés',
        propertyTaxes: 'Impuestos de propiedad',
        monthlyHoa: 'Cuota mensual de HOA',
        mortgageInsurance: 'Seguro hipotecario',
        insurance: 'Seguro',
        downPaymentTitle: 'Pago inicial y precio de la vivienda',
        downPayment: 'Pago inicial',
        downPaymentCash: 'Pago inicial de efectivo',
        downPaymentEquity: 'Pago inicial de la plusvalía de la vivienda',
        percent: 'Por ciento',
        homePrice: 'Precio de la vivienda',
        interestRateTitle: 'Tasa de interés, tipo de préstamo y crédito',
        interestRate: 'Tasa de interés',
        checkCreditNow: 'Compradores de vivienda: verifique su crédito ahora',
        checkCredit: 'Verifique su crédito antes de comprar',
        taxesTitle: 'Impuestos, seguros y HOA',
        taxEstimate: 'Estimación anual del impuesto sobre la propiedad',
        homeInsurance: 'Seguro de vivienda anual',
        mortgageRates: 'Las tasas hipotecarias',
        additionalSerices: 'Servicios adicionales',
        rentalServices: 'Servicios de Alquiler',
        poweredBy: 'Energizado por',
        advertiserDisclosure: 'Divulgación del anunciante',
        advertiserDisclosureTip:
            'Los listados que aparecen en esta página son de compañías de las cuales este sitio web y Bankrate reciben compensación, lo que puede afectar cómo, dónde y en qué orden aparecen los productos. Esta tabla no incluye todas las empresas ni todos los productos disponibles. Bankrate no respalda ni recomienda ninguna empresa.',
        details: 'Detalles',
        detailsTip:
            'Las ofertas de hipotecas a 30 años se basan en un pago inicial del 20 % y 360 pagos mensuales iguales. La mensualidad no incluye impuestos ni seguro. El pago mensual real será más alto.',
        privacyPolicy: 'Política de privacidad',
        noMatch: 'Ninguna tarifa coincide con sus criterios',
        connectLocalLender: 'Conéctese con un prestamista local.',
        findLowRate: 'Encuentre una tarifa baja',
        lowerMortgagePayment: 'Reduzca el pago de su hipoteca',
        cableDiscount: 'Descuentos por cable / Internet / teléfono',
        veteransEligibility: 'Veteranas: Obtenga una aprobación previa para un préstamo VA inicial de $ 0',
        veteransCanAfford: 'Veteranas: Obtener aprobación previa es el paso # 1',
        brownBagMarketingData: 'Los datos proporcionados por Brown Bag Marketing, Inc. Los pagos no incluyen montos por impuestos y primas de seguros.',
        click: 'haga clic',
        here: 'aquí',
        forMoreInfo: 'para obtener más información sobre tarifas y detalles de productos.',
        compareTodaysMortgageRates: 'Compare las tasas hipotecarias de hoy',
        ctaTitle: 'Reduzca su pago',
        ctaSub: 'Nuestros agentes experimentados pueden guiarlo en la conexión con los prestamistas, la comprensión de los incentivos locales y lo que comprende sus impuestos a la propiedad y/o tarifas de HOA.',
        homeownershipcostcalc: 'calculadora de costos de propiedad',
    },
    desc: {
        description: 'Description',
        activeDppDescription: 'Acerca de $streetAddress',
        listedBy: 'Listado por',
    },
    propertyEstimate: {
        landValueComps: 'Precio del terreno & comps',
        homeValueComps: 'Valor de la vivienda & comps',
        above: 'encima',
        below: 'debajo',
        soldPrice: 'precio de venta',
        listPrice: 'Precio de lista',
        estHomeSubText1: 'Esta estimación del valor de la vivienda es para una vivienda construida en este terreno y se basa en las siguientes viviendas cercanas cuyo promedio',
        estHomeSubText2: 'en valor y',
        estHomeSubText3: 'en tamaño.',
        estSubText1: 'basado en las siguientes casas que promedian',
        estSubText2: 'Este cálculo utiliza el área de construcción de registros públicos de',
        estPriceTip: 'Para mejorar esta estimación, elimine las casas que no sean similares o cambie a casas vendidas en la tabla a continuación.',
        land: 'Tierra',
        home: 'Hogar',
        sale: 'En venta',
        sold: 'vendido recientemente',
        viewed: 'Mi última vista',
        bed: 'Cama',
        bath: 'Baño',
        houseSize: 'Tamaño de la casa',
        hoaFee: 'Tarifa de HOA',
        lotSize: 'Tamaño del lote',
        yearBuilt: 'Construida',
        distance: 'Distancia',
        status: 'Estado',
        address: 'Dirección',
        estNote: 'La estimación de Precio/$unit de Movoto no es una tasación profesional, es un punto de partida para ayudarle a fijar el precio de esta casa.',
        ctaTitle: '¿Necesita una valoración precisa?',
        ctaSub: 'Nuestros agentes experimentados pueden proporcionar un análisis de mercado comparativo completo para valorar con precisión esta casa.',
    },
    saleProceeds: {
        saleProceedsCalc: 'Sale Proceeds Calculator',
    },
    propertyHistory: {
        history: 'Historia de la propiedad',
        activeDppHistory: 'Historial de la propiedad para $streetAddress',
        date: 'Fecha',
        status: 'Estado y fuente',
        price: 'Precio',
        change: 'Cambio',
        source: 'Fuente',
        ctaTitle: '¿Preguntas sobre el historial de la propiedad?',
        ctaSub: 'Nuestros agentes experimentados pueden brindarle más historial de la propiedad, incluido el historial de ventas, registros de impuestos, renovaciones recientes e historial de permisos.',
    },
    propertyClimate: {
        climateRisk: 'Riesgo promedio de control climático',
        climateChange: '¿Cómo te afectará el cambio climático?',
        climateCheckDesc: 'ClimateCheck™ analiza el riesgo de una propiedad utilizando los últimos modelos y datos de científicos climáticos, universidades y agencias federales.',
        showMore: 'Mostrar más',
        learnMore: 'Aprende más',
        ctaTitle: 'Aprenda sobre el clima',
        ctaSub: 'Nuestros agentes experimentados pueden brindarle información más detallada sobre tormentas, inundaciones, incendios y otros riesgos relacionados con el clima en esta área.',
    },
    propertySchool: {
        assignedSchool: 'Escuelas asignadas',
        schoolDesc: '$schoolLength Escuelas con calificaciones entre $schoolRating',
        schoolsInCity: 'Todas las escuelas en $city, $state',
        greatSchoolsData: 'Datos proporcionados por GreatSchools.org',
        greatSchoolTip:
            'Información escolar proporcionada por GreatSchools.org. Los límites de asistencia son proporcionados por Maponics y están sujetos a cambios sin previo aviso. Verifique con el distrito escolar correspondiente para confirmar la elegibilidad legal y las reglas de asistencia.',
        homesInSchoolZone: 'Mostrar hogares en la zona de esta escuela',
        removeSchoolZone: 'Eliminar la zona de esta escuela',
        rating: 'Clasificación',
        name: 'Nombre',
        type: 'Escribe',
        grade: 'Calificación',
        reviews: 'Reseñas',
        distance: 'Distancia (mi)',
        ctaTitle: 'Preguntas sobre las escuelas?',
        ctaSub: 'Nuestros agentes experimentados pueden brindarle más información sobre escuelas, requisitos de inscripción y programas educativos en esta área.',
    },
    propertyMarket: {
        statistics: 'Estadísticas de mercado',
        listPrice: 'Precio de lista en',
        year: 'Año',
        years: 'Años',
        price: 'Precio',
        inventory: 'Inventario',
        days: 'Dias',
        noChart: 'No hay gráfico disponible',
        comparisonProperties: 'Comparación con las 30 propiedades más cercanas',
        statisticsByMovoto: 'Estadísticas calculadas por Movoto',
        medianPrice: 'Precio de lista medio en',
        yoy: 'Año tras año',
        ctaTitle: 'Tendencias del mercado en tiempo real',
        ctaSub: 'Nuestros agentes experimentados pueden brindarle información sobre el mercado, incluidas viviendas recientemente bajo contrato, ventas en el área, tendencias de precios y otras dinámicas del mercado.',
    },
    propertyLocalScore: {
        neighborhoodScore: 'Puntajes de vecindario',
        foodDesc: 'El puntaje de calificación general se divide en 4 categorías: precio, calidad, cantidad y diversidad. Todos juntos suman la puntuación máxima de 100.',
        kidDesc: 'La puntuación de calificación general se divide en 2 categorías: parques y puntos de interés. Todos juntos suman la puntuación máxima de 100.',
        dogDesc: 'La puntuación de calificación general se divide en 3 categorías: senderos, parques y puntos de interés. Todos juntos suman la puntuación máxima de 100.',
        walkrateLowDesc: 'Dependiente del coche - Casi todos los lugares y actividades requieren un coche.',
        walkrateAvgDesc: 'Dependiente del coche - La mayoría de los lugares y actividades requieren un coche.',
        walkrateGoodDesc: 'Definitivamente caminable - La mayoría de los lugares y actividades son caminables.',
        walkrateBestDesc: 'Sueño de Caminante - Casi todos los lugares y actividades son caminables.',
        ctaTitle: 'Aprenda sobre el vecindario',
        ctaSub: 'Nuestros agentes experimentados pueden ayudarlo a obtener más información sobre el vecindario, las atracciones, los parques, las escuelas y más.',
    },
    propertyLocalhighlight: {
        insights: 'Perspectivas de la propiedad',
        seeMore: 'Ver más',
        ctaTitle: 'Más información sobre propiedades',
        ctaSub: 'Nuestros agentes experimentados pueden brindarle incluso información sobre la propiedad y el área, como información sobre el clima, servicios cercanos, transporte y más.',
    },
    propertyCommute: {
        commute: 'Distancia y tiempo de viaje',
        addLocation: 'Agregar ubicación de viaje cotidiano',
        commuteAddress: 'Ingrese la dirección de viaje cotidiano',
        city: 'Ciudad',
        distance: 'Distancia (mi)',
        commuteByCar: 'Viaje diario en coche (min)',
        allCommuteTimes: 'Todos los tiempos de viaje diario',
        commuteDesc: '$distance Mile, $carCommute Minute Commute to $cityName',
        myCommuteTime: 'Mi viaje',
        enterDistance: 'Ingrese un destino para ver el tiempo y la distancia del viaje.',
    },
    hotleadForm: {
        genericOjoAgentQuestion: '',
        askOjoAgent: 'Haga $firstName una pregunta',
        askQuestion: 'Háganos una pregunta',
        listingAgent: 'Conéctese con un agente de listados local',
        requestInfo: 'Información requerida',
        askBuilder: 'Hazle una pregunta al constructor',
        questionAbout: 'Me gustaría hacer una pregunta sobre',
        scheduleViewing: 'Solicitar una visita',
        scheduleViewingAgent: 'Programe la visualización con un agente',
        tourNearby: 'Ir a recorrer propiedades cercanas',
        send: 'Enviar',
        go: 'Ir',
        submit: 'Entregar',
        tourPlace: 'Recorre este lugar',
        requestTour: 'Solicitar Una Visita',
        interestIn: 'Estoy interesado en programar una visita de',
        interestInSimilar: 'Estoy interesado en programar una visita de casas similares a',
        seeHomes: 'Ir a ver casas similares',
        tourHome: 'Recorre una casa similar',
        scheduleVirtual: 'Programe una visita virtual',
        virtualTour: 'Estoy interesado en programar un recorrido virtual por',
        virtualTourSimilar: 'Estoy interesado en programar una visita virtual a casas similares para',
        attendOpenHouse: 'Asiste a la jornada de puertas abiertas',
        interestedInOpen: 'Estoy interesado en asistir a la jornada de puertas abiertas en',
        interestedIn: 'Estoy interesado en',
        contactNow: 'Contacta ahora',
        connectToManager: 'Comuníqueme con el administrador de la propiedad para discutir',
        connectAgent: 'Conéctame a un agente para discutir',
        msgBuyerAgent: 'Envía un mensaje al agente del comprador',
        connectBuyerAgent: 'Conéctese con un agente del comprador',
        msgOjoAgent: 'Envía un mensaje a un agente',
        veterancheckbox: ' Yo o mi esposo sirvió en el ejército',
        connectMovotoHomeLoans: "Conecta con un experto de <span style='font-weight: bold; color: #0E6959'>Movoto Home Loans</span> para explorar oportunidades de financiamiento.",
        connectMovotoHomeLoans_on1: 'Quiero información de aprobación previa de Movoto Home Loans',
        connectMovotoHomeLoans_on2: 'Estoy interesado en la pre-aprobación',
    },
    propertyNearby: {
        nearby: 'Casas cercanas',
        newDppNearby: 'Casas cercanas',
        nearbyRent: 'Alquileres cercanas',
        distance: 'Distancia',
        similar: 'Semejante',
        listDate: 'Fecha de lista',
        priceHigh: 'Precio alto',
        priceLow: 'Precio bajo',
        sqftBig: 'Sqft Grande',
        sqftSmall: 'Sqft Pequeño',
        viewOnMap: 'Ver en el mapa',
        soldBy: 'Vendido por Movoto',
        soldByOn: 'Vendido por Movoto el',
        listedBy: 'Listado por Movoto',
    },
    faq: {
        faqHeader: 'FAQ',
    },
    propertyPR: {
        pr: 'Registro Público',
        dataProvided: 'Datos proporcionados por el registro del condado',
    },
    seo: {
        homesForSale: 'Casas en venta',
        homesForRent: 'Casas en alquiler',
        realEstateAndHomes: 'Bienes raíces y casas en venta',
        realEstateAndHomesRent: 'Bienes raíces y casas en alquiler',
        view: 'Ver',
        homesForSaleIn: 'casas en venta en',
        homesForSaleRentIn: 'casas en alquiler en',
        filteredBy: 'filtrado por',
        openHouse: 'Casas abiertas',
        singleFamily: 'Chalet',
        condo: 'Casa/Apartamento',
        new: 'Nuevos Listados',
        priceReduce: 'Precio reciente reducido',
        singleFamilyTitle: '$city Propiedades unifamiliares y casas en venta',
        condoTitle: '$city Condominio Bienes raíces y casas en venta',
        openHouseTitle: '$city Casas abiertas Inmobiliarias y casas en venta',
        newTitle: '$city Nuevas propiedades inmobiliarias y viviendas en venta',
        priceReduceTitle: '$city Precio reducido de bienes raíces y casas en venta',
    },
    propertyAgents: {
        mvtAgents: 'Agentes de bolsa Movoto en',
        noResults: 'No hay resultados',
        askQuestion: 'Hacer Una Pregunta',
    },
    propertyMap: {
        location: 'Localización',
        streetView: 'vista de calle',
        satellite: 'Satélite',
    },
    propertyPoi: {
        commute: 'Viajar diariamente',
        addCommute: 'Agregar viaje diario',
        poi: 'Puntos de interés',
        poiCard: 'Encuentra lugares especiales cercanos',
        nearbyCafe: 'Abarrotes, cafeterías y restaurantes cerca de $address',
        showMore: 'Mostrar más',
        viewMap: 'Ver el mapa',
        dataProvided: 'Datos proporcionados por Precisely',
        ctaSub: 'Nuestros agentes experimentados pueden brindarle más información sobre el vecindario y las atracciones cercanas.',
    },
    mvtNearby: {
        noResults: 'No hay resultados',
    },
    sellbanner: {
        meetWithAgent: 'Reúnase con un agente local superior',
        getConnected: 'Conectarse',
        connectNow: 'Conecte ahora',
        isYourHome: '¿Esta es tu casa?',
        homeValue: 'Descubra el valor de su vivienda',
        valuationDesc: 'Reciba una tasación precisa y gratuita de su casa, para que pueda tomar las decisiones financieras correctas.',
        getValuation: 'Obtener valoración',
        manageInvestment: 'Gestione su inversión',
        trackHomeValueDesc: 'Realice un seguimiento del valor de su vivienda, encuentre formas de ahorrar y acumule riqueza a largo plazo hasta que esté listo para hacer una mudanza.',
        digsByOjo: 'Pruebe la dueña de casa Movoto',
        selling: '¿Vender antes de comprar?',
        findHomesWorth: 'Descubra el valor de su casa y maximice su venta con un experto local.',
        getAValuation: 'Obtenga una valoración',
        rightTimeSell: 'Identifique el momento adecuado para vender',
        getStarted: 'Empiece con Digs',
    },
    propertyArticle: {
        getToKnow: 'Llegar a saber',
        articleDesc: '$number Artículos sobre áreas cercanas',
        showMore: 'Mostrar más',
    },
    hotBanner: {
        hotDesc: 'Esta casa es <b>CALIENTE</b>. Hay un 80% de probabilidad de que esta casa se contrate en 15 días.',
        goTomorrow: 'Ve mañana',
        goToday: 'Ir hoy',
    },
    hotButton: {
        manageAssignedAgent: 'Envíe un mensaje a su agente',
        messageAssignedAgent: 'Enviar mensaje',
        sendReq: 'Enviar petición',
        chkAvlbility: 'Consultar disponibilidad',
        msgAgent: 'Agente de mensajes',
        askAgent: 'Preguntar a una agente',
        message: 'Mensaje',
        fakeChatBanner1: '¡Un agente inmobiliario con experiencia quiere hablar con usted sobre esta propiedad ahora mismo!',
        fakeChatBanner2: '¿Quiere hablar con un experto inmobiliario local en $city?',
        maybeLater: 'Quizas mas tarde',
        requestTour: 'Solicitar Visita',
        requestaTour: 'Solicitar Una Visita',
        requestInfo: 'Solicitar Información',
        contactAgent: 'Contactar Agente',
        owner: 'Soy Dueño',
        ownerDashboard: 'Panel de control del propietario',
        requestThisTime: 'Elegir Esta Hora',
        confirm: 'Confirmar',
        confirmInfo: 'Confirma tu información',
        sell: 'Vender',
        buy: 'Comprar',
        both: 'Ambos',
        other: 'Otro',
        claimHome: 'Reclamar Inicio',
        iAmOwner: 'Soy el Dueño',
        whoIsOwner: 'Quien es el dueño',
        requestHomeDetails: 'Request Home Details',
        subcopyaskagent: 'Lo conectaremos con un agente de bienes raíces local que podrá responder su pregunta sobre esta o cualquier otra propiedad.',
    },
    propertyChat: {
        sendMessage: 'Enviar un mensaje',
        interested: '¡Hola! Veo que te interesa',
        assistYou: 'Cómo puedo ayudarle?',
        contactManager: 'Comuníquese con el administrador de la propiedad',
        contactAgent: 'Contactar Agente',
        videoChat: 'Video Chat',
        scheduleTour: 'Programar un Tour',
        sellHome: 'Vender mi casa',
        buyHome: 'Compre una casa similar',
        askUs: 'O pregúntanos cualquier cosa ...',
        provideContactInfo: 'Proporcione su información de contacto para que sepa cómo comunicarme con usted.',
        InterestedInCPM: 'Estoy interesado en comunicarme con el administrador de la propiedad para',
        InterestedInVT: 'Estoy interesado en un video tour de',
        InterestedInMoreInfo: 'Estoy interesado en más información sobre',
        InterestedInST: 'Estoy interesado en programar un recorrido por',
        getInTouch: 'Ponerse en contacto',
    },
    dppVeterans: {
        veteransTitle: 'Beneficios para veteranos y militares',
        veteransSub: 'Los veteranos aprovechan sus beneficios únicos',
        veteransTitleNew: 'Beneficios para veteranos y militares',
    },
};
