import { importDatadogRUM } from '../../app';

const isSSR = typeof window === 'undefined';
let cacheSetting;

export default (getRouter, model = null) => {
    const context = isSSR && model ? model.context : window.__INITIAL_STATE__;
    if (!isSSR) {
        const cacheConfig = [
            {
                path: '/search',
                host: 'webcache.googleusercontent.com',
                getUrl: (fullPath) => {
                    //conver fullpath /search?q=cache:https://www.movoto.com${realPath}
                    //to ${realPath}
                    if (fullPath) {
                        let realPath = fullPath.split(context.appUrl)[1];
                        if (realPath) {
                            return `/${realPath}`;
                        }
                    }
                    return '';
                },
            },
        ];
        cacheSetting = cacheConfig.find((config) => {
            return config.host === window.location.hostname;
        });
        if (cacheSetting) {
            cacheSetting.originUrl = window.location.href;
        }
    }
    const routes = [];
    if (context.errorPage) {
        routes.push({
            path: '/:extraPath(.*)',
            name: 'verror',
            component: () => import(/* webpackChunkName: "verror" */ '@/error/components/pageerror/page-error.vue'),
        });
        return getRouter(routes, cacheSetting ? cacheSetting.path : undefined);
    }

    routes.push({
        path: '/api',
        name: 'vapi',
        component: () => import(/* webpackChunkName: "vapi" */ '@/api/page-api.vue'),
    });

    routes.push({
        path: '/agent/profile/:id',
        name: 'vagentprofile',
        props: context.pageData
            ? {
                  pageType: 'vagentprofile',
                  cpsId: context.pageData.cpsId,
                  token: context.pageData.token,
                  agentProfile: context.pageData.agentProfile,
                  matchingAgentProfiles: context.pageData.matchingAgentProfiles,
                  assignedAgent: context.pageData.assignedAgent,
                  agentTransactions: context.pageData.agentTransactions,
              }
            : {},
        component: () => import(/* webpackChunkName: "vagentprofile" */ '@/agent/components/profile/index.vue'),
    });

    routes.push({
        path: '/neighborhoods/:area/:name',
        name: 'vneighborhood',
        props: context.pageData,
        component: () => import(/* webpackChunkName: "vneighborhood" */ '@/neighborhood/components/index.vue'),
    });

    routes.push({
        path: '/agent-touring-agreement/:agentId/:customerId',
        name: 'vagenttouringagreement',
        props: context.pageData,
        component: () => import(/* webpackChunkName: "vagenttouringagreement" */ '@/agent/components/touring-agreement/index.vue'),
    });

    routes.push({
        path: '/agent-touring-agreement-status/:agentId/:customerId',
        name: 'vagenttouringagreementstatus',
        props: {
            ...context.pageData,
            readOnly: true,
        },
        component: () => import(/* webpackChunkName: "vagenttouringagreementstatus" */ '@/agent/components/touring-agreement/index.vue'),
    });

    routes.push({
        path: '/core',
        name: 'vcore',
        component: () => import(/* webpackChunkName: "vcore" */ '@/api/page-core.vue'),
    });

    routes.push({
        path: '/icon',
        name: 'vicon',
        component: () => import(/* webpackChunkName: "vicon" */ '@/icon/page-icon.vue'),
    });

    routes.push({
        path: '/guide/:extraPath(.*)',
        name: 'vguide',
        component: () => import(/* webpackChunkName: "vguide" */ '@/guide/components/pageguide/page-guide.vue'),
    });

    // /sitemap/fl/
    // /sitemap/miami-beach-fl/neighborhood/
    routes.push({
        path: '/:es(.*)*/:path(sitemap|property-search)/:extraPath(.*)',
        name: 'vsitemap',
        component: () => import(/* webpackChunkName: "vsitemap" */ '@/sitemap/components/pagesitemap/page-sitemap.vue'),
    });

    if (context.pageType.indexOf('vschools') > -1) {
        // /school, /schools
        routes.push({
            path: '/school:optionalS(s*)',
            name: 'vschools',
            component: () => import(/* webpackChunkName: "vschools" */ '@/schools/components/pageschools/page-schools.vue'),
        });
        // /tx/schools
        routes.push({
            path: '/:state(.*)/school:optionalS(s*)',
            name: 'vschools-state',
            component: () => import(/* webpackChunkName: "vschools" */ '@/schools/components/pageschools/page-schools.vue'),
        });
        // /schools/austin-tx
        routes.push({
            path: '/school:optionalS(s*)/:city(.*)',
            name: 'vschools-city',
            component: () => import(/* webpackChunkName: "vschools" */ '@/schools/components/pageschools/page-schools.vue'),
        });
        routes.push({
            path: '/schools-district/:area(.*)/:districtName(.*)',
            name: 'vschools',
            component: () => import(/* webpackChunkName: "vschools" */ '@/schools/components/pageschools/page-schools.vue'),
        });
    } else {
        // /schools/abilene-tx/abilene-high-school-480744000010/
        routes.push({
            path: '/schools/:area(.*)/:schoolName(.*)',
            name: 'vdsp',
            component: () => import(/* webpackChunkName: "vdsp" */ '@/dsp/components/pagedsp/page-dsp.vue'),
        });
    }

    routes.push({
        path: '/:name(feed|notification)+/',
        name: 'vfeed',
        component: () => import(/* webpackChunkName: "vfeed" */ '@/feed/components/pagefeed/page-feed.vue'),
    });

    routes.push({
        path: '/what-is-your-home-worth',
        name: 'vhomeWorth',
        component: () => import(/* webpackChunkName: "vhomeWorth" */ '@/valuation/components/pagevaluation/page-valuation.vue'),
    });

    routes.push({
        path: '/home-sale-proceeds-calculator',
        name: 'vhomesalecalc',
        component: () => import(/* webpackChunkName: "vhomesalecalc" */ '@/sell/components/pagehomesalecalc/page-homesalecalc.vue'),
    });

    routes.push({
        path: '/sell-with-an-agent',
        name: 'vsellwithanAgent',
        component: () => import(/* webpackChunkName: "vsellwithanAgent" */ '@/sell/components/pageagentsell/page-agentsell.vue'),
    });

    routes.push({
        path: '/sell/:name(.*)',
        name: 'vsell',
        component: () => import(/* webpackChunkName: "vsell" */ '@/sell/components/pagesell/page-sell.vue'),
    });

    routes.push({
        path: '/sell-fast',
        name: 'vsellfast',
        component: () => import(/* webpackChunkName: "vsellfast" */ '@/sell/components/pagesellfast/page-sellfast.vue'),
    });

    routes.push({
        path: '/consent/success/',
        name: 'vconsent',
        component: () => import(/* webpackChunkName: "vconsent" */ '@/consent/pageconsent/page-consent.vue'),
    });
    routes.push({
        path: '/survey/:extraPath(.*)',
        name: 'vsurvey',
        component: () => import(/* webpackChunkName: "vsurvey" */ '@/survey/pagesurvey/page-survey.vue'),
    });

    routes.push({
        path: '/mortgages/onboarding/',
        name: 'vsurveymortgage',
        component: () => import(/* webpackChunkName: "vsurveymortgage" */ '@/survey/pagesurveymortgage/page-surveymortgage.vue'),
    });

    routes.push({
        path: '/agents/meet-an-agent/',
        name: 'vagentmeetagents',
        component: () => import(/* webpackChunkName: "vagentmeetagents" */ '@/agent/components/pageagentmeetagents/page-agentmeetagents.vue'),
    });
    routes.push({
        path: '/agents',
        name: 'vagenthome',
        component: () => import(/* webpackChunkName: "vagenthome" */ '@/agent/components/pageagenthome/page-agenthome.vue'),
    });
    if (context.pageType.indexOf('vagentsearch') > -1) {
        routes.push({
            path: '/agents/:area',
            name: 'vagentsearch',
            component: () => import(/* webpackChunkName: "vagentsearch" */ '@/agent/components/pageagentsearch/page-agentsearch.vue'),
        });
    } else {
        routes.push({
            path: '/agents/:area',
            name: 'vdap',
            component: () => import(/* webpackChunkName: "vdap" */ '@/agent/components/pagedap/page-dap.vue'),
        });
    }

    routes.push({
        path: '/brokerage/:name(.*)',
        name: 'vbrokerage',
        component: () => import(/* webpackChunkName: "vbrokerage" */ '@/agent/components/pagebrokerage/page-brokerage.vue'),
    });

    // /about/terms-of-use
    // /es/about/terms-of-use
    routes.push({
        path: '/:es(.*)?/about/terms-of-use',
        name: 'vtermsofuse',
        component: () => import(/* webpackChunkName: "vtermsofuse" */ '@/staticpages/components/termsofuse/termsofuse.vue'),
    });

    // /about/privacy-policy
    // /es/about/privacy-policy
    routes.push({
        path: '/:es(.*)?/about/privacy-policy',
        name: 'vprivacypolicy',
        component: () => import(/* webpackChunkName: "vprivacypolicy" */ '@/staticpages/components/privacy-policy/privacy-policy.vue'),
    });

    routes.push({
        path: '/about/mls-logo',
        name: 'vmlslogo',
        component: () => import(/* webpackChunkName: "vmlslogo" */ '@/staticpages/components/mlslogo/mlslogo.vue'),
    });

    // routes.push({
    //     path: '/about/bonus',
    //     name: 'vbonus',
    //     component: () => import(/* webpackChunkName: "vbonus" */ '@/staticpages/components/bonus/page-bonus.vue'),
    // });

    // routes.push({
    //     path: '/about/claim-bonus',
    //     name: 'vclaimbonus',
    //     component: () => import(/* webpackChunkName: "vclaimbonus" */ '@/staticpages/components/claimbonus/page-claimbonus.vue'),
    // });

    routes.push({
        path: '/about/ca_privacy',
        name: 'vcapolicyview',
        component: () => import(/* webpackChunkName: "vcapolicyview" */ '@/staticpages/components/caprivacy/ca-privacy.vue'),
    });

    routes.push({
        path: '/:es(.*)?/market-trends/',
        name: 'vmarket-trends',
        component: () => import(/* webpackChunkName: "vmarket-trends" */ '@/market-trends/components/page-market-trends/page-market-trends.vue'),
    });

    routes.push({
        path: '/my-movoto/:extraPath(.*):homeId(.*)?',
        name: 'vmymovoto',
        component: () => import(/* webpackChunkName: "vmymovoto" */ '@/mymovoto/components/pagemymovoto/page-mymovoto.vue'),
    });

    routes.push({
        path: '/unsubscribe/',
        name: 'vunsubscribesucceed',
        component: () => import(/* webpackChunkName: "vunsubscribesucceed" */ '@/unsubscribe/components/unsubscribesucceed/unsubscribesucceed.vue'),
    });

    routes.push({
        path: '/:extraPath(.*)/unsubscribe/',
        name: 'vunsubscribesucceed-drip',
        component: () => import(/* webpackChunkName: "vunsubscribesucceed" */ '@/unsubscribe/components/unsubscribesucceed/unsubscribesucceed.vue'),
    });

    routes.push({
        path: '/:es(.*)*/signup/',
        name: 'vhome',
        component: () => import(/* webpackChunkName: "vhome" */ '@/home/components/pagehome/page-home.vue'),
    });
    if (context.pageType === 'vdpp') {
        // OLD patterns as fail safe via split
        if (context.simple) {
            routes.push({
                path: '/:city(.*)/:address(.*)/',
                name: 'vdpp3',
                component: () => import(/* webpackChunkName: "vdpp3" */ '@/dpp/components/pagedpp/page-dpp-simple.vue'),
            });
        } else if (['on1', 'on2'].includes(context?.splits?.['movoto-dpp-redesign-CW-2515'])) {
            routes.push({
                path: '/:city(.*)/:address(.*)/',
                name: 'vdppnew',
                component: () => import(/* webpackChunkName: "vdppnew" */ '@/dpp/components/pagedpp/page-dpp-new.vue'),
            });
        } else {
            routes.push({
                path: '/:city(.*)/:address(.*)/',
                name: 'vdpp',
                component: () => import(/* webpackChunkName: "vdpp" */ '@/dpp/components/pagedpp/page-dpp.vue'),
            });
        }
    }

    routes.push({
        path: '/preapproval',
        name: 'vpreapproval',
        component: () => import(/* webpackChunkName: "vpreapproval" */ '@/staticpages/components/preapproval/page-preapproval.vue'),
    });

    routes.push({
        path: '/rent-to-own',
        name: 'vrenttoown',
        component: () => import(/* webpackChunkName: "vrenttoown" */ '@/staticpages/components/preapproval/page-renttoown.vue'),
    });

    routes.push({
        path: '/mortgages',
        name: 'vmortgages',
        component: () => import(/* webpackChunkName: "vmortgages" */ '@/staticpages/components/mortgages/page-mortgages.vue'),
    });

    routes.push({
        path: '/mortgages/buyingpower',
        name: 'vbuyingpower',
        component: () => import(/* webpackChunkName: "vbuyingpower" */ '@/staticpages/components/buyingpower/page-buyingpower.vue'),
    });

    routes.push({
        path: '/mortgages/calculator',
        name: 'vmortgagecalculator',
        component: () => import(/* webpackChunkName: "vmortgagecalculator" */ '@/staticpages/components/mortgagecalculator/page-mortgagecalculator.vue'),
    });

    routes.push({
        path: '/mortgages/rates',
        name: 'vmortgagerates',
        component: () => import(/* webpackChunkName: "vmortgagerates" */ '@/staticpages/components/mortgagerates/page-mortgagerates.vue'),
    });

    routes.push({
        path: '/mortgages/refinance',
        name: 'vrefinancerates',
        component: () => import(/* webpackChunkName: "vrefinancerates" */ '@/staticpages/components/refinancerates/page-refinancerates.vue'),
    });

    routes.push({
        path: '/mortgage/mrc',
        name: 'vmortgagemrc',
        component: () => import(/* webpackChunkName: "vmortgagemrc" */ '@/staticpages/components/mortgagemrc/page-mortgagemrc.vue'),
    })
    routes.push({
        path: '/creditrepair',
        name: 'vcreditrepair',
        component: () => import(/* webpackChunkName: "vcreditrepair" */ '@/staticpages/components/creditrepair/page-creditrepair.vue'),
    });

    routes.push({
        path: '/insurance',
        name: 'vinsurance',
        component: () => import(/* webpackChunkName: "vinsurance" */ '@/staticpages/components/insurance/page-insurance.vue'),
    });

    routes.push({
        path: '/homeservices',
        name: 'vhomeserviceslanding',
        component: () => import(/* webpackChunkName: "vhomeserviceslanding" */ '@/homeservices/landing/homeservices-landing.vue'),
    });

    routes.push({
        path: '/homeservices/search',
        name: 'vhomeservicessearch',
        component: () => import(/* webpackChunkName: "vhomeservicessearch" */ '@/homeservices/search/homeservices-search.vue'),
    });

    routes.push({
        path: '/mobile',
        name: 'vappdownload',
        component: () => import(/* webpackChunkName: "vappdownload" */ '@/staticpages/components/appdownload/page-mobile.vue'),
    });

    routes.push({
        path: '/ac',
        name: 'vabout',
        component: () => import(/* webpackChunkName: "vabout" */ '@/about/about/about.vue'),
    });

    routes.push({
        path: '/about',
        name: 'vaboutus',
        component: () => import(/* webpackChunkName: "vaboutus" */ '@/staticpages/components/aboutus/aboutus.vue'),
    });

    routes.push({
        path: '/faqs',
        name: 'vfaqs',
        component: () => import(/* webpackChunkName: "vfaqs" */ '@/about/faqs/faqs.vue'),
    });

    routes.push({
        path: '/do-not-sell-my-personal-information',
        name: 'vnotsellpersonalinfo',
        component: () => import(/* webpackChunkName: "vnotsellpersonalinfo" */ '@/staticpages/components/notsellpersonalinfo/personal-info.vue'),
    });

    routes.push({
        path: '/set_password/:extraPath(.*)',
        name: 'vresetpassword',
        component: () => import(/*webpackChunkName: "vresetpassword" */ '@/staticpages/components/user/resetpassword/page-resetpassword.vue'),
    });

    routes.push({
        path: '/activate_account/:extraPath(.*)',
        name: 'vactivateaccount',
        component: () => import(/*webpackChunkName: "vactivateaccount" */ '@/staticpages/components/user/activation/page-activation.vue'),
    });

    routes.push({
        path: '/widget/:path(hearst-marketing-insight|marketing-insight)/:extraPath(.*)',
        name: 'vwidgethearstmarketinsight',
        component: () => import(/* webpackChunkName: "vwidgethearstmarketinsight" */ '@/widgets/hearstmarketinsight/hearstmarketinsight.vue'),
    });

    routes.push({
        path: '/widget/home-finder/search/',
        name: 'vwidgethomefindersearch',
        component: () => import(/* webpackChunkName: "vwidgethomefindersearch" */ '@/widgets/homefinder/homefinder-search.vue'),
    });

    routes.push({
        path: '/widget/home-finder/:extraPath(.*)',
        name: 'vwidgethomefinder',
        component: () => import(/* webpackChunkName: "vwidgethomefinder" */ '@/widgets/homefinder/homefinder.vue'),
    });

    routes.push({
        path: '/widget/hearst-home-finder/:extraPath(.*)',
        name: 'vwidgethearsthomefinder',
        component: () => import(/* webpackChunkName: "vwidgethearsthomefinder" */ '@/widgets/hearsthomefinder/hearsthomefinder.vue'),
    });

    routes.push({
        path: '/:es(.*)?/homeowner/',
        name: 'vhomeowner',
        component: () => import(/* webpackChunkName: "vhomeowner" */ '@/home-owner/components/pagehomeowner/page-homeowner.vue'),
    });

    routes.push({
        path: '/:extraPath(es)?',
        name: 'vhome',
        component: () => import(/* webpackChunkName: "vhome" */ '@/home/components/pagehome/page-home.vue'),
    });

    // paths that match none of the above should match this and be driected to the MSP
    routes.push({
        path: '/:catchAll(.*)*',
        name: context.pageType === 'vgeolanding' ? 'vgeolanding' : 'vmapsearch',
        component: context.pageType === 'vgeolanding' ?
            () => import(/* webpackChunkName: "vgeolanding" */ '@/geolanding/components/pagegeolanding/page-geolanding.vue') :
            () => import(/* webpackChunkName: "vmapsearch" */ '@/msp/components/pagesearch/page-search.vue')
    });

    routes.push({
        path: '/veterans',
        name: 'vveterans',
        component: () => import(/* webpackChunkName: "vveterans" */ '@/veterans/components/veterans/page-veterans.vue'),
    });

    var router = getRouter(routes, cacheSetting ? cacheSetting.path : undefined);
    router.beforeEach((to, from, next) => {
        const matches = to.matched.map((match) => match.name);
        if (!isSSR) {
            if (context.splits && context.splits['movoto-enable-datadog-rum-CW-10249'] === 'on') {
                importDatadogRUM().then(({ datadogRum }) => {
                    datadogRum.startView({
                        name: matches.join('|'),
                        service: 'movoto-web',
                        version: context.version,
                    });
                });
            }
            $.addDebugBearTag('tag1', matches.join('|'));
            $.addDebugBearTag('tag2', context.user && context.user.id ? true : false);
        }

        if (to && from && to.path === '/' && from.path !== to.path) {
            !isSSR && window && window.sendNodeLog('VueRedirectHome', from.path);
        }

        let replaceUrl;
        if (!isSSR && cacheSetting && cacheSetting.getUrl) {
            replaceUrl = cacheSetting.getUrl(to.fullPath);
        }
        if (!isSSR && replaceUrl) {
            next({ path: replaceUrl, replace: true });
            if (cacheSetting.originUrl) {
                setTimeout(() => {
                    if (cacheSetting.originUrl !== window.location.href) {
                        window.history.replaceState(null, '', cacheSetting.originUrl);
                        cacheSetting.originUrl = null;
                    }
                }, 3000);
            }
        } else {
            next();
        }
    });
    return router;
};
