import $eventBus from '@/common/providers/eventbus';
import mapper from '@/common/store/mapper/index.js';
import { getFilterByPath, getPathByFilter } from '../../../widget/spec/filterhelper.js';
import { signup_trigger, getGa4Trigger, getItemByDpp } from '../../../widget/spec/ga4helper';
import { fetchCachedClientTreatments } from '@/common/components/split/utils.js';
import { formatSplitsForGA4 } from '../components/split/utils.js';
function yieldToMain() {
    if (window.scheduler && window.scheduler.yield) {
        return window.scheduler.yield();
    }

    // Fall back to yielding with setTimeout.
    return new Promise((resolve) => {
        setTimeout(resolve, 0);
    });
}
const isSSR = typeof window === 'undefined';

const timePeriod = 30 * 60 * 1000;

const blacklist = {
    ec: [],
};

function blockGa(key, value) {
    return blacklist[key].indexOf(value) > -1;
}
// const ga4EcommerceList = [
//     'add_payment_info',
//     'add_shipping_info',
//     'add_to_cart',
//     'add_to_wishlist',
//     'begin_checkout',
//     'purchase',
//     'refund',
//     'remove_from_cart',
//     'select_item',
//     'select_promotion',
//     'view_cart',
//     'view_item',
//     'view_item_list',
//     'view_promotion',
// ];

const _getSearchViewType = function (str) {
    const views = {
        'map-view': 'mapView',
        'grid-view': 'gridView',
        'slim-view': 'slimView',
    };
    return views[str || 'grid-view'];
};
const _getSortingText = function (str) {
    const sortings = {
        'sortby-dom-asc': 'List Date',
        'sortby-price-desc': 'Price High',
        'sortby-price-asc': 'Price Low',
        'sortby-sqft-desc': 'Sqft Big',
        'sortby-sqft-asc': 'Sqft Small',
        'sortby-pricepersqft-asc': '$/Sqft',
        'sortby-closest-asc': 'Relevant',
        'sortby-updated-desc': 'Updated',
    };
    const sort = str || 'sortby-closest-asc';
    return sortings[sort];
};

const _getFiltersText = function (filters) {
    return getPathByFilter(filters, false, ['|', ':']);
};

const getDefaultDatalayer = function (data) {
    const glb = data.glb;
    let gtmDatalayer = {};
    gtmDatalayer.channel = 'website';
    if (glb) {
        gtmDatalayer.experience = glb.isMobile ? 'vue-mobile' : 'vue-desktop';
        gtmDatalayer.ipaddress = glb.ip || '172.0.0.1';
        const user = glb.user;
        if (user) {
            gtmDatalayer.logged_in = user.id ? 'true' : 'false';
            if (user.id) {
                gtmDatalayer.user_id = user && user.id;
            }
        }

        const providerCachedData = fetchCachedClientTreatments();

        if (glb && glb.splitsUrlBased) {
            gtmDatalayer.url_split_treatments = formatSplitsForGA4(glb.splitsUrlBased);
        }
        gtmDatalayer.split_treatments = formatSplitsForGA4(glb.splits, providerCachedData);
        gtmDatalayer.landing_page_path = glb.landingPagePath;
        gtmDatalayer.landing_page_type = glb.landingPageType;
    }

    if (window) {
        gtmDatalayer.page_url = window.location.pathname + window.location.search;
        const stdLocale = [window.navigator.language || window.navigator.userLanguage || navigator.browserLanguage || window.navigator.languages][0];
        gtmDatalayer.locale = stdLocale ? stdLocale.toLowerCase() : '';
    }
    gtmDatalayer = Object.assign(gtmDatalayer, getBasePageTypeTrackingAttributes(data));
    gtmDatalayer = Object.assign(gtmDatalayer, getBaseTrackDataLayer(data));
    return gtmDatalayer;
};

const getBasePageTypeTrackingAttributes = function (data) {
    const glb = data.glb;
    const geo = glb && glb.geo;
    const pageObj = glb && glb.pageInfo;
    let gtmDatalayer = {};
    if (pageObj && pageObj.value) {
        gtmDatalayer.page_type = pageObj.fullPageType;
        gtmDatalayer.site_section = pageObj.sitesection;
        var page = pageObj.value;
        if (!page.startsWith('/')) {
            page = `/${page}`;
        }
        gtmDatalayer.page = page;
        gtmDatalayer.title = pageObj.value;
        const state = data.state;
        //hackin code for CW-8719, force overwirte page type
        if (glb.pageType === 'vmapsearch') {
            if (state && state.lightDpp) {
                if (state.lightDpp.isActive) {
                    gtmDatalayer.title += ' - Pinned Active';
                    gtmDatalayer.page += ' - Pinned Active';
                } else if (state.lightDpp.isSold) {
                    gtmDatalayer.title += ' - Pinned Sold';
                    gtmDatalayer.page += ' - Pinned Sold';
                } else {
                    gtmDatalayer.title += ' - Pinned PR';
                    gtmDatalayer.page += ' - Pinned PR';
                }
            } else {
                let filter = getFilterByPath(state.viewUrl);
                let keys = Object.keys(filter);
                if (keys.length > 0) {
                    let filterCount = keys.filter((key) => {
                        if (['rentals', 'center', 'coordinates', 'boundary'].includes(key)) {
                            return false;
                        }
                        //start with school ignore
                        // if (key.startsWith('school')) {
                        //     return false;
                        // }
                        return true;
                    }).length;
                    if (filterCount > 0) {
                        gtmDatalayer.title += ' - Filtered';
                        gtmDatalayer.page += ' - Filtered';
                    }
                }
            }
        } else if (glb.pageType === 'vneighborhood') {
            gtmDatalayer.msp_type = (geo && geo.neighborhoodType) || 'NEIGHBORHOOD';
        }
        gtmDatalayer.content_group = gtmDatalayer.title;
        gtmDatalayer.path = page;
        gtmDatalayer.name = page;
        //hackin code for CW-8719, force overwirte page type
    }
    return gtmDatalayer;
};

const getRentalPageName = function (state) {
    let name = '';
    if (state.isRentals && state.rentalListingType) {
        if (state.rentalListingType === 'LISTING') {
            name = 'RentalListing';
        } else if (state.rentalListingType === 'FLOOR PLAN') {
            name = 'RentalFloorplan';
        } else {
            name = 'Rental' + state.rentalListingType;
        }
    }
    return name;
};

const getBasePropertyTrackingAttributes = function (state) {
    if (state) {
        let gtmDatalayer = {};
        gtmDatalayer.dpp_mlsid = state.mlsId ? state.mlsId : '';
        gtmDatalayer.dpp_listingid = state.listingId;
        gtmDatalayer.dpp_listprice = state.price;
        gtmDatalayer.dpp_photocount = state.photoCount;
        gtmDatalayer.dpp_beds = state.bed;
        gtmDatalayer.dpp_baths = state.bath;
        gtmDatalayer.dpp_sqft = state.area || 0;
        gtmDatalayer.dpp_dom = state.daysOnMovoto;
        gtmDatalayer.dpp_type = state.propertyTypeDisplay;
        gtmDatalayer.dpp_listingstatus = state.houseRealStatus;
        gtmDatalayer.dpp_id = state.propertyId;
        gtmDatalayer.msp_rentalpageinfo = getRentalPageName(state);
        gtmDatalayer = Object.assign(gtmDatalayer, getPropertyGeoTrackingAttributes(state));
        return gtmDatalayer;
    }
    return null;
};
const getPageSearchTrackingAttributes = function (state) {
    if (state) {
        let gtmDatalayer = {};
        gtmDatalayer.msp_state = state.geo ? state.geo.state : '';
        gtmDatalayer.msp_resultnumber = state.totalCount;
        gtmDatalayer.msp_resulttype = state.totalCount ? 'matched properties' : 'no matched properties';
        gtmDatalayer.msp_sorttype = _getSortingText(state.filter.sort);
        gtmDatalayer.msp_type = _getSessionSearchType(state);
        gtmDatalayer.msp_term = state.searchInput;
        gtmDatalayer.msp_view = _getSearchViewType(state.currentViewStatus);
        gtmDatalayer.msp_pagenumber = state.displayPageIndex;
        gtmDatalayer.msp_rentalpageinfo = state.filter.rentals ? 'RentalMapsearch' : '';
        gtmDatalayer.msp_mlsidcount = state.mlsIds ? state.mlsIds.length : 0;
        (state.filter.minBed || state.filter.maxBed) && (gtmDatalayer.msp_bed_filter = `${state.filter.minBed || 0}-${state.filter.maxBed || 0}`);
        (state.filter.minBath || state.filter.maxBath) && (gtmDatalayer.msp_bath_filter = `${state.filter.minBath || 0}-${state.filter.maxBath || 0}`);
        state.filter.maxPrice && (gtmDatalayer.msp_max_price_filter = state.filter.maxPrice);
        state.filter.minPrice && (gtmDatalayer.msp_min_price_filter = state.filter.minPrice);
        return gtmDatalayer;
    }
    return null;
};

const getPropertyGeoTrackingAttributes = function (state) {
    if (state.geo) {
        let gtmDatalayer = {};
        // these fields named dppXXX but have been used to recored global geo info for every page
        gtmDatalayer.dpp_city = state.geo.city;
        gtmDatalayer.dpp_state = state.geo.state;
        gtmDatalayer.dpp_zipcode = state.geo.zipcode;
        gtmDatalayer.dpp_neighbourhood = state.geo.neighborhood;
        return gtmDatalayer;
    }
    return null;
};

const _getSessionSearchType = function (state) {
    let searchType = state.geo.geoType || '';
    if ([1, 2].includes(state.currentSearchMode)) {
        searchType = 'HOME ROAM';
    } else if (state.filter && state.filter.schoolId) {
        searchType = 'CITY-School';
    } else if (state.searchType === 'COUNTY') {
        searchType = state.searchType;
    } else if (state.searchType === 'NEIGHBORHOOD') {
        searchType = state.geo.neighborhoodType || state.searchType;
    }
    return searchType;
};

const getBaseTrackDataLayer = function (data) {
    let gtmDatalayer = {};
    if (data.state) {
        switch (data.pageType) {
            case 'vdpp':
            case 'vdppphoto':
                gtmDatalayer = Object.assign(gtmDatalayer, getBasePropertyTrackingAttributes(data.state));
                break;
        }
    }
    return gtmDatalayer;
};

const getPageTrackDataLayer = function (data) {
    let gtmDatalayer = {};
    if (data.state) {
        switch (data.pageType) {
            case 'vmapsearch':
                gtmDatalayer = Object.assign(gtmDatalayer, getPageSearchTrackingAttributes(data.state));
                // gaMspViewItemListTrack(data.state);
                break;
            default:
                break;
        }
        //custome params
        if (data.params) {
            gtmDatalayer = Object.assign(gtmDatalayer, data.params);
        }
    }
    return gtmDatalayer;
};

const initialTrack = function () {
    if (!window.initialTrack) {
        window && initialGTMTrack();
        window.inialTrack = true;
    }
};

const initialGTMTrack = function () {
    const context = window.__INITIAL_STATE__;
    const gtmEnvQS = context.gtmEnvQS || '';
    window &&
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + gtmEnvQS;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'vueDataLayer', context.gtmContainer);
};

const pushToVueDataLayer = function (data) {
    if (data && data.event) {
        let track = {
            event: data.event,
        };
        if (data.eventData) {
            track.category = data.eventData.event_category;
            track.name = data.eventData.event_name;
            track.label = data.eventData.event_label;
            track.value = data.eventData.value;
        }
        //convert to pure js version
        document.dispatchEvent(new CustomEvent('glb.ga4', { detail: track }));
    }
    window.vueDataLayer && window.vueDataLayer.push(data);
    //set limit when no more than 30 items
    // if (window.vueDataLayer && window.vueDataLayer.length > 300) {
    //     window.vueDataLayer.splice(0, 200);
    // }
};

/**
 * @TODO
 * After July 1, 2024, change all events name cotains hypen or camelcase to underscore as GA4 recommended way.
 * And remove this special ga4 method and callers too after that
 */
const ga4Convention = {
    underscore: (val) => (typeof val === 'string' ? val.replace(/-/g, '_') : val),
    eventName: (val) => {
        const formattedValue =
            typeof val === 'string'
                ? val
                      .replace(/[- ]/g, '_')
                      .replace(/([a-z])([A-Z])/g, '$1_$2')
                      .toLowerCase()
                : val;
        if (val !== formattedValue) {
            console.warn(`GA4 event name "${val}" is passed, expected is "${formattedValue}".`);
        }
        return formattedValue;
    },
};

function checkGa4Convention(eventData) {
    if (eventData) {
        if (eventData.event_name) {
            eventData.event_name = ga4Convention.eventName(eventData.event_name);
        }
        if (eventData.event_category) {
            eventData.event_category = ga4Convention.underscore(eventData.event_category);
        }
        if (eventData.event_label) {
            eventData.event_label = ga4Convention.underscore(eventData.event_label);
        }
        //value is number
        if (eventData.value) {
            eventData.value = !isNaN(eventData.value) ? eventData.value : null;
        }
    }
    return eventData;
}

function saveCrossPageEventData(gtmDatalayer) {
    let eventName = gtmDatalayer && gtmDatalayer.event;
    if (eventName === 'select_item') {
        if (gtmDatalayer.eventData && gtmDatalayer.eventData.items && gtmDatalayer.eventData.items[0]) {
            let item = gtmDatalayer.eventData.items[0];
            $.setStorage('item_list_id', item.item_list_id || '');
            $.setStorage('item_list_name', item.item_list_name || '');
            $.setStorage('item_index', item.index || '');
            $.setStorage('item_id', item.item_id);
        }
    }
}

/** @function
 * @name sendGtmEventData - It's used to trigger GTM data layer push. This data layer push can trigger GTM tag triggers and GA4 events.
 * @param {{en: string, ec: string', el: string, ev: number}} eventDefinition - Definition event name, category, label, valie
 * @param {eventDefinition.event} - eventDefinition.event - Default to gaEvent
 * @param {Object} gtmDatalayer - Data Layer {eventData, pageData}
 */

function sendGtmEventData(eventDefinition, gtmDatalayer = {}, needGa4Convention = true) {
    if (!eventDefinition) {
        return eventDefinition;
    }

    let { event, en, ec, el, ev } = eventDefinition;
    let { eventData, pageData } = gtmDatalayer;

    let dataLayer = {
        event: event || 'gaEvent',
    };

    if (eventData) {
        dataLayer.eventData = eventData;
    } else {
        dataLayer.eventData = {};
    }

    if (en) {
        dataLayer.eventData.event_name = en;
    }
    if (ec) {
        dataLayer.eventData.event_category = ec;
    }
    if (el) {
        dataLayer.eventData.event_label = el;
    }
    if (ev) {
        dataLayer.eventData.value = !isNaN(ev) ? ev : null;
    }

    //start create ticket to go though all "gaEvent" and try removing it.
    if (needGa4Convention) {
        dataLayer.eventData = checkGa4Convention(dataLayer.eventData);
    }
    //end

    if (pageData) {
        //update pageData if eventOptions.pageData exsiting, this is for push eventData and pageData at the same time
        dataLayer.pageData = pageData;
    }


    pushToVueDataLayer({ eventData: null, ecommerce: null });
    pushToVueDataLayer(dataLayer);
    // saveCrossPageEventData(dataLayer);
}

var dialogTrack = {};
var dialogTrackWhitelist = ['mvtHotleadForm', 'mvtConfirmSave', 'mvtSellLead', 'mvtAuthenticate'];
const gaDialogTrack = function (name, trigger) {
    if (dialogTrackWhitelist.includes(name) && !dialogTrack[name]) {
        gaEventTrack({
            category: 'pop',
            name,
            label: $('#container').data('type') || $('#container').attr('type'),
            trigger,
        });
        dialogTrack[name] = true;
    }
    $(document).trigger('glb.track', {
        category: name,
        action: 'pop',
        label: trigger,
    });
};
const gaChatbotTrack = function (data, state) {
    var glb = state.glb;
    switch (data.requestName) {
        case 'hotlead':
            getHotleadTrackDataLayer(glb.pageType, data, state, !data.leadposted);
            break;
        default:
            gaEventTrack({
                name: data.action,
                category: data.category,
                label: data.label,
            });
    }
};

const gaPopTrack = function (data, label) {
    if (data.pageType === 'vdppphoto') {
        defaultDatalayer = getDefaultDatalayer(data);
        sendGtmEventData({ event: 'Loaded a Page' }, { pageData: defaultDatalayer });
    }
    gaEventTrack({
        name: data.pageType,
        category: 'pop',
        label: data.pageType,
    });
    $(document).trigger('glb.track', {
        category: data.pageType,
        action: 'pop',
        label: label,
    });
};
let defaultDatalayer;
const gaPageTrack = function (data) {
    //missing
    //cm13: 1997
    // cm11: 1
    // cd73: 71.5.3.66
    defaultDatalayer = getDefaultDatalayer(data);
    let gtmDatalayer = getPageTrackDataLayer(data);
    sendGtmEventData({ event: 'Loaded Page Data' }, { pageData: { ...gtmDatalayer, ...defaultDatalayer } });
    $(document).trigger('glb.track', {
        category: data.pageType,
        action: 'load-page',
        data: {
            glb: data.glb,
            pageType: data.pageType,
            dpp: data.pageType.indexOf('vdpp') >= 0 ? data.state : null,
            msp: data.pageType.indexOf('vmapsearch') >= 0 ? data.state : null,
            startTime: window.startTime,
        },
    });
    $eventBus.$emit('signal-app-opened');
};

const gaEventTrack = function (data) {
    const name = data.name || data.action;
    if (name && data.category && data.label) {
        const gtmDatalayer = {
            link_module_name: data.category,
            event_type: data.label,
            trigger: data.trigger,
        };
        sendGtmEventData({ en: name, ec: data.category, el: data.label, ev: 0 }, { eventData: gtmDatalayer });
    }
};

const getSearchTrackDataLayer = function (pageType, params, state) {
    if (!state || !state.filter) {
        return null;
    }
    let gtmDatalayer = {};
    (state.filter.minBed || state.filter.maxBed) && (gtmDatalayer.msp_bed_filter = `${state.filter.minBed || 0}-${state.filter.maxBed || 0}`);
    (state.filter.minBath || state.filter.maxBath) && (gtmDatalayer.msp_bath_filter = `${state.filter.minBath || 0}-${state.filter.maxBath || 0}`);
    state.filter.maxPrice && (gtmDatalayer.msp_max_price_filter = state.filter.maxPrice);
    state.filter.minPrice && (gtmDatalayer.msp_min_price_filter = state.filter.minPrice);
    gtmDatalayer.filters_applied = _getFiltersText(state.filter);
    // gaMspViewItemListTrack(state);

    sendGtmEventData(
        { en: 'filter', ec: 'map_search_filter', el: state.searchTrigger || 'text_box', ev: 0 },
        {
            eventData: gtmDatalayer,
            pageData: { page_url: window.location.pathname + window.location.search },
        }
    );
};

const getUserActionEventDataLayer = function (options) {
    const params = options.params;
    const resData = params && params.res && params.res.data;
    const reqData = params && params.res && params.req.data;
    const en = options.event_name || 'key_user_actions';
    const ec = options.event_category || 'signup_login_saves_favorites_social';
    const el = options.event_label || '';
    let gtmDatalayer = {
        event_type: options.trigger,
    };
    if (resData) {
        gtmDatalayer.user_id = resData.id || resData.userId;
    }
    if (reqData) {
        gtmDatalayer.user_email = reqData.email;
    }
    sendGtmEventData({ en, ec, el, ev: 0 }, { pageData: gtmDatalayer });
};

const getFirstHotleadPriceValue = function (listPrice, isVeteran) {
    const priceConstant = 0.0000819;
    const minimumValue = 2;
    const maximumValue = 82;
    const defaultAverageValue = 20;
    const veteranValue = 63;

    /* If veteran, return veteran value */
    if (isVeteran) {
        return veteranValue;
    }

    /* If no list price, return default average value */
    if (!listPrice || isNaN(listPrice)) {
        return defaultAverageValue;
    }

    /* Get list price multiplied by price constant, rounded */
    const calculatedValue = Math.round(listPrice * priceConstant);

    /* If calculated value below minimum, return minimum */
    if (calculatedValue < minimumValue) {
        return minimumValue;
    }

    /* If calculated value greater than maximum, return maximum */
    if (calculatedValue > maximumValue) {
        return maximumValue;
    }

    /* Return calculated value */
    return calculatedValue;
};

const getHotleadTrackDataLayer = function (pageType, params, state, failed) {
    //missing PropertyPriceBand:cd109
    const resData = params.res && params.res.data;
    const reqData = params.req && params.req.data;
    let gtmDatalayer = {
        lead_type: reqData && reqData.type,
    };
    let pageData;
    if (reqData) {
        if (reqData.partnerId && window) {
            //--- start inject partnerId into pageData.split_treatments like 2711:on_partnerId
            let splits = document.getElementById('container').__vue__.$store.state.glb.splits;
            const providerCachedData = fetchCachedClientTreatments();
            let split_treatments = formatSplitsForGA4(splits, providerCachedData);

            if (split_treatments) {
                split_treatments = split_treatments
                    .split('|')
                    .map((e) => {
                        if (e.indexOf('2711:') >= 0) {
                            return e.split('_')[0] + '_' + reqData.partnerId;
                        }
                        return e;
                    })
                    .join('|');
                pageData = {
                    split_treatments: split_treatments,
                };
            }
            //--- end
        }
        if (reqData.veteransType) {
            gtmDatalayer.link_name = reqData.veteransType;
        }
        if (reqData.comment) {
            gtmDatalayer.lead_form_message = reqData.comment;
        }
        gtmDatalayer.user_name = reqData.name;
        gtmDatalayer.user_email = reqData.email;
        gtmDatalayer.user_phone = reqData.phone;
    }
    //beforesend
    if (!resData) {
        gtmDatalayer.lead_state = 'submit';
        gtmDatalayer.event_type = 'lead-submit';
        if (pageData) {
            sendGtmEventData({ en: 'lead_submit', ec: 'lead', el: 'lead' }, { eventData: gtmDatalayer, pageData });
        } else {
            sendGtmEventData({ en: 'lead_submit', ec: 'lead', el: 'lead' }, { eventData: gtmDatalayer });
        }
        return;
    }
    //after send
    if (failed) {
        gtmDatalayer.lead_state = 'failed';
        gtmDatalayer.event_type = 'lead-failed';
        sendGtmEventData({ en: 'lead_failed', ec: 'lead', el: 'lead' }, { eventData: gtmDatalayer });
    } else if (resData) {
        gtmDatalayer.lead_id = resData.hotLeadId;
        gtmDatalayer.lead_state = 'received';
        gtmDatalayer.event_type = 'lead-received';
        let ev;

        if (state && state.isRentals) {
            ev = Math.round((state.rentalMonetization || 0) * 100);
        }
        sendGtmEventData({ en: 'lead_received', ec: 'lead', el: 'lead', ev }, { eventData: gtmDatalayer });

        //new lead-received track per usercase, confirm with team now it's all relying on userType.
        //if one day goal "drop", it may caused by userType changed.
        var userType = resData.userType ? resData.userType.toLowerCase() : 'unknown';
        if (userType === 'researcher') {
            if (state && state.isNHS) {
                userType = 'nhs';
            }
        }
        sendGtmEventData({ en: `lead_received_${userType}`, ec: 'lead', el: 'lead' }, { eventData: gtmDatalayer });

        if (resData.allHotleadCount === 1) {
            sendGtmEventData({ en: `first_hotlead_${userType}`, ec: 'lead', el: `lead` }, { eventData: gtmDatalayer });
        }

        //new way to check buyer & seller
        if (resData.hotLeadCount === 1) {
            var price = state ? state.price : 0;
            var propertyType = state && state.propertyType ? state.propertyType.toLowerCase() : '';
            var isVeteran = reqData && reqData.submission_attrs ? reqData.submission_attrs.is_veteran : '';
            var evPrice = getFirstHotleadPriceValue(price, isVeteran);
            sendGtmEventData({ en: 'first_hotleadr', ec: 'lead', el: reqData.type, ev: price && evPrice }, { eventData: gtmDatalayer });
            if (price) {
                gtmDatalayer.value = price;
            }

            if (propertyType && ['mobile', 'land'].indexOf(propertyType) === -1) {
                sendGtmEventData({ en: 'first_hotleadr_nlm', ec: 'lead', el: 'lead' }, { eventData: gtmDatalayer });
            }
        }

        gtmDatalayer.lead_state = 'confirmed';
        gtmDatalayer.event_type = 'lead-confirmed';
        sendGtmEventData({ en: 'lead_confirmed', ec: 'lead', el: 'lead' }, { eventData: gtmDatalayer });
        if (resData.newUser) {
            sendGtmEventData(
                { event: 'sign_up' },
                {
                    eventData: {
                        method: 'Form',
                        trigger: signup_trigger.mvtHotleadForm,
                    },
                },
                false
            );
        }

        let eventData = {
            value: reqData && reqData.price ? reqData.price : 0,
            form_id: resData.hotLeadType,
            form_name: reqData.type,
            form_index: resData.hotLeadCount,
            form_submit_text: reqData.cta,
            form_intent: resData.userType,
            trigger: getGa4Trigger(reqData.trigger),
            user_name: reqData.name,
            user_email: reqData.email,
            user_phone: reqData.phone,
            user_id: resData.userId,
            transaction_id: resData.hotLeadId,
        };
        if (resData.property) {
            let dpp = mapper.property(resData.property, document.getElementById('container').__vue__.$store.state.glb);
            eventData = Object.assign(eventData, { items: [getItemByDpp(dpp)] });
        }
        sendGtmEventData(
            { event: 'generate_lead' },
            {
                eventData: eventData,
            },
            false
        );
    }
};

const gaSubRequestTrack = function (pageType, params, state) {
    switch (params.requestName) {
        case 'hotlead':
            getHotleadTrackDataLayer(pageType, params, state);
            break;
        default:
            break;
    }
};

const gaReceiveRequestTrack = function (pageType, params, state) {
    switch (params.requestName) {
        case 'mortgageLead':
            if (params.res && params.res.data && params.res.data.newUser) {
                sendGtmEventData(
                    { event: 'sign_up' },
                    {
                        eventData: {
                            method: 'Form',
                            trigger: signup_trigger.pageSurveyMortgage,
                        },
                    },
                    false
                );
            }
            break;
        case 'hotlead':
            getHotleadTrackDataLayer(pageType, params, state);
            break;
        case 'search':
            getSearchTrackDataLayer(pageType, params, state);
            break;
        case 'savedsearch':
            getUserActionEventDataLayer({ event_name: 'saved_search', event_label: 'save_search', trigger: params.trigger });
            break;
        case 'signup':
            sendGtmEventData(
                { event: 'sign_up' },
                {
                    eventData: {
                        method: 'Form',
                        trigger: params.trigger,
                    },
                },
                false
            );
            getUserActionEventDataLayer({ event_name: 'signup_complete', event_label: 'signup_complete', trigger: params.trigger, params });
            break;
        case 'login':
            getUserActionEventDataLayer({ event_name: 'login', event_label: 'login_complete', trigger: params.trigger });
            break;
        case 'googlelogin':
            if (params.res && params.res.data && params.res.data.ifEmailExists) {
                getUserActionEventDataLayer({ event_name: 'login_complete', event_label: 'login_complete' });
            } else {
                sendGtmEventData(
                    { event: 'sign_up' },
                    {
                        eventData: {
                            method: 'Google',
                            trigger: params.trigger,
                        },
                    },
                    false
                );
                getUserActionEventDataLayer({ event_name: 'signup_complete', event_label: 'signup_complete', params });
            }
            getUserActionEventDataLayer({ event_name: 'google_login', event_category: 'All' });
            break;
        case 'applelogin':
            if (params.res && params.res.data && params.res.data.ifEmailExists) {
                getUserActionEventDataLayer({ event_name: 'login_complete', event_label: 'login_complete' });
            } else {
                sendGtmEventData(
                    { event: 'sign_up' },
                    {
                        eventData: {
                            method: 'Apple',
                            trigger: params.trigger,
                        },
                    },
                    false
                );
                getUserActionEventDataLayer({ event_name: 'signup_complete', event_label: 'signup_complete', params });
            }
            getUserActionEventDataLayer({ event_name: 'apple_login', event_category: 'All' });
            break;
        case 'onetapgooglelogin':
            if (params.res && params.res.data) {
                if (params.res.data.ifEmailExists) {
                    getUserActionEventDataLayer({ event_name: 'login_complete', event_label: 'login_complete' });
                } else {
                    sendGtmEventData(
                        { event: 'sign_up' },
                        {
                            eventData: {
                                method: 'Google',
                                trigger: params.trigger,
                            },
                        },
                        false
                    );
                    getUserActionEventDataLayer({ event_name: 'signup_complete', event_label: 'signup_complete', params });
                }
            }

            getUserActionEventDataLayer({ event_name: 'onetapgoogle_login', event_category: 'All' });
            break;
        case 'addfavorite':
            gaEventTrack({
                name: 'saved_home',
                category: 'signup_login_saves_favorites_social',
                label: 'favorite_home',
            });
            break;
        case 'addclaimhome':
            gaEventTrack({
                name: 'claimed_home',
                category: 'signup_login_saves_favorites_social',
                label: 'claimed_home',
            });
            break;
        case 'shareToFriend':
            gaEventTrack({
                name: 'social_share',
                category: 'signup_login_saves_favorites_social',
                label: 'social_share',
            });
            break;
        default:
            break;
    }
};

const gaFailedRequestTrack = function (pageType, params, state) {
    if (params.requestName === 'hotlead') {
        getHotleadTrackDataLayer(pageType, params, state, true);
    }
};

function gaImpressionTrack(value) {
    gaEventTrack({
        name: 'impressions',
        category: 'in_page_content_impressions',
        label: value,
    });
    $(document).trigger('glb.track', {
        category: value,
        action: 'load-section',
        label: '',
        value: '',
    });
}
function handler(entries) {
    entries.forEach((entry) => {
        if (!entry.isIntersecting) {
            return;
        }
        if (entry.target.trackeName) {
            gaImpressionTrack(entry.target.trackeName);
            delete entry.target.trackeName;
        }

        window.gaObserver.unobserve(entry.target);
    });
}
function createObserver(el, value) {
    el.trackeName = value;
    if (!window.gaObserver) {
        const options = {
            rootMargin: '0% 0% 0% 0%',
            threshold: 0,
        };
        window.gaObserver = new IntersectionObserver(function (entries) {
            handler(entries);
        }, options);
    }
    window.gaObserver.observe(el);
}
function getLinkText($el) {
    var text = $el.text() || $el.attr('class') || $el.attr('alt');
    if (text) {
        text = text.trim();
        text = text.replace(/[\s\n\r]+/g, ' ');
        if (text.length > 20) {
            text = text.substr(0, 20) + '...';
        }
    }
    return text;
}

export default (app) => {
    const context = !isSSR && window.__INITIAL_STATE__;
    var clickEvent = 'click';
    if (context.enableThirdPart) {
        initialTrack();
    }
    if (!isSSR) {
        $eventBus.$on('ga-page', function (data) {
            gaPageTrack(data);
        });
        $eventBus.$on('ga', async function ({ en, ea, ec, el, props }) {
            await yieldToMain();
            ea && console.error(`ga4 event action not valid params. EN: ${en}, EA: ${ea}, EC: ${ec}, EL: ${el}, PROPS: ${JSON.stringify(props)}`);
            sendGtmEventData({ en, ec, el }, { eventData: props });
        });

        $eventBus.$on('ga4', async (eventDefinition, gtmDatalayer) => {
            //for new ga4 evnet no need do Ga4Convention
            await yieldToMain();
            sendGtmEventData(eventDefinition, gtmDatalayer, false);
        });
    }
    app.directive(
        'ga',
        isSSR
            ? {}
            : {
                  unmounted(el, binding) {
                      switch (binding.arg) {
                          case 'dialog':
                              $eventBus.$off('eventbus-dialog-open');
                              break;
                          case 'request':
                              var requestSuffix = binding.value ? binding.value.pageType : 'any';
                              $eventBus.$off(`eventbus-request-before-${requestSuffix}`);
                              $eventBus.$off(`eventbus-request-success-${requestSuffix}`);
                              $eventBus.$off(`eventbus-request-error-${requestSuffix}`);
                              break;
                      }
                  },
                  beforeMount(el, binding) {
                      if (!context.enableThirdPart) {
                          return;
                      }
                      switch (binding.arg) {
                          case 'bot':
                              $eventBus.$on('eventbus-bot-track', function (opt) {
                                  opt && gaChatbotTrack(opt, binding.value);
                              });
                              break;
                          case 'dialog':
                              $eventBus.$on('eventbus-dialog-open', function (opt) {
                                  opt && gaDialogTrack(opt.name, opt.trigger);
                              });
                              break;
                          case 'pop':
                              var label = $('#container').attr('type');
                              gaPopTrack(binding.value, label);
                              break;
                          case 'page':
                              gaPageTrack(binding.value);
                              if (!window.eventTrack) {
                                  $(document)
                                      .off('eventTrack')
                                      .on('eventTrack', function (e, opt) {
                                          if (opt.category && opt.action && opt.label) {
                                              gaEventTrack({
                                                  name: opt.name || opt.action,
                                                  category: opt.category,
                                                  action: opt.action,
                                                  label: opt.label,
                                              });
                                          }
                                      });
                                  window.eventTrack = true;
                              }
                              break;
                          case 'clickable-multi': // same as 'clickable' but allows duplicate submissions
                          case 'clickable': {
                              $(el).attr('tracked', 'tracked');
                              const listenerMethod = binding.arg === 'clickable-multi' ? 'on' : 'one';
                              $(el)?.[listenerMethod](clickEvent, function () {
                                  const $link = $(this);
                                  const data = {
                                      category: $link.closest('[comp]').attr('comp'),
                                      action: 'click',
                                      label: binding.value || $link.data('eventLabel') || '',
                                  };
                                  // custom attributes add data-link-name="xxx" to the element
                                  const gtmDatalayer = {
                                      link_name: $link.data('linkName') || getLinkText($link),
                                  };
                                  sendGtmEventData({ en: 'click_custom', ec: data.category, el: data.label }, { eventData: gtmDatalayer });
                                  $(document).trigger('glb.track', {
                                      category: data.category,
                                      action: 'click',
                                      label: gtmDatalayer.link_name,
                                      value: data.label || $link.attr('href') || '',
                                  });
                              });
                              break;
                          }
                          case 'ux':
                              $(document).on(clickEvent, 'a, button, [role="button"]', async function () {
                                  var $link = $(this);
                                  if ($link.is('[tracked]')) {
                                      return;
                                  }
                                  $link.attr('tracked', 'tracked');
                                  var data = {
                                      category: $link.closest('[comp]').attr('comp'),
                                      action: 'click',
                                      label: $link.data('linkLabel') || '',
                                  };
                                  // custom attributes add data-link-name="xxx" to the element
                                  const gtmDatalayer = {
                                      link_name: $link.data('linkName') || getLinkText($link),
                                  };
                                  if (blockGa('ec', data.category)) {
                                      return;
                                  }
                                  await yieldToMain();
                                  sendGtmEventData({ en: 'click_custom', ec: data.category, el: data.label }, { eventData: gtmDatalayer });
                                  $(document).trigger('glb.track', {
                                      category: data.category,
                                      action: 'click',
                                      label: gtmDatalayer.link_name,
                                      value: data.label || $link.attr('href') || '',
                                  });
                              });
                              break;
                          case 'impression':
                              createObserver(el, binding.value);
                              break;
                          case 'request':
                              var requestSuffix = binding.value ? binding.value.pageType : 'any';
                              $eventBus.$on(`eventbus-request-before-${requestSuffix}`, function (params) {
                                  if (binding.value.pageType) {
                                      gaSubRequestTrack(binding.value.pageType, params, binding.value.state);
                                  }
                              });
                              $eventBus.$on(`eventbus-request-success-${requestSuffix}`, function (params) {
                                  if (binding.value.pageType) {
                                      gaReceiveRequestTrack(binding.value.pageType, params, binding.value.state);
                                      $(document).trigger('glb.track', {
                                          category: params.requestName,
                                          action: 'request',
                                          label: params.trigger,
                                          value: '',
                                          data: {
                                              req: params.req,
                                              res: params.res,
                                              startTime: params.startTime,
                                          },
                                      });
                                  }
                              });
                              $eventBus.$on(`eventbus-request-error-${requestSuffix}`, function (params) {
                                  if (binding.value.pageType) {
                                      gaFailedRequestTrack(binding.value.pageType, params, binding.value.state);
                                      $(document).trigger('glb.track', {
                                          category: params.requestName,
                                          action: 'request-error',
                                          label: params.trigger,
                                          value: params.req ? params.req.url : '',
                                          data: {
                                              req: params.req,
                                              res: params.res,
                                              startTime: params.startTime,
                                          },
                                      });
                                  }
                              });
                              break;
                      }
                  },
              }
    );
};
