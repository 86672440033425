import $eventBus from '@/common/providers/eventbus';

function sendReview({commit, dispatch, rootState}, data) {
    const url = `${rootState.glb.appUrl}service/agents/review/`;
    return dispatch('glb/sendRequest', {url: url, data: data, needLogin: true, method: 'POST'}, {root: true})
        .then(res => {
            if (res) {
                $eventBus.$emit('eventbus-context-update');
                return res;
            }
            return [];
        });
}

function sendReport({commit, dispatch, rootState}, data) {
   const url = `${rootState.glb.appUrl}service/agents/review/${data.reviewId}/abuse/`;
   return dispatch('glb/sendRequest', {url: url, data: data, method: 'POST'}, {root: true})
       .then(res => {
           if (res) {
               return res;
           }
           return [];
       });
}


function fetchHouseByAgentId({commit, dispatch, rootState}, agentId){
    if (!agentId) {
        throw new Error('parameter agentId is missing.');
    }
    const url = `${rootState.glb.appUrl}service/agents/transactions/agent/`;
    let data = {
        txnId: agentId,
        txnType: 'agent',
        simple: true,
        isFetchAllSold: true
    };
    return dispatch('glb/sendRequest', {url: url, data: data, method: 'GET'},{root: true});
}

function fetchRecentTransForAgentByPage({commit, dispatch, rootState}, data){
    if (!data.agentId || !data.page || !data.limit) {
        throw new Error('parameters are missing.');
    }
    const url = `${rootState.glb.appUrl}service/agents/transactionsbypage/agent/`;
    let param = {
        txnId: data.agentId,
        page: data.page,
        limit: data.limit,
        simple: true
    }
    return dispatch('glb/sendRequest', {url: url, data: param, method: 'GET'},{root: true})
}

function voteReview({commit, dispatch, rootState}, data) {
    const url = `${rootState.glb.appUrl}service/agents/review/${data.reviewId}`;
    return dispatch('glb/sendRequest', {url: url, data: data, needLogin: true, method: 'PUT'}, {root: true});
}

function fetchReviews({commit, dispatch, rootState}, data) {
    const url = `${rootState.glb.appUrl}service/agents/${data.agentId}/review/`;
    return dispatch('glb/sendRequest', {url: url, data: data, method: 'POST'}, {root: true});
}

export default {
    sendReview,
    voteReview,
    fetchReviews,
    sendReport,
    fetchHouseByAgentId,
    fetchRecentTransForAgentByPage
};
