const msp = state => state;
const geo = function(state){
    return state.geo;
};


const getNearbyData = state => {
    return state.nearbyData;
};

const getListings = state => {
    return state.listings.reduce(function(pre, next, index) {
        if (next && next.length) {
            return pre.concat(next);
        }
        return pre;
    }, []);
};

const getMapListings = (state) => {
    if (state.mapListingsPath && state.viewUrl.indexOf(state.mapListingsPath) >= 0) {
        return state.mapListings;
    }
    return [];
};

const getCurrentPageListings = state =>{
    return state.listings[state.displayPageIndex - 1] || [];
};

const hasSchoolSearch = state => {
    return (
        state.filter.schoolHigh ||
        state.filter.schoolMiddle ||
        state.filter.schoolPre ||
        state.filter.schoolElementary ||
        state.filter.schoolPublic ||
        state.filter.schoolCharter ||
        state.filter.schoolPrivate
    );
};

const getSchoolRequest = state => {
    return state.filter.schoolId ? [
        {
            schoolId: state.filter.schoolId,
            schoolBoundaryId: state.boundaryIndexId,
            location: {
                lat: state.geo.lat,
                lng: state.geo.lng,
            },
        },
    ] : null

}
const getOriginalListings = function(state) {
    return state.listings;
};
const getDisplayPageIndex = (state) => state.displayPageIndex;
const getNearbyCities = state => state.nearbyCities;
const getNearbyZipcodes = state => state.nearbyZipCodes;
const getNearbyNeighborhood = state => state.nearbyNeighborhoods;
const getNearbyCounties = state => state.nearbyCounties;
const getMarketInfo = state => state.marketSummary;
const getSnapshotData = state => state.snapshotData;
const getSchoolData = state => state.schoolData;
const getColloquialAreaInfo = state => state.colloquialAreaInfo;
const getFilterType = state => state.filterType;
const getTopSchools = state => state.topSchools;
const getPhotoGraphicContent = state => state.photoGraphicContent;
const getSearchCount = state => state.searchCount;
const getTopListings = state => state.topNlistingsInCity;
const getLocalHighlightGeo = state => state.localHighlightGeo;
const getVisiableListings = state => state.visiableListings;
const getCurrentpageListings = state => state.currentpageListings;
const getBoundaryIndexId = state => state.boundaryIndexId;
const getSearchMode = state => state.currentSearchMode;
const getIsNewSearch = state => state.isNewSearch;
const getIsSearching = state => state.isSearching;
const getSearchType = state => state.searchType;
const getRefinedInventoryCounts = state => state.refinedInventoryCounts;
const getIsCommunitySearch = state => state.searchType === 'COMMUNITY';
const getGeoPath = state => {
    switch (state.geo.geoType) {
        case 'ZIPCODE':
            return `${state.geo.stateCode}/${state.geo.zipcode}/`;
        case 'NEIGHBORHOOD':
        case 'CITY':
        case 'COUNTY':
        case 'ADDRESS':
            return state.geo.cityCode ? `${state.geo.cityCode}-${state.geo.stateCode}/` : '';
        case 'STATE':
            return `${state.geo.stateCode}/`;
        default:
            return '';
    }
};
const getGeoDisplayText = state => {
    switch (state.geo.geoType) {
        case 'ZIPCODE':
            return `${state.geo.zipcode}, ${state.geo.state}`;
        case 'NEIGHBORHOOD':
            return `${state.geo.neighborhood} ${state.geo.city}, ${state.geo.state}`;
        case 'COUNTY':
            return `${state.geo.county}, ${state.geo.state}`;
        case 'CITY':
        case 'ADDRESS':
            return `${state.geo.city}, ${state.geo.state}`;
        case 'STATE':
            return state.geo.state;
        default:
            return '';
    }
};
const noFilter = state => {
    return (
        state.filter.ojoAttribute &&
        state.filter.ojoAttribute.length === 0 &&
        state.filter.maxBed === 0 &&
        state.filter.minBed === 0 &&
        state.filter.maxBath === 0 &&
        state.filter.minBath === 0 &&
        state.filter.soldDateRange === 0 &&
        state.filter.minPrice === 0 &&
        state.filter.maxPrice === 0 &&
        state.filter.propertyType.length === 0 &&
        state.filter.minHouseSize === 0 &&
        state.filter.maxHouseSize === 0 &&
        state.filter.minLotSize === 0 &&
        state.filter.maxLotSize === 0 &&
        state.filter.minYearBuild === 0 &&
        state.filter.maxYearBuild === 0 &&
        state.filter.minDOM === 0 &&
        state.filter.maxDOM === 0 &&
        // state.filter.hoaFees === '-1' &&
        state.filter.minHoa === -1 &&
        state.filter.maxHoa === -1 &&
        state.filter.pool === 0 &&
        state.filter.fixerupper === 0 &&
        state.filter.isActive === 1 &&
        state.filter.updateNew === 0 &&
        state.filter.propertyCriteriaOpenHouse === 0 &&
        state.filter.updatePriceReduced === 0 &&
        state.filter.updateVirtualTour === 0 &&
        state.filter.updateNewConstruction === 0 &&
        state.filter.propertyCriteriaForeclosed === 0 &&
        state.filter.propertyListedMovoto === 0 &&
        state.filter.hidePending === 0 &&
        state.filter.photos === 0 &&
        state.filter.garage === 0 &&
        state.filter.sort === null
    );
};
const lightDpp = state => state.lightDpp;
const noSchoolFilter = state => {
    return (
        state.filter.schoolHigh === 0 &&
        state.filter.schoolMiddle === 0 &&
        state.filter.schoolElementary === 0 &&
        state.filter.schoolPre === 0 &&
        state.filter.schoolPublic === 0 &&
        state.filter.schoolCharter === 0 &&
        state.filter.schoolPrivate === 0
    );
};
const getPoiList = state => state.poiList;
export default {
    msp,
    geo,
    lightDpp,
    getDisplayPageIndex,
    getGeoPath,
    getGeoDisplayText,
    getVisiableListings,
    getCurrentpageListings,
    getOriginalListings,
    hasSchoolSearch,
    getNearbyCities,
    getNearbyZipcodes,
    getNearbyNeighborhood,
    getNearbyCounties,
    getMarketInfo,
    getSnapshotData,
    getSchoolData,
    getListings,
    getColloquialAreaInfo,
    getFilterType,
    getTopSchools,
    getPhotoGraphicContent,
    getSearchCount,
    getTopListings,
    getRefinedInventoryCounts,
    getBoundaryIndexId,
    getSearchMode,
    getIsNewSearch,
    getIsSearching,
    getIsCommunitySearch,
    getSearchType,
    noFilter,
    getLocalHighlightGeo,
    getNearbyData,
    getCurrentPageListings,
    noSchoolFilter,
    getPoiList,
    getMapListings,
    getSchoolRequest,
};
