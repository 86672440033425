if (window.__INITIAL_STATE__.glb) {
    window.__INITIAL_STATE__ = window.__INITIAL_STATE__.glb;
}

import createApp from '@/app';
import jqext, { fnExt } from '@/common/utilities/jqext';
import initialParse from '@/common/utilities/initialparse';
import initialClient from '@/common/utilities/initialclient';
import eventDrag from '@/common/utilities/jqext/event.drag';
import registerDirectives from '@/common/directives';
import { populateStoreForPageType } from './entry-common.js';

window.$ = jqext;
$.extend(jqext);
$.fn.extend(fnExt);
eventDrag($);

async function mountApp() {
    if (window.__INITIAL_STATE__) {
        const context = initialParse(window.__INITIAL_STATE__);
        // see vweb/common/controllers/vuecontroller.js for where the context.ssr object is populated by the server
        const { app, store, router } = createApp(null);

        initialClient(context, jqext);
        store.commit('glb/initalGlbState', context);
        registerDirectives(app);
        populateStoreForPageType(store, app);

        await router.isReady();

        app.mount('#app');
    }
}

mountApp();

export default mountApp;
