import { getPathBySearchObj } from '../../../../widget/spec/searchHelper';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
var notificationInitialed = false;
var supportServiceWork = false;
var anonymousId;
var safariNotification;
var browserType;
var isSafari;
var isChrome;
var firebaseMessaging;
var defaultPayload;
var defaultNotificationOptions;
var context = window.__INITIAL_STATE__;
var hasTouch = 'ontouchstart' in window;
var eventName = hasTouch ? 'touchstart' : 'mousedown';
var chromeTokenRetryCount = 2;
const ddLogError = (err) => { window.ddAddError && window.ddAddError(err, {source: 'notificationError', browserType, safariNotification: !!safariNotification}); };

function fixedThreeDigits(value) {
    var num = Math.round(value);
    if (num < 10) {
        return Math.round(value * 100) / 100;
    } else if (num < 100) {
        return Math.round(value * 10) / 10;
    }
    return num;
}
function friendlyPrice(value) {
    var amount = Math.abs(value);
    if (amount >= 1000 && amount < 1000000) {
        return fixedThreeDigits(amount / 1000) === 1000 ? '1M' : fixedThreeDigits(amount / 1000).toFixed(0) + 'K';
    } else if (amount >= 1000000 && amount < 1000000000) {
        if (fixedThreeDigits(amount / 1000000) === 1000) {
            return '1B';
        }
        const valueNum = fixedThreeDigits(amount / 1000000).toFixed(1);
        const strValue = valueNum.toString();
        return (
            fixedThreeDigits(amount / 1000000).toFixed(
                strValue.lastIndexOf('0') === strValue.length - 1 ? 0 : 1
            ) + 'M'
        );
    } else if (amount >= 1000000000) {
        return fixedThreeDigits(amount / 1000000000).toFixed(1) + 'B';
    }
    return amount;
}
var mergeArray = function(a1, a2) {
    var array = [];
    if (Array.isArray(a1)) {
        if (Array.isArray(a2)) {
            array = a1.concat(a2);
        } else {
            return a1;
        }
    } else if (Array.isArray(a2)) {
        return a2;
    } else {
        return [];
    }
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j]) {
                a.splice(j--, 1);
            }
        }
    }
    return a;
};
var getRegSW = function() {
    return new Promise((resolve) => {
        navigator.serviceWorker.ready.then(function(registration) {
            navigator.serviceWorker.getRegistrations().then(function(registrations) {
                for (var key in registrations) {
                    var regSW = registrations[key];
                    if (regSW.active && regSW.active.scriptURL === context.appUrl + 'firebase-messaging-sw.js') {
                        resolve(regSW);
                    }
                }
                resolve(null);
            }).catch(function(err) {
                resolve(null);
            });
        }).catch(function(err) {
            resolve(null);
        });
    });
};
var sendEventTrack = function(label, name) {
    $(document).trigger('eventTrack', {
        category: 'pushNotification',
        action: 'pushNotification',
        label,
        name
    });
    return;
};
var getFireBase = function() {
    return new Promise((resolve) => {
        if (isSupported()) {
            var config = context.firebaseMessaging.chrome;
            const app = initializeApp(config);
            firebaseMessaging = getMessaging(app);
            resolve(true);
        } else {
            sendEventTrack('NotificationFirebaseNotSupport');
            resolve(null);
        }
    });
};
var syncBySubscriptions = function(data) {
    var record = _getRecords();
    record = data.reduce(function(prev, next) {
        var newRecord = subscriptionToRecord(next);
        if(newRecord && newRecord.zipcode){
            var i = prev.findIndex(function(e){
                return e.zipcode == newRecord.zipcode;
            });
            if (i >= 0) {
                var item = prev[i];
                //sync disable flag and propertyType
                item.disabled = newRecord.disabled;
                item.propertyType = mergeArray(item.propertyType, newRecord.propertyType);
                //only sync bed price when client side don't have the data
                item.minBed = newRecord.minBed || item.minBed;
                item.maxBed = newRecord.maxBed || item.maxBed;
                item.minPrice = newRecord.minPrice || item.minPrice;
                item.maxPrice = newRecord.maxPrice || item.maxPrice;
                item.stateCode = newRecord.stateCode || item.stateCode;
            }else{
                prev.push(newRecord);
            }
        }
        return prev;
    }, record);
    $.setStorage('saveSearchRecord', record);
};
var formatDate = function(t) {
    var year = t.getFullYear();
    return year + '' + (1 + t.getMonth()) + '' + t.getDate();
};
var updatePushNotificationToken = function(token) {
    var saveSearchRecordSyncDate = $.getStorage('saveSearchRecordSyncDate');
    var currentDate = formatDate(new Date());
    var localToken = $.getCookie('pushToken');
    if (token && localToken === token) {
        if (saveSearchRecordSyncDate === currentDate) {
            return;
        }
        //sync saveSearchRecord
        getSubscriptionTrack().then(function(res) {
        var arr = res.data && res.data.pushNotificationRecord || res.data;
        if (arr && arr.length) {
            syncBySubscriptions(arr);
            $.setStorage('saveSearchRecordSyncDate', currentDate);
        }
        }).finally(function() {
            callSubscribe(token);
        });
    }
    else{
       callSubscribe(token);
    }
};

var callSubscribe = function(token){
    const data = Object.assign(defaultPayload, {token: token});
    if (!token || token === 'null') {
        ddLogError(new Error('No Subscribe Token Found'));
    }
    $.setCookie('pushToken', token);
    const url = `${context.appUrl}api/pushnotification/subscribe/`;
    const body = JSON.stringify(data);
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
        },
        body
    })
};
var requestNotificationsPermissions = function() {
    return new Promise((resolve) => {
        sendEventTrack('notificationStart');
        try {
            if (safariNotification) {
                var config = context.firebaseMessaging.safari;
                window.safari.pushNotification.requestPermission(
                    context.appUrl.replace(/\/$/, ''),
                    config.pushId, {},
                    function(e) {
                        if (e.permission === 'granted') {
                            sendEventTrack('notificationAllowed', 'notification_accepted');
                            resolve(true);
                        } else {
                            sendEventTrack('notificationDenied');
                            resolve(false);
                        }
                    }
                );
            } else {
                resolve(false);
            }
        } catch (err) {
            ddLogError(err);
            sendEventTrack('notificationDenied');
            resolve(false);
        }
    });
};
var getCurrentToken = function() {
    return new Promise((resolve) => {
        if (safariNotification) {
            var config = context.firebaseMessaging.safari;
            var permissionData = window.safari.pushNotification.permission(config.pushId);
            if (permissionData.permission === 'granted') {
                resolve(permissionData.deviceToken);
            } else if (permissionData.permission === 'denied') {
                resolve(null);
            } else {
                requestNotificationsPermissions().then(function(allowed) {
                    if (allowed) {
                        getCurrentToken().then(function(e) {
                            resolve(e);
                        });
                    } else {
                        resolve(null);
                    }
                }).catch(function(err) {
                    console.error('Unable to get messaging token.', err);
                    ddLogError(err);
                    sendEventTrack('notificationError');
                    resolve(null);
                });
            }
        } else {
            const permissionDefault = $.notification.permission() === 'default';
            getChromeToken().then((currentToken) => {
                    if (permissionDefault && $.notification.allowed()) {
                        sendEventTrack('notificationAllowed', 'notification_accepted');
                    }
                    resolve(currentToken);
                })
                .catch(function (err) {
                    ddLogError(err);
                    let eventName = 'notificationError';
                    if (err && err.code === 'messaging/permission-blocked') {
                        eventName = 'notificationDenied';
                    }
                    sendEventTrack(eventName);
                    resolve(null);
                });
        }
    });
};
var getChromeToken = function() {
    const config = context.firebaseMessaging.chrome;
    return getToken(firebaseMessaging, { vapidKey: config.publicKey }).catch(function (err) {
        if (chromeTokenRetryCount) {
            chromeTokenRetryCount--;
            return getChromeToken();
        } else {
            throw err;
        }
    });
};

var showLocalNotification = function(options) {
    if (!safariNotification) {
        //this is local notification
        getRegSW().then(function(registration) {
            registration && registration.showNotification(options.title, options.param);
        });
    } else {
        //this is push notification
        var notification = new window.Notification(options.title, options.param);
        notification.addEventListener('close', function() {
            sendEventTrack('notificationBoxClose');
        });
        notification.addEventListener('show', function() {
            sendEventTrack('notificationBoxShow');
        });
        notification.addEventListener('click', function() {
            sendEventTrack('notificationBoxClick');
            notification.close();
            window.open(options.param.data.url, '_blank');
        });
        notification.addEventListener('error', function(e) {
            e && e.error && ddLogError(e.error);
            sendEventTrack('notificationBoxError');
        });
    }
};
var pushNotificationInitializeFirebase = function() {
    // Initialize Firebase
    onMessage(firebaseMessaging, function(message) {
        var payload = message.data;
        if (!payload.tag) {
            var dt = new Date();
            payload.tag = (dt.getFullYear() + '-' + dt.getMonth() + '-' + dt.getDate() + '-' + dt.getHours() + '-' + dt.getMinutes());
        }
        var notificationTitle = payload.title || 'Movoto Message',
            notificationOptions = {
                body: payload.body,
                icon: payload.image || defaultNotificationOptions.icon,
                data: {
                    url: payload.clickAction || defaultNotificationOptions.url,
                    zipcode: payload.zipcode,
                    urlUtmTerm: payload.urlUtmTerm
                },
                tag: payload.tag,
                requireInteraction: true
            };

        if ('actions' in Notification.prototype) {
            notificationOptions.actions = [
                {
                    action: 'openurl',
                    title: 'View House'
                }, {
                    action: 'unsubscribe',
                    title: 'Unsubscribe'
                }
            ];
        }

        showLocalNotification({
            title: notificationTitle,
            param: notificationOptions
        });
    });
};
var initalNotificationSafari = function() {
    if (safariNotification) {
        getCurrentToken().then(function(tokenStored) {
            if (tokenStored) {
                updatePushNotificationToken(tokenStored);
            }
            notificationInitialed = true;
            setTimeout(function(){
                $(document).trigger('notificatinInitialed', !!tokenStored);
            }, 1000);
        }).catch(function(){
            $(document).trigger('notificatinInitialed', false);
        });
    } else {
        notificationInitialed = true;
        setTimeout(function(){
            $(document).trigger('notificatinInitialed', false);
        }, 1000);
    }
};
var initalNotificationChrome = function(firebase) {
    if (firebase) {
        getCurrentToken().then(function(tokenStored) {
            if (tokenStored) {
                pushNotificationInitializeFirebase();
                updatePushNotificationToken(tokenStored);
            }
            notificationInitialed = true;
            setTimeout(function(){
                $(document).trigger('notificatinInitialed', !!tokenStored);
            }, 1000);
        }).catch(function(){
            $(document).trigger('notificatinInitialed', false);
        });
    } else {
        notificationInitialed = true;
        setTimeout(function(){
            $(document).trigger('notificatinInitialed', false);
        }, 1000);
    }
};
var initalNotification = function(needClicked) {
    $.setStorage('lastTime', null);
    if(!needClicked){
        if(isSafari) {
            initalNotificationSafari();
        } else {
            getFireBase().then(initalNotificationChrome);
        }
    } else {
        if(context.isMobile) {
            $('#body').addClass('clickable');
        }
        $('#body').one(eventName, function() {
            $('#body').removeClass('clickable');
            if(isSafari) {
                initalNotificationSafari();
            } else {
                getFireBase().then(initalNotificationChrome);
            }
        });
    }
};
var _updateSubscriptionTrack = function(param) {
    var token = $.getCookie('pushToken');
    return new Promise((resolve) => {
        if (!defaultPayload.gaId) {
            resolve({error: 'no gaid'});
        }
        if (token === 'null') {
            resolve({error: 'not support'});
        } else if (!token) {
            resolve({error: 'no token'});
        }
        if (!param.zipcode) {
            resolve({error: 'zipcode is required'});
        }

        var data = Object.assign(defaultPayload, {
            token: token,
            zipcode: param.zipcode || '',
            minBed: param.minBed || 0,
            maxBed: param.maxBed || 0,
            minPrice: param.minPrice || 0,
            maxPrice: param.maxPrice || 0,
            state: param.state,
            propertyType: param.propertyType || [],
            mapSearchUrl: _getUrlByData(param, true),
            timezone: $.getIANAtimeZone()
        });

        const url = `${context.appUrl}api/pushnotification/subscribe/`;
        const body = JSON.stringify(data);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body
        })
        .then(response => response.json())
        .then(function(res) {
            resolve({success: 1, message: res});
        })
        .catch(function(err) {
            resolve({error: 'failed', message: err});
        });
    });
};
var changeType = function(currentRecord, mergeRecord){
    var type = '';
    if(!currentRecord){
        type = 'create';
    } else if(currentRecord.propertyType.length !== mergeRecord.propertyType.length){
        type = 'update';
    } else if(mergeRecord.maxPrice > currentRecord.maxPrice){
        type = 'maxPrice';
    } else if(mergeRecord.minBed < currentRecord.minBed){
        type = 'minBed';
    }
    return type;
};
var _CreateNotification = function(record, type){
    var successTitle,
        successBody,
        notificationParam,
        urlUtmTerm,
        buttonText,
        _url,
        tag;

    if(!record){
        successTitle = 'Thanks For Allowing Notifications!';
        successBody = 'View Your Notification Settings: Movoto.com/notification';
        buttonText = 'View Settings';
        _url = context.appUrl + 'notification';
        urlUtmTerm = 'first_notification';
        tag = '';
    }else{
        buttonText = 'See Homes';
        _url = _getUrlByData(record);
        tag = record.zipcode;
        switch(type){
            case 'create':
            successTitle = record.zipcode + ' Notification Saved';
            successBody = record.propertyTypeDisplayName;
            if(record.maxPrice){
                successBody += ' <$' + friendlyPrice(record.maxPrice);
            }
            if(record.minBed){
                successBody += ' ' + record.minBed + '+ Bd';
            }
            urlUtmTerm = 'save';
            break;
            case 'update':
            successTitle = record.zipcode + ' Notification Update';
            successBody = record.propertyTypeDisplayName + ' Added';
            if(record.maxPrice){
                successBody += ' <$' + friendlyPrice(record.maxPrice);
            }
            if(record.minBed){
                successBody += ' ' + record.minBed + '+ Bd';
            }
            urlUtmTerm = 'update';
            break;
            case 'maxPrice':
            successTitle = record.zipcode + ' Notification Update';
            successBody = 'Max Price Increased to $' + friendlyPrice(record.maxPrice);
            urlUtmTerm = 'update';
            break;
            case 'minBed':
            successTitle = record.zipcode + ' Notification Update';
            successBody = 'Min Beds Reduced to ' + record.minBed + 'Bd';
            urlUtmTerm = 'update';
            break;
        }
        successBody += ' in ' + record.zipcode;
    }
    if(!successTitle){
        return;
    }

    notificationParam = {
        body: successBody,
        icon: defaultNotificationOptions.icon,
        data: {
            url: _url,
            zipcode: record && record.zipcode,
            term: urlUtmTerm
        },
        tag: tag,
        requireInteraction: true
    };
    notificationParam.data.url += '?utm_medium=notification&utm_campaign=realtime&utm_source=web-push&utm_term=' + urlUtmTerm;
    if ('actions' in Notification.prototype) {
        notificationParam.actions = [
            {
                action: 'openurl',
                title: buttonText
            }, {
                action: 'unsubscribe',
                title: 'Unsubscribe'
            }
        ];
    }
    showLocalNotification({
        title: successTitle,
        param: notificationParam
    });
};
var _updateSubscriptionBySubscription = function(subscription){
    return new Promise((resolve) => {
        var savesearchRecord = _getRecords();
        var currentRecord = savesearchRecord.find(function(e){
            return e.zipcode == subscription.zipcode;
        });
        if(!currentRecord){
            resolve(false);
        }
        if (subscription) {
            _updateSubscriptionTrack(subscription).then(function(response) {
                if (!response.error || response.error === 'not support') {
                    currentRecord.maxPrice = subscription.maxPrice;
                    currentRecord.minPrice = subscription.minPrice;
                    currentRecord.maxBed = subscription.maxBed;
                    currentRecord.minBed = subscription.minBed;
                    currentRecord.propertyType = subscription.propertyType;
                    currentRecord.updateTime = new Date();
                    sendEventTrack('notificationSubscriptionEdit');
                    $.setStorage('saveSearchRecord', savesearchRecord);
                    refreshUI();
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        }
    });
};
var _updateSubscriptionByListing = function(listing) {
    return new Promise((resolve) => {
        if($.getStorage('disableNotification') || listing.isRentals){
            resolve(false);
        }
        var newRecord = listingToRecord(listing);
        if (!newRecord) {
            resolve(false);
        }
        var savesearchRecord = _getRecords();
        var i = savesearchRecord.findIndex(function(e){
            return e.zipcode == newRecord.zipcode;
        });
        var currentRecord = i >= 0 ? savesearchRecord[i] : null;
        var mergeRecord = updateRecord(currentRecord, newRecord);
        var type = changeType(currentRecord, mergeRecord);
        if (type) {
            var subscription = recordToSubscription(mergeRecord);
            if (subscription) {
                _updateSubscriptionTrack(subscription).then(function(response) {
                    if(type && !mergeRecord.disabled){
                        _CreateNotification(mergeRecord, type);
                    }
                    if (!response.error || response.error === 'not support') {
                        if(type === 'create'){
                            savesearchRecord.push(mergeRecord);
                        }else{
                            savesearchRecord[i] = mergeRecord;
                        }
                        $.setStorage('saveSearchRecord', savesearchRecord);
                        refreshUI();
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            }
        } else {
            resolve(false);
        }
    });
};
var updateToken = function(param) {
    var token = $.getCookie('pushToken');
    return new Promise((resolve) => {
        if (!defaultPayload.gaId) {
            resolve({error: 'no gaid'});
        }
        if (token === 'null') {
            resolve({error: 'not support'});
        } else if (!token) {
            resolve({error: 'no token'});
        }
        var data = Object.assign(defaultPayload, {
            token: token,
            newtoken: param.newtoken
        });

        const url = `${context.appUrl}api/pushnotification/subscriptions/updatetoken/useroptions/`;
        const body = JSON.stringify(data);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body
        })
        .then(response => response.json())
        .then(function(res) {
            resolve({success: 1, message: res});
        })
        .catch(function(err) {
            resolve({error: 'failed', message: err});
        });

    });
};
var enableSubscriptionTrack = function(param) {
    var token = $.getCookie('pushToken');
    return new Promise((resolve) => {
        if (!defaultPayload.gaId) {
            resolve({error: 'no gaid'});
        }
        if (token === 'null') {
            resolve({error: 'not support'});
        } else if (!token) {
            resolve({error: 'no token'});
        }
        if (!Array.isArray(param.zipcodes) && param.zipcodes.length) {
            resolve({error: 'zipcode is required'});
        }
        var data = Object.assign(defaultPayload, {
            token: token,
            zipcodes: param.zipcodes,
            isAllowPushNotification: true
        });

        const url = `${context.appUrl}api/pushnotification/subscriptions/enabledisable/useroptions/`
        const body = JSON.stringify(data);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body
        })
        .then(response => response.json())
        .then(function(res) {
            resolve({success: 1, message: res});
        })
        .catch(function(err) {
            resolve({error: 'failed', message: err});
        });
    });
};
var _enableSubscription = function(param) {
    return new Promise((resolve) => {
        var savesearchRecord = _getRecords();
        enableSubscriptionTrack(param).then(function(response) {
            if (!response.error || response.error === 'not support') {
                var zipcodes = new RegExp('(' + param.zipcodes.join('|') + ')');
                savesearchRecord.forEach(function(e){
                    if(zipcodes.test(e.zipcode)){
                        e.disabled = false;
                    }
                });
                sendEventTrack('notificationSubscriptionActive');
                $.setStorage('saveSearchRecord', savesearchRecord);
                refreshUI();
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
};
var allowSubscriptionsTrack = function(isAllowPushNotification) {
    var token = $.getCookie('pushToken');
    return new Promise((resolve) => {
        if (!defaultPayload.gaId) {
            resolve({error: 'no gaid'});
        }
        if (token === 'null') {
            resolve({error: 'not support'});
        } else if (!token) {
            resolve({error: 'no token'});
        }

        var data = Object.assign(defaultPayload, {
            token: token,
            isAllowPushNotification: isAllowPushNotification
        });


        const url = `${context.appUrl}api/pushnotification/unsubscribe/notification/all/`;
        const body = JSON.stringify(data);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body
        })
        .then(response => response.json())
        .then(function(res) {
            resolve({success: 1, message: res});
        })
        .catch(function(err) {
            resolve({error: 'failed', message: err});
        });
    });
};
var _allowSubscriptions = function(isAllowPushNotification){
    return new Promise((resolve) => {
        var savesearchRecord = _getRecords();
        allowSubscriptionsTrack(isAllowPushNotification).then(function(response) {
            if (!response.error || response.error === 'not support') {
                savesearchRecord.forEach(function(e){
                    e.disabled = !isAllowPushNotification;
                });
                $.setStorage('disableNotification', !isAllowPushNotification);
                if(isAllowPushNotification){
                    $(document).trigger('unblockAllNotification');
                }else{
                    $(document).trigger('blockAllNotification');
                }
                $.setStorage('saveSearchRecord', savesearchRecord);
                refreshUI();
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
};

var disableSubscriptionTrack = function(param) {
    var token = $.getCookie('pushToken');
    return new Promise((resolve) => {
        if (!defaultPayload.gaId) {
            resolve({error: 'no gaid'});
        }
        if (token === 'null') {
            resolve({error: 'not support'});
        } else if (!token) {
            resolve({error: 'no token'});
        }
        if (!Array.isArray(param.zipcodes) && param.zipcodes.length) {
            resolve({error: 'zipcode is required'});
        }
        var data = Object.assign(defaultPayload, {
            token: token,
            zipcodes: param.zipcodes,
            isAllowPushNotification: false
        });

        const url = `${context.appUrl}api/pushnotification/subscriptions/enabledisable/useroptions/`;
        const body = JSON.stringify(data);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body
        })
        .then(response => response.json())
        .then(function(res) {
            resolve({success: 1, message: res});
        })
        .catch(function(err) {
            resolve({error: 'failed', message: err});
        });
    });
};
var deleteSubscriptionTrack = function(param) {
    var token = $.getCookie('pushToken');
    return new Promise((resolve) => {
        if (!defaultPayload.gaId) {
            resolve({error: 'no gaid'});
        }
        if (token === 'null') {
            resolve({error: 'not support'});
        } else if (!token) {
            resolve({error: 'no token'});
        }
        if (!Array.isArray(param.zipcodes) && param.zipcodes.length) {
            resolve({error: 'zipcode is required'});
        }
        var data = Object.assign(defaultPayload, {
            token: token,
            zipcodes: param.zipcodes
        });

        const url = `${context.appUrl}api/pushnotification/deletenotifications/`;
        const body = JSON.stringify(data);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body
        })
        .then(response => response.json())
        .then(function(res) {
            resolve({success: 1, message: res});
        })
        .catch(function(err) {
            resolve({error: 'failed', message: err});
        });
    });
};
var _deleteSubscription = function(param) {
    return new Promise((resolve) => {
        var savesearchRecord = _getRecords();
        deleteSubscriptionTrack(param).then(function(response) {
            if (!response.error || response.error === 'not support') {
                var zipcodes = new RegExp('(' + param.zipcodes.join('|') + ')');
                let newsavesearchRecord = savesearchRecord.filter(e=>!zipcodes.test(e.zipcode));
                sendEventTrack('notificationSubscriptionInactive');
                $.setStorage('saveSearchRecord', newsavesearchRecord);
                refreshUI();
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
};
var _disableSubscription = function(param) {
    return new Promise((resolve) => {
        var savesearchRecord = _getRecords();
        disableSubscriptionTrack(param).then(function(response) {
            if (!response.error || response.error === 'not support') {
                var zipcodes = new RegExp('(' + param.zipcodes.join('|') + ')');
                savesearchRecord.forEach(function(e){
                    if(zipcodes.test(e.zipcode)){
                        e.disabled = true;
                    }
                });
                sendEventTrack('notificationSubscriptionInactive');
                $.setStorage('saveSearchRecord', savesearchRecord);
                refreshUI();
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
};

var _valideBed = function(type) {
    var _validateBedRex = /(MULTI_FAMILY|LAND|OTHER)/;
    if(Array.isArray(type)){
        return type.some(function(val){
            return !_validateBedRex.test(val);
        });
    }
    return !_validateBedRex.test(type);
};
var _getUrlByData = function (record, withoutDomain) {
    var stateCode = record.stateCode || record.state;
    if (!record || !record.zipcode || !stateCode) {
        return context.appUrl + 'for-sale/';
    }
    let geo = {
        zipcode: record.zipcode,
        stateCode: stateCode,
    };
    let filter = {
        propertyType: record.propertyType,
        minBed: record.minBed,
        maxPrice: record.maxPrice,
    };
    let path = getPathBySearchObj(geo, filter);
    return (withoutDomain ? '' : context.appUrl) + path;
};
var getSubscriptionTrack = function() {
    var token = $.getCookie('pushToken');
    return new Promise((resolve) => {
        if (!defaultPayload.gaId) {
            resolve({error: 'no gaid'});
        }

        //incase of the blocked or no access given.
        if (token === 'null') {
            resolve({error: 'not support'});
        } else if (!token) {
            resolve({error: 'no token'});
        }

        const url = `${context.appUrl}api/pushnotification/activenotification/gaid/token/`;

        const data = Object.assign(defaultPayload, {token: token});
        const body = JSON.stringify(data);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body
        })
        .then(response => response.json())
        .then(resolve)
        .catch(err => {
            resolve({error: 'failed', message: err});
        });
    });
};
var _getRecords = function() {
    //if we have old data
    var records = $.getStorage('saveSearchRecord');
    if (!records) {
        return [];
    }
    if (records && records.length > 10) {
        //if array has more then 10 records, only keep the last 9 records
        let startIndex = records.length - 9;
        records = records.slice(startIndex);
    }
    if (records && records.length) {
        //es375: in some corner case the field zipcode & stateCode will be array,if yes change them to item
        for(var i = 0; i < records.length; i++){
            var item = records[i];
            if(Array.isArray(item.zipcode)){
                if(item.zipcode.length){
                    item.zipcode = item.zipcode[0];
                }else{
                    item.zipcode = undefined;
                }
            }
            if(Array.isArray(item.stateCode)){
                if(item.stateCode.length){
                    item.stateCode = item.stateCode[0];
                }else{
                    item.stateCode = undefined;
                }
            }
        }
        return records;
    }
    var newRecords = [];
    for (var key in records) {
        var oldR = records[key];
        var count = oldR.count;
        var disabled = oldR.disabled;
        var newR;
        if (!disabled && oldR.info) {
            var isUpdate = false;
            for (var j = 0; j < newRecords.length; j++) {
                newR = newRecords[j];
                if (newR.zipcode == oldR.info.zipcode) {
                    if (_valideBed(oldR.info.propertyType)) {
                        newR.minBed = isNaN(oldR.info.minBed) ? newR.minBed : (isNaN(newR.minBed) ? oldR.info.minBed : Math.min(oldR.info.minBed, newR.minBed));
                        newR.maxBed = isNaN(oldR.info.maxBed) ? newR.maxBed : (isNaN(newR.maxBed) ? oldR.info.maxBed : Math.max(oldR.info.maxBed, newR.maxBed));
                    }
                    newR.minPrice = isNaN(oldR.info.minPrice) ? newR.minPrice : (isNaN(newR.minPrice) ? oldR.info.minPrice : Math.min(oldR.info.minPrice, newR.minPrice));
                    newR.maxPrice = isNaN(oldR.info.maxPrice) ? newR.maxPrice : (isNaN(newR.maxPrice) ? oldR.info.maxPrice : Math.max(oldR.info.maxPrice, newR.maxPrice));
                    if (newR.propertyType.indexOf(oldR.propertyType) === -1) {
                        newR.propertyType.push(oldR.propertyType);
                    }
                    newR.count = oldR.count;
                    isUpdate = true;
                    break;
                }
            }
            if (!isUpdate) {
                newR = {
                    minPrice: oldR.info.minPrice,
                    maxPrice: oldR.info.maxPrice,
                    propertyType: [oldR.info.propertyType],
                    propertyTypeDisplayName: oldR.info.propertyTypeDisplayName,
                    stateCode: oldR.info.stateCode,
                    zipcode: oldR.info.zipcode,
                    count: count || 0,
                    disabled: false,
                    updateTime: new Date()
                };
                if (_valideBed(newR.propertyType)) {
                    newR.minBed = oldR.info.minBed;
                    newR.maxBed = oldR.info.maxBed;
                }
                newRecords.push(newR);
            }
        }
    }
    $.setStorage('saveSearchRecord', newRecords);
    return newRecords;
};
var refreshUI = function () {
    var count = 0;
    var records = _getRecords();
    var hasRecords = false;
    for (var i = 0; i < records.length; i++) {
        if (!records[i].disabled) {
            count++;
        }
        hasRecords = true;
    }
    $('.menu-count.notification').each(function (index, item) {
        if (!count) {
            $(item).closest('a').attr('disabled', 'disabled');
        }
        if (hasRecords) {
            $(item).closest('a').removeAttr('disabled');
        } else {
            $(item).closest('a').attr('disabled', 'disabled');
        }
    });
};
var listingToRecord = function(listing) {
    if (!listing.propertyType || !listing.geo.zipcode) {
        return null;
    }
    var record = {
        zipcode: listing.geo.zipcode,
        minPrice: isNaN(listing.price) ? NaN : Math.floor(listing.price * 0.75),
        maxPrice: isNaN(listing.price) ? NaN : Math.floor(listing.price * 1.1),
        propertyType: [listing.propertyType],
        propertyTypeDisplayName: listing.propertyTypeDisplay,
        stateCode: listing.geo.stateCode,
        count: 1,
        disabled: false,
        updateTime: new Date()
    };
    if(_valideBed(record.propertyType)){
        record.minBed = isNaN(listing.bed) ? NaN : listing.bed * 1;
        record.maxBed = isNaN(listing.bed) ? NaN : (listing.bed * 1 + 1);
    }
    return record;
};
var updateRecord = function(oldR, newR) {
    if (!oldR || oldR.zipcode !== newR.zipcode) {
        return newR;
    }
    var mergeRecord = {
        zipcode: oldR.zipcode,
        updateTime: new Date(),
        disabled: oldR.disabled || newR.disabled,
        minPrice: isNaN(oldR.minPrice) ? newR.minPrice : (isNaN(newR.minPrice) ? oldR.minPrice : Math.min(oldR.minPrice, newR.minPrice)),
        maxPrice: isNaN(oldR.maxPrice) ? newR.maxPrice : (isNaN(newR.maxPrice) ? oldR.maxPrice : Math.max(oldR.maxPrice, newR.maxPrice)),
        propertyTypeDisplayName: newR.propertyTypeDisplayName || oldR.propertyTypeDisplayName
    };
    if (newR.count >= 0) {
        if(oldR.count >= 0){
            mergeRecord.count = oldR.count + newR.count;
        }else{
            mergeRecord.count = newR.count++ || 1;
        }
    } else {
        mergeRecord.count = oldR.count++ || 1;
    }
    mergeRecord.propertyType = mergeArray(oldR.propertyType, newR.propertyType);
    mergeRecord.stateCode = (Array.isArray(oldR.stateCode) ? oldR.stateCode[0] : oldR.stateCode) || (newR.stateCode);

    if (_valideBed(newR.propertyType)) {
        mergeRecord.minBed = isNaN(oldR.minBed) ? newR.minBed : (isNaN(newR.minBed) ? oldR.minBed : Math.min(oldR.minBed, newR.minBed));
        mergeRecord.maxBed = isNaN(oldR.maxBed) ? newR.maxBed : (isNaN(newR.maxBed) ? oldR.maxBed : Math.max(oldR.maxBed, newR.maxBed));
    }else{
        mergeRecord.minBed = oldR.minBed;
        mergeRecord.maxBed = oldR.maxBed;
    }
    return mergeRecord;
};
var recordToSubscription = function(data) {
    return {
        zipcode: data.zipcode,
        minBed: data.minBed,
        maxBed: data.maxBed,
        minPrice: data.minPrice,
        maxPrice: data.maxPrice,
        propertyType: data.propertyType,
        state: data.stateCode,
        isTokenActive: !data.disabled
    };
};
var subscriptionToRecord = function(subscription) {
    if (!subscription.zipcode || !subscription.propertyType || !subscription.propertyType.length) {
        return null;
    }
    return {
        count: 0,
        zipcode: subscription.zipcode,
        propertyType: subscription.propertyType,
        disabled: !subscription.isTokenActive,
        maxBed: subscription.maxBed * 1,
        maxPrice: subscription.maxPrice * 1,
        minBed: subscription.minBed * 1,
        minPrice: subscription.minPrice * 1,
        stateCode: subscription.state,
        fromServer: true,
        updateTime: new Date(subscription.updateDate ? subscription.updateDate : subscription.tokenCreatedDate)
    };
};
var launchNotification = function(needClicked){
    initalNotification(needClicked);
};
export default {
    notification: {
        createNotification(title, text, icon) {
            try{
                return new Notification(title, {
                    body: text,
                    icon: icon
                });
            } catch(e){
                return null;
            }
        },
        initial(){
            supportServiceWork = window.navigator && window.navigator.serviceWorker;
            if (!supportServiceWork) {
                sendEventTrack('NotificationNoServiceWork');
            }
            if(!window.Notification){
                return;
            }
            if($.notification.blocked()){
                $.setStorage('lastTime', null);
                return;
            }
            anonymousId = $.getCookie('ajs_anonymous_id');
            safariNotification = window.safari && window.safari.pushNotification;
            browserType = $.browserType();
            isSafari = browserType === 'safari';
            isChrome = browserType === 'chrome';
            defaultPayload = {
                browser: browserType,
                novaId: context.user.id || '',
                gaId: $.getCookie('MOVOTODEVICEID') || context.movotoDeviceID,
                anonymousId: anonymousId ? anonymousId.replace(/'|"/g, '') : '',
                pageUrl: window.location.href,
                appType: 'web'
            };
            defaultNotificationOptions = {
                icon: context.cdnUrl + 'images/desktop/manifest/icon-192x192.png',
                url: context.appUrl + 'for-sale/'
            };
            $(document).one('dom.load.subscriptionUpdate', refreshUI);
            launchNotification(true);
        },
        valideBed: function(type){
            return _valideBed(type);
        },
        permission() {
            return window.Notification && Notification.permission;
        },
        blocked() {
            return window.Notification && window.Notification.permission === 'denied';
        },
        allowed(){
            return window.Notification && window.Notification.permission === 'granted';
        },
        getUrlByRecord: function(data, withoutDomain){
            return _getUrlByData(data, withoutDomain);
        },
        getRecords() {
            return _getRecords();
        },
        updateSubscriptionBySubscription(param){
            return new Promise((resolve) => {
                if (notificationInitialed) {
                    _updateSubscriptionBySubscription(param).then(function(e) {
                        resolve(e);
                    });
                } else {
                    $(document).one('notificatinInitialed', function() {
                        _updateSubscriptionBySubscription(param).then(function(e) {
                            resolve(e);
                        });
                    });
                }
            });
        },
        updateSubscriptionByListing(param) {
            return new Promise((resolve) => {
                if (notificationInitialed) {
                    _updateSubscriptionByListing(param).then(function(e) {
                        resolve(e);
                    });
                } else {
                    $(document).one('notificatinInitialed', function() {
                        _updateSubscriptionByListing(param).then(function(e) {
                            resolve(e);
                        });
                    });
                }
            });
        },
        deleteSubscription(param) {
            return new Promise((resolve) => {
                if (notificationInitialed) {
                    _deleteSubscription(param).then(function(e) {
                       resolve(e);
                    });
                } else {
                    $(document).one('notificatinInitialed', function() {
                        _deleteSubscription(param).then(function(e) {
                           resolve(e);
                        });
                    });
                }
            });
        },

        disableSubscription(param) {
            return new Promise((resolve) => {
                if (notificationInitialed) {
                    _disableSubscription(param).then(function(e) {
                       resolve(e);
                    });
                } else {
                    $(document).one('notificatinInitialed', function() {
                        _disableSubscription(param).then(function(e) {
                           resolve(e);
                        });
                    });
                }
            });
        },
        enableSubscription(param) {
            return new Promise((resolve) => {
                if (notificationInitialed) {
                    _enableSubscription(param).then(function(e) {
                       resolve(e);
                    });
                } else {
                    $(document).one('notificatinInitialed', function() {
                        _enableSubscription(param).then(function(e) {
                           resolve(e);
                        });
                    });
                }
            });
        },
        toggleAllSubscriptions(blocked){
            return new Promise((resolve) => {
                if (notificationInitialed) {
                    _allowSubscriptions(blocked).then(function(e) {
                       resolve(e);
                    });
                } else {
                    $(document).one('notificatinInitialed', function() {
                        _allowSubscriptions(blocked).then(function(e) {
                           resolve(e);
                        });
                    });
                }
            });
        }
    }
};