const context = window.__INITIAL_STATE__;

export default {
    loadPerimeterX() {
        if (!context.enableThirdPart) {
            return false;
        }
        // Custom parameters
        // window._pxParam1 = "<param1>";
        const appId = context.perimeterxAppId;
        const scriptUrl = context.isDevelopment ? `//client.px-cloud.net/${appId}/main.min.js` : `/${appId.replace('PX', '')}/init.js`;
        try {
            var p = document.getElementsByTagName('script')[0],
                s = document.createElement('script');
            s.async = 1;
            s.src = scriptUrl;
            p.parentNode.insertBefore(s, p);
        } catch (e) {
            console.log('pX not loaded', e);
        }
        return true;
    },
    loadDebugBear: function () {
        if (!context.enableThirdPart) {
            return false;
        }
        try {
            var dbpr = 100;
            if (Math.random() * 100 > 100 - dbpr) {
                var d = 'dbbRum',
                    w = window,
                    o = document,
                    a = addEventListener,
                    scr = o.createElement('script');
                scr.async = !0;
                w[d] = w[d] || [];
                w[d].push(['presampling', dbpr]);
                ['error', 'unhandledrejection'].forEach(function (t) {
                    a(t, function (e) {
                        w[d].push([t, e]);
                    });
                });
                scr.src = 'https://cdn.debugbear.com/HEu0mSPWKRmV.js';
                o.head.appendChild(scr);
            }
        } catch (e) {
            console.error('An error occured initializing Debugbear RUM', e);
        }
    },
    addDebugBearTag(tag, value) {
        window.dbbRum = window.dbbRum || [];
        window.dbbRum.push([tag, value]);
    },
};
