import urlMixin from '../../mixins/url.js';

export default function ({ glb = {}, geo = {}, molang }, { showLogin, showSignup, openContact } = {}) {
    let buyer = [
        {
            text: molang('footer.OpenHouses'),
            href: glb.appUrl + 'for-sale/open-houses/',
            action: null
        },
        {
            text: molang('footer.VirtualTours'),
            href: glb.appUrl + 'for-sale/virtual-tour/',
            action: null
        },
        {
            text: molang('footer.RecentlyReduced'),
            href: glb.appUrl + 'sitemap/reduced-30/',
            action: null
        }
    ];
    let baseUrl = glb.appUrl;
    let baseUrlRental = glb.appUrl + 'rentals/';
    let apartmentsRentUrl = glb.appUrl + 'rentals/condos/';
    buyer = [{
        text: molang('footer.HomesforSale'),
        href: baseUrl,
        action: null
    }].concat(buyer);

    buyer.push({
        text: molang('footer.ApartmentsForRent'),
        href: apartmentsRentUrl,
        action: null
    })

    // seller links
    let seller = [
        {
            text: molang('footer.HomesforSale'),
            href: baseUrl,
            action: null
        },
        {
            text: molang('footer.HomesforRent'),
            href: baseUrlRental,
            action: null
        },
        {
            text: molang('footer.SellYourHome'),
            href: glb.appUrl + 'sell/',
            action: null
        },

        {
            text: molang('footer.GetaHomeValuation'),
            href: glb.appUrl + 'what-is-your-home-worth/',
            action: null
        },
        {
            text: molang('footer.MortgageCalculator'),
            href: glb.appUrl + 'mortgages/calculator/',
            action: null
        },
    ];

    // agent links
    let agent = [{
        text: molang('footer.Agent'),
        href: glb.appUrl + 'agents/',
        action: null
    },
    {
        text: molang('footer.BecomeaMovotoAgent'),
        href: 'https://agents.movoto.com/platforms/',
        action: null
    },
    {
        text: molang('footer.PartnerWitUs'),
        href: `https://ojo.com/partnerships/`,
        action: null
    },
    {
        text: molang('footer.careers'),
        href: 'https://ojo.com/careers/',
        action: null
    }];

    // account & about links
    let account = [];
    let about = [
        {
            text: molang('footer.about'),
            href: 'https://ojo.com/our-story/',
            action: null
        },
        {
            text: molang('footer.Faqs'),
            href: glb.appUrl + 'faqs/',
            action: null
        },
        {
            text: molang('common.ContactUs'),
            action: 'click',
            trigger: openContact
        },
        {
            text: molang('footer.press'),
            href: 'https://ojo.com/media-room/',
            action: null
        },
        {
            text: molang('footer.sitemap'),
            href: glb.appUrl + 'sitemap/',
            action: null
        }
    ];
    if (glb.user && glb.user.id) {
        account.push({
            text: molang('footer.savedHomes'),
            href: glb.appUrl + 'my-movoto/favorites/',
            action: null
        }, {
            text: molang('footer.savedSearches'),
            href: glb.appUrl + 'my-movoto/saved-searches/',
            action: null
        }, {
            text: molang('footer.profile'),
            href: glb.appUrl + 'my-movoto/account/',
            action: null
        });
    } else {
        account.push({
            text: molang('footer.savedHomes'),
            action: 'click',
            trigger: showLogin,
            url: glb.appUrl + 'my-movoto/favorites/'
        }, {
            text: molang('footer.savedSearches'),
            action: 'click',
            trigger: showLogin,
            url: glb.appUrl + 'my-movoto/saved-searches/'
        }, {
            text: molang('footer.profile'),
            action: 'click',
            trigger: showLogin,
            url: glb.appUrl + 'my-movoto/account/'
        }, {
            text: molang('footer.SignIn'),
            action: 'click',
            trigger: showLogin
        });
    }

    // promotional links
    let promotion = [{
        text: molang('footer.HelpCenter'),
        href: 'https://help.movoto.com/',
        action: null
    },
    {
        text: molang('footer.blog'),
        href: glb.appUrl + 'blog/',
        action: null
    },
    {
        text: molang('footer.guide'),
        href: glb.appUrl + 'guide/',
        action: null
    },
    {
        text: molang('footer.getApp'),
        href: glb.appUrl + 'mobile/',
        action: null
    }];
    if (!(glb.user && glb.user.id)) {
        promotion = [{
            text: molang('footer.Join'),
            action: 'click',
            trigger: showSignup
        }].concat(promotion);
    }
    return [seller, agent, account, about, promotion];
}
