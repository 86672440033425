import mapper from '../mapper/index.js';
import prevHotlead from './data.prevhotlead';

const menu = mapper.menu;

const isSSR = typeof window === 'undefined';

function getPropertyList(arr, state, isNearby) {
    if (arr && arr.length) {
        return arr.map(function (e) {
            let data = mapper.property(e, state);
            if (isNearby) {
                data.isNearby = true;
            }
            return data;
        });
    }
    return [];
}
//todo cw-4102 Step 2.2 update glbalState only by request info and movotoconfig
function initalGlbState(state, options) {
    Object.assign(state, options);
    if (options.pageData) {
        if (options.pageData.seotitle) {
            updateSeoInfo(state, options.pageData.seotitle);
        }
        if (options.pageData.mlsIds) {
            updateMlsIds(state, options.pageData.mlsIds);
        }
    }
    if (!isSSR && window) {
        updateViewport(state, {
            width: $(window).outerWidth(),
            height: $(window).outerHeight(),
            scrollTop: window.scrollY,
            scrollLeft: window.scrollX,
        });
    }
    updateRentalInfo(state, options);

    // Check if user have selected some primary agent previously, otherwise assign the first agent from list
    if (state.assignedAgents.length) {
        const primaryAgentMap = !isSSR && $.getStorage('primaryAgentMap');
        if (primaryAgentMap && primaryAgentMap[state.user.id]) {
            state.agent = state.assignedAgents.find((agent) => agent.id == primaryAgentMap[state.user.id]) || state.assignedAgents[0];
        } else {
            state.agent = state.assignedAgents[0];
        }
    }
}

function updateGlbGeo(state, geo) {
    if (geo && geo.hasGeoInfo === undefined) {
        state.geo = mapper.geo(geo, state.rentals);
    } else {
        state.geo = geo;
    }
    if (!isSSR) {
        let feeds = $.getStorage('feed');
        if (geo && geo.hasGeoInfo && !geo.hardcode) {
            $.setCookie('nearbyGeoPath', geo.zipcode ? `${geo.state}/${geo.zipcode}/` : geo.geoPath);
        } else if (feeds && feeds.length) {
            //get lates item by updateTime
            feeds.forEach((feed) => {
                feed.updateTime = new Date(feed.updateTime) * 1 || 0;
            });
            feeds = feeds.sort((a, b) => {
                return b.updateTime - a.updateTime;
            });
            if (feeds[0].zipcode) {
                $.setCookie('nearbyGeoPath', `${feeds[0].stateCode}/${feeds[0].zipcode}/`);
            }
        }
    }
}

const PROPERTYTYPES = {
    SINGLE_FAMILY_HOUSE: 'single-family',
    CONDO: 'condos',
    MULTI_FAMILY: 'multi-family',
    LAND: 'land',
    MOBILE: 'mobile',
    OTHER: 'other',
};

function updateGlbFilter(state, propertyTypes) {
    let filterPath = '';
    if (propertyTypes && propertyTypes.length > 0) {
        let types = propertyTypes.map((e) => {
            return PROPERTYTYPES[e];
        });
        if (types.length > 1) {
            filterPath = `type-${types.join(',')}/`;
        } else {
            filterPath = `${types[0]}/`;
        }
    } else {
        filterPath = 'single-family/';
    }
    if (state.rentals) {
        filterPath = 'rentals/' + filterPath;
    }
    !isSSR && $.setCookie('nearbyFilterPath', filterPath);
}

function updateCompliance(state, data) {
    state.compliance = {
        ...state.compliance,
        ...data,
    };
}

function updateMenu(state, options) {
    updateGlbGeo(state, options.geo);
    updateGlbFilter(state, options.propertyTypes);
    state.menu = menu({
        ...options,
        state,
    });
}

function updateListingCount(state, listingCountObj) {
    state.listingCountObj = listingCountObj || {};
}

function updateViewport(state, data) {
    state.viewport = Object.assign({}, state.viewport, data);
}

function updateUserInfo(state, data) {
    if (data) {
        state.user = {
            id: data.id || data.userId,
            fullName: data.fullName,
            email: data.email,
            phone: data.phone,
            role: data.role,
            activated: !!data.activated,
            ojoId: data.ojoId,
        };
        if (data.userHotleadInfo) {
            updateUserHotleadInfo(state, {
                fullName: data.userHotleadInfo.fullName,
                email: data.userHotleadInfo.email,
                phone: data.userHotleadInfo.phone,
            });
        } else if (state.user) {
            if (state.user.email) {
                state.hotleadInfo.email = state.user.email;
            }
            if (state.user.phone) {
                state.hotleadInfo.phone = state.user.phone;
            }
            if (state.user.fullName) {
                state.hotleadInfo.fullName = state.user.fullName;
            }
        }
        if (state.user.id && !isSSR && window.__INITIAL_STATE__.user && !window.__INITIAL_STATE__.user.id) {
            window.__INITIAL_STATE__.user = state.user;
        }
    } else {
        state.user = {};
    }
}

function updateUserHotleadInfo(state, data) {
    if (data) {
        state.hotleadInfo = {
            fullName: data.fullName,
            email: data.email,
            phone: data.phone,
        };
    }
}

function addViewedPropertyId(state, id) {
    addViewedListings(state, id);
    !isSSR && $.setStorage('movoto.viewedlistings', state.viewedListings);
}

function addPropertyToSentHotleadList(state, propertyId) {
    let propertyList = !isSSR && $.getStorage('hotleadSentPropertyList');
    let obj = {
        propertyId,
        date: new Date().getDate(),
        month: new Date().getMonth(),
    };
    !isSSR && $.setStorage('hotleadSentPropertyList', propertyList ? [...propertyList, obj] : [obj]);
}

function addViewedListings(state, id) {
    if (id) {
        var index = state.viewedListings.indexOf(id);
        if (index === -1) {
            if (state.viewedListings && state.viewedListings.length > 100) {
                state.viewedListings.pop(1);
            }
            state.viewedListings.unshift(id);
            state.updatedViewedItem = {
                id: id,
                viewed: true,
            };
        } else {
            state.viewedListings.unshift(state.viewedListings.splice(index, 1)[0]);
        }
    }
}

function updateFavoriteListings(state, options) {
    if (options.isFavorite) {
        if (state.favoriteListings.indexOf(options.propertyId) === -1) {
            state.favoriteListings.push(options.propertyId);
            state.updatedFavItem = {
                id: options.propertyId,
                added: true,
            };
        }
    } else {
        let currentIndex = state.favoriteListings.indexOf(options.propertyId);
        if (currentIndex !== -1) {
            state.favoriteListings.splice(currentIndex, 1);
            state.updatedFavItem = {
                id: options.propertyId,
                added: false,
            };
        }
    }
}

function updateFavoriteStore(state, data) {
    if (data) {
        data.forEach((item) => {
            if (state.favoriteListings.indexOf(item.propertyId) === -1) {
                state.favoriteListings.push(item.propertyId);
            }
        });
    }
}

function updateSavedSearches(state, data) {
    state.savedSearches = data;
}

function removeSavedSearch(state, searchID) {
    if (searchID) {
        let index = state.savedSearches.findIndex((search) => search.searchID == searchID);
        if (index != -1) {
            state.savedSearches.splice(index, 1);
        }
    }
}

function updateAutoSuggest(state, autoSuggest) {
    state.autoSuggest = autoSuggest;
}

function updateLastSearchinfo(state, lastSearch) {
    state.lastSearch = lastSearch;
}

function updatePrevSearchinfo(state, prevSearch) {
    state.prevSearch = prevSearch;
}
function updateRecentSearchList(state, currSearch) {
    let recentSearchList = !isSSR && $.getStorage('recentSearchList');
    if (recentSearchList && recentSearchList.length > 0) {
        const indx = recentSearchList.findIndex((e) => e.text === currSearch.text);
        indx > -1 ? recentSearchList.splice(indx, 1) : recentSearchList;
        recentSearchList.length > 3 ? recentSearchList.splice(4) : recentSearchList;
        recentSearchList.unshift(currSearch);
    }
    !isSSR && $.setStorage('recentSearchList', recentSearchList ? [...recentSearchList] : [currSearch]);
    state.recentSearchList = $.getStorage('recentSearchList');
}
function updateLoginToDoList(state, data) {
    if (!data) {
        state.loginToDoList = [];
        return;
    }
    var exist = state.loginToDoList.find(function (e) {
        return e.key === data.key;
    });
    if (!exist) {
        state.loginToDoList.push(data);
    }
}

function runToDoList(state, options) {
    let isLoginAction,
        data = '';
    if (!state.user.id) {
        isLoginAction = false;
        data = 'Login is required';
    } else {
        isLoginAction = true;
        data = {
            userId: state.user.id,
            userEmail: state.user.email,
        };
    }

    if (isLoginAction) {
        for (var i = 0; i < state.loginToDoList.length; i++) {
            state.loginToDoList[i].resolve(data);
        }
        state.loginToDoList = [];
    }
}

function updateSubscriptions(state, subscriptions) {
    state.subscriptions = subscriptions;
}

function updateAutoSearchResult(state, autoSearchResult) {
    state.autoSearchResult = autoSearchResult;
}

function updatePageType(state, type) {
    state.pageType = type;
}

function updateProgressStatus(state, val) {
    if (val === 0) {
        state.progressStatus = 0;
    } else if (val === 1) {
        state.progressStatus += 1;
    } else if (val === -1) {
        state.progressStatus -= 1;
    }
}

function updateMlsIds(state, ids) {
    if (ids && ids.length) {
        state.mlsIds = ids;
    } else {
        state.mlsIds = [];
    }
}

function updateSeoInfo(state, data) {
    state.seo = Object.assign({}, state.seo, data);
}
function updateMetaObj(state, data) {
    state.metaObj = Object.assign({}, state.metaObj, data);
}
function updateSitePhoneNumber(state, data) {
    if (data) {
        state.phoneNumber = data;
    }
}

function updateSitePhoneNumber2(state, data) {
    state.phoneNumber2 = data;
}

function updateOjoLink(state, data) {
    state.insurancelink = state.appUrl + 'insurance/';
}

function updateBrokerageAgents(state, agents) {
    state.brokerageAgents = agents;
}

function updateRecentView(state, data) {
    if (data && data.length) {
        state.recentlyViewed = getPropertyList(data, state);
    }
}

function updateZipNewListings(state, data) {
    if (data && data.length) {
        state.zipNewListings = getPropertyList(data, state);
    }
}

function updateZipReducedListings(state, data) {
    if (data && data.length) {
        state.zipReducedListings = getPropertyList(data, state);
    }
}

function updateZipRecentlySoldListings(state, data) {
    if (data && data.length) {
        state.zipRecentlySoldListings = getPropertyList(data, state);
    }
}

function updateZipOpenListings(state, data) {
    if (data && data.length) {
        state.zipOpenListings = getPropertyList(data, state);
    }
}

function updateZipBestValueListings(state, data) {
    if (data && data.length) {
        state.zipBestValueListings = getPropertyList(data, state);
    }
}

function updateCityCondosListings(state, data) {
    if (data && data.length) {
        state.cityCondosListings = getPropertyList(data, state);
    }
}

function updateCityUrlListings(state, data) {
    if (data && data.length) {
        state.cityUrlListings = getPropertyList(data, state);
    }
}

function updateCityNewListings(state, data) {
    if (data && data.length) {
        state.cityNewListings = getPropertyList(data, state);
    }
}

function updateCityReducedListings(state, data) {
    if (data && data.length) {
        state.cityReducedListings = getPropertyList(data, state);
    }
}

function updateCityOpenListings(state, data) {
    if (data && data.length) {
        state.cityOpenListings = getPropertyList(data, state);
    }
}

function updateCityBestValueListings(state, data) {
    if (data && data.length) {
        state.cityBestValueListings = getPropertyList(data, state);
    }
}

function updateSurroundingListings(state, data) {
    if (data && data.length) {
        state.surroundingListings = getPropertyList(data, state);
    }
}

function updateNeighborhoodNewListings(state, data) {
    if (data && data.length) {
        state.neighborhoodNewListings = getPropertyList(data, state);
    }
}

function updateNeighborhoodOpenListings(state, data) {
    if (data && data.length) {
        state.neighborhoodOpenListings = getPropertyList(data, state);
    }
}

function updateNeighborhoodBestValueListings(state, data) {
    if (data && data.length) {
        state.neighborhoodBestValueListings = getPropertyList(data, state);
    }
}

function updateNeighborhoodReducedListings(state, data) {
    if (data && data.length) {
        state.neighborhoodReducedListings = getPropertyList(data, state);
    }
}

function updateNearbyHomeForAttribute(state, { data, attributeTag }) {
    if (data && data.length) {
        state.attributeNearbyHomes[attributeTag] = getPropertyList(data, state);
    }
}

function addFeed(state, data) {
    if (data) {
        state.feed.push(data);
        if (state.feed.length > 15) {
            //only keep last 15 feeds
            let startIndex = state.feed.length - 15;
            state.feed = state.feed.slice(startIndex);
        }
    }
}

function updateDigsSubscribe(state, id) {
    if (id) {
        var digsSubscribe = (!isSSR && $.getStorage('digsSubscribe')) || [];
        if (digsSubscribe && digsSubscribe.indexOf(id) == -1) {
            digsSubscribe.push(id);
            !isSSR && $.setStorage('digsSubscribe', digsSubscribe);
            state.digsSubscribe = digsSubscribe;
        }
    }
}

function updateFeed(state, i, data) {
    if (data && state.feed[i]) {
        state.feed[i] = data;
    }
}

function removeFeed(state, i) {
    state.feed.splice(i, 1);
}

function updateRentalInfo(state, data) {
    const rentals = !!(data && data.rentals);
    const cookie = !isSSR && $.getCookie('RENTALS');
    const prevPageRent = !isSSR && $.getStorage('prevPageRent');
    if (rentals && !cookie) {
        !isSSR && $.setCookie('RENTALS', 1);
    } else if (!rentals && cookie) {
        !isSSR && $.removeCookie('RENTALS');
    }
    if (rentals !== prevPageRent) {
        !isSSR && $.updateContext(['page', 'api']);
        !isSSR && $.setStorage('prevPageRent', rentals);
    }
    state.rentals = rentals;
    state.rentLang = rentals ? 'Rent' : '';
}

function updateNearbySold(state, data) {
    if (data && data.length) {
        state.nearbySold = getPropertyList(data, state);
    }
}

function updateNearbySale(state, data) {
    if (data && data.length) {
        state.nearbySale = getPropertyList(data, state);
    }
}

function updatePageUserType(state, data) {
    state.pageInfo.userType = data;
}

function setTag(state, { key, value }) {
    if (value && key) {
        if (state.tags[key] != value) {
            state.tags[key] = value;
        }
    }
}

function setRecentTag(state, key) {
    if (key) {
        let index = state.recentlyViewedTags.findIndex((e) => e === key);
        if (index > -1) {
            state.recentlyViewedTags[index] = key;
            state.recentlyViewedTags.unshift(state.recentlyViewedTags.splice(index, 1)[0]);
        } else {
            state.recentlyViewedTags.unshift(key);
        }
    }
}

function removeRecentTag(state, key) {
    if (key) {
        let index = state.recentlyViewedTags.findIndex((e) => e === key);
        if (index > -1) {
            state.recentlyViewedTags.splice(index, 1);
        }
    }
}

function updateClaimedHomes(state, data) {
    state.claimedHomes = (data && data.claimedHomes) || [];
    state.claimedPropertiesList = (data && data.listings) || [];
}

function updateClaimedHomeById(state, data) {
    state.claimedHomes = state.claimedHomes.map((item) => {
        return item.propertyId === data.propertyId ? data : item;
    });
}

function updateClaimedPropertiesList(state, data) {
    state.claimedPropertiesList = data;
}

function updateUnit(state, data) {
    if (data) {
        state.unit = data;
    }
}

function updateMapType(state, data) {
    state.mapType = data.mapType || state.mapType;
    !isSSR && $.setSessionStorage('map.mapType', data.mapType);
}

function updateMapDetails(state, data) {
    state.mapDetails = data.mapDetails;
    !isSSR && $.setSessionStorage('map.mapDetails', data.mapDetails);
}

function updateSelectedPoi(state, pois) {
    state.selectedPoi = pois;
}

function updateOpendoor(state, data) {
    if (data && data[0] && data[0].isEligible) {
        state.opendoor = {
            url: data[0].referral ? data[0].referral.url : '',
            price: [],
        };
        if (data[0].valueEstimate) {
            if (data[0].valueEstimate.lower) {
                state.opendoor.price.push(data[0].valueEstimate.lower);
            }
            if (data[0].valueEstimate.upper) {
                state.opendoor.price.push(data[0].valueEstimate.upper);
            }
        }
    }
}

function updateNearbyHomesLatLng(state, data) {
    if (data && data.length) {
        state.nearbyHomes = getPropertyList(data, state, true);
    }
}

function updateRentalNearbyAffordUrl(state, url) {
    state.rentalNearbyAffordUrl = url;
}

function updatePrimaryAgent(state, agentId) {
    if (!agentId) {
        return;
    }
    let primaryAgent = !isSSR && $.getStorage('primaryAgentMap');
    let selectedAgentIndex = state.assignedAgents.findIndex((agent) => agent.id == agentId);
    let index = selectedAgentIndex == -1 ? 0 : selectedAgentIndex;
    !isSSR && $.setStorage('primaryAgentMap', { ...primaryAgent, [state.user.id]: state.assignedAgents[index].id });
    state.agent = state.assignedAgents[index];
}

function updateMarketSummary(state, { key, value }) {
    if (key && value) {
        let marketSummary = !isSSR && $.getStorage('marketSummary');
        if (marketSummary) {
            state.marketSummary = marketSummary;
        }
        state.marketSummary[key] = value;
        !isSSR && $.setStorage('marketSummary', state.marketSummary);
    }
}
function mapVideo(item) {
    return {
        id: item?.id?.videoId,
    };
}
function updateVideo(state, data) {
    if (!state.nearbyVideos) {
        state.nearbyVideos = [];
    }
    if (data?.length) {
        data.forEach((item) => {
            let matchedITem = state.nearbyVideos.find((home) => home.id === item?.id?.videoId);
            if (matchedITem) {
                matchedITem = Object.assign(matchedITem, mapVideo(item));
            } else if (item?.id?.videoId) {
                state.nearbyVideos.push(mapVideo(item));
            }
        });
    }
}

function mapPlace(item) {
    if (!item) {
        return null;
    }
    return {
        id: item.place_id,
        vicinity: item.vicinity,
        name: item.name,
        rating: item.rating,
        totalReview: item.user_ratings_total,
        photo: item.photo || null,
        status: item.business_status,
        tag: item.tag,
        icon: item.icon,
    };
}

function updatePlace(state, data) {
    if (!state.nearbyPlaces) {
        state.nearbyPlaces = [];
    }
    if (data?.length) {
        data.forEach((item) => {
            if (item?.place_id && item.rating) {
                state.nearbyPlaces.push(mapPlace(item));
            }
        });
    }
}

function updatePrevHotlead(state, { container, trigger, options, hotlead, sendHotleadType, hasSubmitLead }) {
    if (trigger) {
        let popName = container || trigger;
        if (state.prevHotlead.popHistory[state.prevHotlead.popHistory.length - 1] !== popName) {
            state.prevHotlead.popHistory.push(popName);
        }
        state.prevHotlead.trigger = trigger;
        if (hotlead !== undefined) {
            if (!state.prevHotlead.firstHotlead) {
                state.prevHotlead.firstHotlead = {
                    ...hotlead,
                };
                state.prevHotlead.firstOptions = {
                    ...options,
                };
            }
            state.prevHotlead.hotlead = hotlead;
        }
        if (options !== undefined) {
            Object.assign(state.prevHotlead.options, options);
        }
        if (hasSubmitLead !== undefined) {
            state.prevHotlead.hasSubmitLead = hasSubmitLead;
            if (hasSubmitLead && sendHotleadType) {
                state.prevHotlead.hotleadHistory.push(sendHotleadType);
            }
        }
    }
}

function resetPrevHotlead(state) {
    state.prevHotlead = prevHotlead();
}

function updatePopLead(state, value) {
    state.popLeadBeforeRedirect = value;
}

function updateMuted(state, value) {
    state.muted = value;
}

export default {
    setTag,
    setRecentTag,
    removeRecentTag,
    updateMarketSummary,
    updateSitePhoneNumber,
    updateSitePhoneNumber2,
    updateSeoInfo,
    addViewedPropertyId,
    updateMuted,
    initalGlbState,
    updatePageType,
    updateViewport,
    updateUserInfo,
    updateUserHotleadInfo,
    addViewedListings,
    updateAutoSuggest,
    updatePrevSearchinfo,
    updateLastSearchinfo,
    updateFavoriteListings,
    updateFavoriteStore,
    updateSavedSearches,
    updateLoginToDoList,
    runToDoList,
    updateSubscriptions,
    updateAutoSearchResult,
    updateProgressStatus,
    updateMlsIds,
    addPropertyToSentHotleadList,
    updateMenu,
    updateOjoLink,
    updateRecentView,
    updateZipReducedListings,
    updateZipRecentlySoldListings,
    updateZipOpenListings,
    updateZipNewListings,
    updateCityReducedListings,
    updateCityCondosListings,
    updateCityUrlListings,
    updateCityOpenListings,
    updateCityNewListings,
    updateCityBestValueListings,
    updateBrokerageAgents,
    addFeed,
    removeFeed,
    updateFeed,
    updateSurroundingListings,
    updateListingCount,
    updateDigsSubscribe,
    updateRentalInfo,
    updatePageUserType,
    updateNearbySold,
    updateNearbySale,
    updateNeighborhoodNewListings,
    updateNeighborhoodOpenListings,
    updateNearbyHomeForAttribute,
    updateClaimedHomes,
    updateClaimedHomeById,
    updateClaimedPropertiesList,
    updateUnit,
    updateMapType,
    updateMapDetails,
    removeSavedSearch,
    updateSelectedPoi,
    updateOpendoor,
    updateNearbyHomesLatLng,
    updateRentalNearbyAffordUrl,
    updatePrimaryAgent,
    updatePrevHotlead,
    resetPrevHotlead,
    updatePopLead,
    updateNeighborhoodBestValueListings,
    updateNeighborhoodReducedListings,
    updateZipBestValueListings,
    updateCompliance,
    updateMetaObj,
    updateVideo,
    updatePlace,
    updateRecentSearchList,
};
