import $eventBus from '@/common/providers/eventbus';

const isSSR = typeof window === 'undefined';

var nhsTrackQueue = [];
const nhsListingTypeMapping = {
    'New Construction': 'S',
    'Ready To Build': 'P',
};
const requestToCodeMapping = {
    hotlead: 'Lead',
    addfavorite: 'Save',
    shareToFriend: 'Send',
};
const contextData = (!isSSR && window.__INITIAL_STATE__) || {};
const isMobile = contextData.isMobile;
const makeTrackingCall = function ({ eventCode, listingId, nhsListingType }) {
    if (!eventCode || !listingId || !nhsListingType) {
        return;
    }
    nhsTrackQueue = $.getStorage('nhsTrackQueue');
    const today = new Date();
    const dd = `0${today.getDate()}`.slice(-2);
    const mm = `0${today.getMonth() + 1}`.slice(-2);
    const yyyy = today.getFullYear();
    const propertyId = `BHI${nhsListingTypeMapping[nhsListingType] || 'C'}${listingId}`;
    if (contextData.pageType === 'vdpp' && ['Click', 'Clickthrough', 'Tour'].indexOf(eventCode) !== -1) {
        const isDevelopment = contextData.isDevelopment;
        const baseUrl = isDevelopment ? 'https://sprint-api.newhomesource.com/' : 'https://api.newhomesource.com/';
        fetch(`${baseUrl}api/v2/log/partnerevent/partnerId-348/eventCode-${eventCode}/propertyId-${propertyId}?date=${mm}-${dd}-${yyyy}`, { method: 'POST' });
        return;
    }
    nhsTrackQueue.push({
        eventCode: eventCode,
        propertyId: propertyId,
        date: `${mm}-${dd}-${yyyy}`,
    });
    $.setStorage('nhsTrackQueue', nhsTrackQueue);
};
const initial = function () {
    const isDevelopment = contextData.isDevelopment;
    var hasTouch = 'ontouchstart' in window;
    var clickEvent = hasTouch ? 'touchstart' : 'mouseenter';
    nhsTrackQueue = $.getStorage('nhsTrackQueue');
    if (!nhsTrackQueue) {
        $.setStorage('nhsTrackQueue', []);
    }

    $(document).ready(() => {
        $(document).one(clickEvent, function () {
            nhsTrackQueue = $.getStorage('nhsTrackQueue');
            $.setStorage('nhsTrackQueue', []);
            //make the long task dont bolck the main thread
            requestAnimationFrame(() => {
                if (nhsTrackQueue && nhsTrackQueue.length) {
                    nhsTrackQueue.forEach(function (e) {
                        const baseUrl = isDevelopment ? 'https://sprint-api.newhomesource.com/' : 'https://api.newhomesource.com/';
                        fetch(`${baseUrl}api/v2/log/partnerevent/partnerId-348/eventCode-${e.eventCode}/propertyId-${e.propertyId}?date=${e.date}`, { method: 'POST' });
                    });
                }
            });
        });
    });
};
const getListingData = function () {
    if (contextData.pageType === 'vdpp') {
        return contextData.pageData || {};
    }
    return null;
};

export default (app) => {
    !isSSR && initial();
    app.directive(
        'nhs',
        isSSR
            ? {}
            : {
                  unmounted(el, binding) {
                      switch (binding.arg) {
                          case 'request':
                              $eventBus.$off('eventbus-request-success-vdpp');
                              break;
                      }
                  },
                  beforeMount(el, binding) {
                      const listingData = getListingData();
                      switch (binding.arg) {
                          case 'searchimpression':
                              if (binding.value.isNHS) {
                                  makeTrackingCall({ eventCode: 'Search', listingId: binding.value.nhsListingId, nhsListingType: binding.value.nhsListingType });
                              }
                              break;

                          case 'listingviewed':
                              listingData && listingData.isNHS && makeTrackingCall({ eventCode: 'View', listingId: listingData.nhsListingId, nhsListingType: listingData.nhsListingType });
                              break;

                          case 'click':
                              // var hasTouch = 'ontouchstart' in window;
                              var clickEvent = isMobile ? 'click' : 'mousedown';
                              $(el).on(clickEvent, function (e) {
                                  ((listingData && listingData.isNHS) || (binding.value && binding.value.isNHS)) &&
                                      makeTrackingCall({
                                          eventCode: (binding.value && binding.value.code) || 'Click',
                                          listingId: (binding.value && binding.value.nhsListingId) || listingData.nhsListingId,
                                          nhsListingType: (binding.value && binding.value.nhsListingType) || listingData.nhsListingType,
                                      });
                              });
                              break;

                          case 'request':
                              $eventBus.$on('eventbus-request-success-vdpp', function ({ requestName }) {
                                  if (['hotlead', 'addfavorite', 'shareToFriend'].includes(requestName)) {
                                      listingData &&
                                          listingData.isNHS &&
                                          makeTrackingCall({ eventCode: requestToCodeMapping[requestName], listingId: listingData.nhsListingId, nhsListingType: listingData.nhsListingType });
                                  }
                              });
                              break;

                          case 'clickthrough':
                              var clickEvent = isMobile ? 'click' : 'mousedown';
                              $(el).on(clickEvent, function (e) {
                                  listingData && listingData.isNHS && makeTrackingCall({ eventCode: 'Clickthrough', listingId: listingData.nhsListingId, nhsListingType: listingData.nhsListingType });
                              });
                              break;

                          case 'tour':
                              var clickEvent = isMobile ? 'click' : 'mousedown';
                              $(el).on(clickEvent, function (e) {
                                  listingData && listingData.isNHS && makeTrackingCall({ eventCode: 'Tour', listingId: listingData.nhsListingId, nhsListingType: listingData.nhsListingType });
                              });
                              break;
                      }
                  },
              }
    );
};
