import mapper from '../mapper/index.js';

function updateNeighborhood(state, { pageData, glb }) {
    if (pageData.geo) {
        //ignore rentals side-effect for links generated on neighborhood page
        state.geo = mapper.geo(pageData.geo, false);
    }
    state.relatedListings.nearby = getPropertyList(pageData.relatedListings.nearby, glb);
    state.relatedListings.rentals = getPropertyList(pageData.relatedListings.rentals, glb);
    state.relatedListings.nearbyNeighborhoods = getPropertyList(pageData.relatedListings.nearbyNeighborhoods, glb);
    state.nearbySchools = pageData.nearbySchools || [];
}

function getPropertyList(arr, state) {
    if (arr && arr.length) {
        return arr.map(function (e) {
            return mapper.property(e, state);
        });
    }
    return [];
}

export default {
    updateNeighborhood,
};
