import { Loader } from '@googlemaps/js-api-loader';
var context = window.__INITIAL_STATE__;
var streetViewCheckList = {};

let loaderConfig = {
    version: 'weekly',
    libraries: ['drawing', 'geometry', 'places'],
};
if (context.isDevelopment) {
    loaderConfig.apiKey = context.googleMapKey ? context.googleMapKey.replace('key=', '') : '';
} else {
    loaderConfig.client = context.googleMapKey ? context.googleMapKey.replace('client=', '') : '';
}
const loader = new Loader(loaderConfig);

export default {
    hasStreetView: function (opt) {
        $.loadGMap().then(function () {
            var key = null;
            if (opt.address) {
                key = encodeURIComponent(opt.address);
            }
            if (opt.lat && opt.lng) {
                key = opt.lat + ',' + opt.lng;
            } else {
                return opt.callback(false);
            }

            if (streetViewCheckList[key] !== undefined) {
                if (streetViewCheckList[key] !== 'loading') {
                    return opt.callback(streetViewCheckList[key]);
                }
                $(document).one('hasStreetView.' + key, function (e, hasStreetView) {
                    opt.callback(hasStreetView);
                });
            } else {
                streetViewCheckList[key] = 'loading';
                var metaDataPath = '/maps/api/streetview/metadata?location=' + key + '&' + context.googleMapKey;
                if (context.isDevelopment) {
                    fetch(`https://maps.googleapis.com${metaDataPath}`, { method: 'GET' })
                        .then((response) => response.json())
                        .then(function (res) {
                            streetViewCheckList[key] = res && res.status === 'OK';
                            opt.callback(streetViewCheckList[key]);
                            $(document).trigger('hasStreetView.' + key, streetViewCheckList[key]);
                        });
                } else {
                    const body = { url: metaDataPath };
                    fetch(`${context.appUrl}webservices/getgooglemapurlwithsignature/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                        },
                        body: JSON.stringify(body),
                    })
                        .then((response) => response.json())
                        .then(function (data) {
                            fetch(`https://maps.googleapis.com${data.url}`, { method: 'GET' })
                                .then((response) => response.json())
                                .then(function (res) {
                                    streetViewCheckList[key] = res && res.status === 'OK';
                                    opt.callback(streetViewCheckList[key]);
                                    $(document).trigger('hasStreetView.' + key, streetViewCheckList[key]);
                                });
                        });
                }
            }
        });
    },
    loadGMap: () => {
        return loader.load();
    },
};
