import mapper from '../mapper/index.js';
import dppState from './state';

function updateEstValue(state, data){
    state.estPrice = data.estPrice;
    state.estPricePerArea = data.estPricePerArea;
    state.estPriceRange = data.estPriceRange;
}

function getPropertyList(arr, state){
    if(arr && arr.length){
        return arr.map(function(e){
           return mapper.property(e, state);
        });
    }
    return [];
}

function updateMortgagePerMonth(state, value){
    state.mortgagePerMonth = value;
}

function updateMonthTotal(state, value){
    state.monthTotal = value;
}

function reloadProprty(state, data){
    //reset dpp to empty
    let emptyState = dppState();
    Object.assign(state, emptyState);
    updateProperty(state, data);
}

function updateProperty(state, {data, glb}) {
    var extraInfo = {
        pageInfo: data.pageInfo,
        seo: data.seo,
        attributes: data.attributes || [],
        description: data.description || null,
        fCooling: data.fCooling || null,
        fHeating: data.fHeating || null,
        fLevels: data.fLevels || null,
        // categorizedPhotos: data.categorizedPhotos || [],
        avmInfo: data.avmInfo || null,
        isOjOBrokerage: data.isOjOBrokerage,
        features: data.features || [],
        nearbyHomes: getPropertyList(data.nearbyHomes, glb),
        dppAgent: data.workingAgent || null,
        listingAgentData: data.listingAgentData || null,
        nearbySchools: data.nearbySchools || [],
        nearbySchoolAvgRating: data.nearbySchoolAvgRating || undefined,
        schoolDistricts: data.schoolDistricts || [],
        localHighlight: data.localHighlight,
        localHighlightScore: data.localHighlightScore,
        publicRecord: data.publicRecord || null,
        faqs: data.faqs || [],
        marketTrendTable: data.marketTrendTable || null,
        seoNearbyCity: data.seoNearbyCity || [],
        seoNearbyZipCode: data.seoNearbyZipCode || [],
        seoNearbyNeighborhood: data.topNeighborhoods || [],
        seoNearbyCounty: data.topNearbyCounty || [],
        weatherSummary: data.weatherSummary || [],
        priceHistory: data.priceHistory || [],
        hideIsViewed: true,
        rentalMonetization: data.rentalMonetization,
        id: data.id,
        agentDetails: data.agentDetails || null,
        realListingId: data.realListingId,
        avgPendingDays: getDOMForHotMarket(data.cityMarketSnapshot, data.marketTrendTable),
        avgPendingCount: data.avgPendingCount,
        nearbyRentHomes: getPropertyList(data.nearbyRentHomes, glb),
        isFrontier: data.isFrontier,
    };
    var dpp = Object.assign(mapper.property(data, glb), extraInfo);
    if (data.nearbyEstPrice) {
        if (data.nearbyEstPrice.estPrice) {
            dpp.estPrice = data.nearbyEstPrice.estPrice;
        }
        if (data.nearbyEstPrice.estPricePerArea) {
            dpp.estPricePerArea = data.nearbyEstPrice.estPricePerArea;
        }
        if (data.nearbyEstPrice.estPriceRange) {
            dpp.estPriceRange = data.nearbyEstPrice.estPriceRange;
        }
    }
    if (!data.isRentals) {
        if (data.mortgageInfo) {
            dpp.mortgagePerMonth = data.mortgageInfo.mortgagePerMonth;
            dpp.monthTotal = data.mortgageInfo.monthTotal;
        }
        if (dpp.isEstPrice && data.nearbyEstPrice && data.nearbyEstPrice.estPrice) {
            dpp.price = data.nearbyEstPrice.estPrice;
            if (dpp.price && dpp.area) {
                dpp.pricePerArea = dpp.price / dpp.area;
            }
        }
    }
    if (dpp.isPrOnly || dpp.showAsPR) {
        dpp.keyInsights = data.keyInsights;
        //cw-12336: confirmed remove description for PR
        dpp.description = null;
    }
    dpp.loadBankrateLink = dpp.price > 100000 && !['NY'].includes(dpp.geo.state);
    glb.mlsIds = dpp.mlsId ? [dpp.mlsId] : [];
    glb.hideMlsInfo = dpp.showAsPR;
    glb.rentalLink = dpp.rentalLink || '';
    glb.dynamicRentalUrl = dpp.dynamicRentalUrl || '';
    if (glb.assignedAgents.length) {
        const isSSR = typeof window === 'undefined';
        const primaryAgentMap = !isSSR && $.getStorage('primaryAgentMap');
        const assignedAgentList = glb.assignedAgents.filter(agent => {
            const issuedArea = agent.licenses.length && agent.licenses.map( item => item.areaIssued && item.areaIssued.replace('US-',''));
            if(issuedArea && issuedArea.includes(dpp.geo && dpp.geo.state)){
                return agent;
            }
        })
        glb.dppAssignedAgents = assignedAgentList;
        if (primaryAgentMap && primaryAgentMap[glb.user.id] && assignedAgentList && assignedAgentList.length) {
            glb.agent = assignedAgentList && assignedAgentList.find((agent) => agent.id == primaryAgentMap[glb.user.id]) || assignedAgentList[0];
        } else {
            glb.agent = assignedAgentList && assignedAgentList[0] || {};
        }
    }
    Object.assign(state, dpp);
}

function getDOMForHotMarket(citySnapShot, fullSnapShot){
    var dom = 0;
    if(citySnapShot) {
        dom = citySnapShot.one_month_ago && citySnapShot.one_month_ago.allTypeMedianDom || 0;
    }
    if(!dom && fullSnapShot && fullSnapShot.zipcode_onemonthago){
        dom = fullSnapShot.zipcode_onemonthago.allTypeMedianDom || 0;
    }
    return dom;
}

function updateNearbySold(state, {data, glb}){
    if(data && data.length){
        state.nearbySoldHomes = getPropertyList(data, glb);
    }
}

function updateNearbySaleForLand(state, { data, glb }) {
    if (data) {
        let propertyList = null;
        if (Array.isArray(data) && data.length) {
            propertyList = data;
        } else if (data.listings && data.listings.length) {
            propertyList = data.listings;
        }
        state.nearbySaleForLand = getPropertyList(propertyList, glb);
    }
}

function updateNearbyRentals(state, {data, glb}) {
    if (data) {
        let propertyList = null;
        if (Array.isArray(data) && data.length) {
            propertyList = data;
        } else if (data.listings && data.listings.length) {
            propertyList = data.listings;
        }
        state.nearbyRentHomes = getPropertyList(propertyList, glb);
    }
}

function updateNearbySoldForLand(state, {data, glb}){
    if(data && data.length){
        state.nearbySoldForLand = getPropertyList(data, glb);
    }
}

function updatenearbyPois(state, data){
    if(data){
        state.nearbyPois = mapper.poi(data);
    }
}

function updatecityArticles(state, {data}){
    if(data && data.length){
        state.cityArticles = data;
    }
}

function updateFiveYearStats(state, {data}) {
    state.market5Y = data;
}

function updateOneYearStats(state, {data}) {
    state.market1Y = data;
}
function updateForecast(state, data) {
    state.forecast = data;
}

function updateMapUrls(state, data){
    state.mapUrls.push(data);
}

function updateCateIndex(state, index) {
    state.cateIndex = index;
}

function updatePhotoIndex(state, index) {
    state.photoIndex = index;
}

function updateImageIndex(state, index) {
    state.imageIndex = index;
}

function updateNearbyNHS(state, {data, glb}) {
    if (data) {
        let propertyList = null;
        if (Array.isArray(data) && data.length) {
            propertyList = data;
        } else if (data.listings && data.listings.length) {
            propertyList = data.listings;
        }
        state.nearbyNHSHomes = getPropertyList(propertyList, glb);
    }
}

export default {
    reloadProprty,
    updateCateIndex,
    updatePhotoIndex,
    updateImageIndex,
    updateProperty,
    updateEstValue,
    updateMortgagePerMonth,
    updateMonthTotal,
    updateNearbySold,
    updateNearbySaleForLand,
    updateNearbySoldForLand,
    updatenearbyPois,
    updatecityArticles,
    updateFiveYearStats,
    updateOneYearStats,
    updateForecast,
    updateMapUrls,
    getDOMForHotMarket,
    updateNearbyRentals,
    updateNearbyNHS
};
